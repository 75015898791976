import React, { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { getDateAndTime, getLeftBazarTime } from "../../utils";

function MatkaHeader(props) {
  const { matchDetail, setBetClosed, betClosed } = props;
  const [bazarTime, setBazarTime] = useState({
    left: "00:00:00",
    currentTime: new Date(),
    bet_sec: "",
  });

  useEffect(() => {
    if (matchDetail?.event?.open_date) {
      const clearTime = setInterval(function () {
        setBazarTime({
          ...getLeftBazarTime(
            getDateAndTime("YYYY-MM-DD HH:mm:ss", matchDetail?.event?.open_date)
          ),
          currentTime: moment(),
        });
      }, 1000);
      return () => {
        clearInterval(clearTime);
      };
    }
  }, [matchDetail?.event?.open_date]);

  useEffect(() => {
    if (matchDetail?.event && bazarTime?.bet_sec !== "") {
      switch (true) {
        case +bazarTime?.bet_sec === 0:
          setBetClosed(0);

          break;
        case !betClosed &&
          bazarTime?.bet_sec <= matchDetail?.event?.bet_close_time:
          setBetClosed(true);

          break;
      }
    }
  }, [bazarTime?.bet_sec]);

  return (
    <div className="row content-top-padding">
      <div className="content_matches_wrapper">
        <div className=" col-lg-8 col-xs-9 col-md-8 col-sm-7 bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color">
          <p
            className="inplay-show-tbl-td match-status-text match-score-box-color"
            id="score_table_383067"
            style={{ fontFamily: "sans-serif !important" }}
          >
            <span
              className="match-score-span-1 font-sans-serif"
              style={{ fontFamily: "sans-serif !important" }}
            >
              {matchDetail?.event?.league_name
                ? matchDetail?.event?.league_name.toUpperCase()
                : '--'}
            </span>
            <span className="match-score-span-2 font-sans-serif">
              {matchDetail?.event?.event_name
                ? matchDetail?.event?.event_name.toUpperCase()
                : '--'}
            </span>
            <span className="match-score-span-2 font-sans-serif">
              Bazar Date :{" "}
              {matchDetail?.event?.open_date
                ? getDateAndTime("DD, MMM YYYY", matchDetail?.event?.open_date)
                : "-- -- --"}
            </span>
            <span className="match-score-span-2 font-sans-serif">
              Bazar Time :{" "}
              {matchDetail?.event?.open_date
                ? getDateAndTime("h:mm A", matchDetail?.event?.open_date)
                : "-- -- --"}
            </span>
            <span className="match-score-span-2 font-sans-serif">
              Current Time :{" "}
              {bazarTime?.currentTime
                ? moment(bazarTime?.currentTime).format("hh:mm:ss")
                : "-- -- --"}
            </span>
            <span
              className={
                "match-score-span-2 font-sans-serif" +
                (bazarTime?.bet_sec !== "" &&
                bazarTime?.bet_sec < matchDetail?.event?.bet_close_time
                  ? " text-danger"
                  : "")
              }
            >
              Bazar Left Time : {bazarTime.left}{" "}
            </span>
            {matchDetail?.event?.announcement && (
              <span className="match-score-span-2 font-sans-serif">
                {matchDetail?.event?.announcement}
              </span>
            )}
          </p>
        </div>

        <div
          id="refresh2"
          onClick={() => window.location.reload()}
          className=" col-lg-1 col-xs-2 col-md-1 col-sm-2 refresh-btn  reload-class refresh-btn-color"
        >
          <FontAwesomeIcon icon={faRotate} className="refresh_img" />
        </div>
      </div>
    </div>
  );
}

export default React.memo(MatkaHeader);
