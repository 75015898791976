import React, { useContext, useEffect, useState } from "react";
import {
  BetPlaceContext,
  LoginContext,
  NavContext,
} from "../../context/navContext";
import { getUserLimit, getclientBalance } from "../../_services";
import { Button, Modal } from "react-bootstrap";
import { Login } from "../Modal/Login";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../_actions";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function LoginNavDetails(props) {
  const { fetchBalance } = props;
  const { toggleShowMenu } = useContext(NavContext);
  const { isBetPlaced } = useContext(BetPlaceContext);
  const { isLoginModal, setLoginModal } = useContext(LoginContext);
  const [details, setDetails] = useState(null);
  const [limitModal, setLimitModal] = useState(null);
  const { clientLogin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const authDetails = clientLogin;
  const history = useHistory();
  useEffect(() => {
    if (authDetails?.user_id) getclientBalanceData();
  }, [isBetPlaced, fetchBalance, authDetails]);

  const getclientBalanceData = async () => {
    getclientBalance()
      .then((response) => {
        if (response && response.data) {
          setDetails({
            balance: response.data.balance,
            exposer: response.data.exposure,
          });
        }
      })
      .catch((error) => {});
  };

  const getUserLimitDetails = async () => {
    try {
      const res = await getUserLimit();
      setLimitModal(res?.data?.limit_bets ? res?.data?.limit_bets : []);
    } catch (error) {}
  };

  const logout = () => {
    dispatch(
      logoutUser((res) => {
        localStorage.clear();
      })
    );
  };

  return (
    <>
      <div className="navbar-header w-100">
        <div className="w-100 row anvMain_row">
          <Link
            className="navbar-brand pro-img-tag  col-sm-3 col-md-4 col-lg-6"
            to="/dashboard"
          >
            <img className="day_logo" src="/images/7day_nav_logo.svg" alt="" />
          </Link>
          <div className="anv-col6 col-sm-9 col-md-8 col-lg-6">
            {!authDetails?.user_id ? (
              <div>
                <div className="loginFeild">
                  <Login />
                </div>

                <div className="modalLogin-btn">
                  <Button
                    onClick={() => {
                      setLoginModal(true);
                    }}
                    className="login_submitBtn justify-content-between"
                    variant="secondary"
                    type="button"
                  >
                    Login
                  </Button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-end align-items-baseline">
                {/* <Link to="/dashboard">
                  <FaHome
                    style={{
                      fontSize: "36px",
                      marginBottom: "-10px",
                      marginRight: "10px",
                      color: "blue",
                    }}
                  />
                </Link> */}
                <div className="anvRight">
                  <div className="user_profile_detail">
                    <span className="header-user-nm">
                      {" "}
                      {authDetails?.username} ({authDetails?.first_name})
                    </span>
                    <br />
                    <span className="header-user-coins">
                      Coins : {details?.balance}|
                      <span
                        className={
                          (details?.exposer > 0 ? "user_limit_span1 " : " ") +
                          "user_limit_span"
                        }
                        onClick={() =>
                          details?.exposer > 0 && getUserLimitDetails()
                        }
                      >
                        Used Limit : {details?.exposer}
                      </span>
                    </span>
                  </div>
                  <div className="user-menu">
                    <div className="user_icon logout_button">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        class="loginIcon_design"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path>
                      </svg>
                    </div>
                    <ul className="user-menu-dropdown">
                      <li onClick={() => history.push("/ledger")}>Ledger</li>
                      <li onClick={() => history.push("/account-statement")}>
                        Statement
                      </li>
                      <li onClick={() => history.push("/rules")}>Rules</li>
                      <li onClick={() => history.push("/change_password")}>
                        Change Password
                      </li>
                      <li onClick={() => logout()}>
                        <button>Logout</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {limitModal && (
        <Modal
          className="setting_modal"
          size="lg"
          show={limitModal}
          onHide={() => setLimitModal(false)}
          keyboard={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "10px",
              color: "#000000",
              cursor: "pointer",
            }}
            onClick={() => setLimitModal(false)}
          >
            <h4 style={{ marginLeft: "10px" }}>Used Limit </h4>
            <div> X </div>
          </div>
          <div
            style={{
              margin: "10",
            }}
          >
            <table
              className="table"
              style={{ border: "1px solid #EFEFEF", marginBottom: 50 }}
            >
              <thead>
                <tr style={{ background: "#E0E0E0" }}>
                  <th style={{ color: "#E0E0E0" }}>Event Name</th>
                  <th style={{ color: "#E0E0E0" }}>Market Name</th>
                  <th style={{ color: "#E0E0E0" }}>Stake</th>
                </tr>
              </thead>
              <tbody>
                {limitModal &&
                  limitModal.map((item, index) => {
                    return (
                      <React.Fragment
                        key={item.market_name + index + item.price}
                      >
                        <tr>
                          <td className="text_wrap_ellipsis">
                            {item?.event_name}
                          </td>
                          <td
                            className="text_wrap_ellipsis"
                            style={{
                              borderLeft: "1px solid #EFEFEF",
                            }}
                          >
                            {item.market_name}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #EFEFEF",
                              fontWeight: 700,
                            }}
                          >
                            {item.stake}
                          </td>
                        </tr>
                        {limitModal.length === index + 1 && (
                          <tr>
                            <td></td>
                            <td
                              align="end"
                              style={{
                                fontWeight: 700,
                                paddingRight: "20px",
                              }}
                            >
                              Total
                            </td>
                            <td
                              style={{
                                borderLeft: "1px solid #EFEFEF",
                                fontWeight: 700,
                              }}
                            >
                              {details?.exposer}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </>
  );
}
