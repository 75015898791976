import React from "react";
import SportNavBar from "../common/SportNavBar";
import MatchList from "../common/MatchList";
import { connect } from "react-redux";
import SuperNowaComponent from "../../components/Games/SuperNowaComponent";

class InMatchList extends React.Component {
  constructor(props) {
    super(props);
  } 

  render() {
    let { inFixtureList, loading } = this.props;
    const sport_id = this.props.match?.params?.sport_id;
    return (
      <div className="container matchList-matchBox-outer matchBox-outer">
        <div className="content-top-padding">
          <SportNavBar matchType="inplay" sport_id={sport_id ? +sport_id : 999} />
          {+sport_id === 6 ? (
            <SuperNowaComponent {...this.props}/>
          ) : (
            <MatchList
              fixtureList={inFixtureList}
              sport_id={sport_id ? +sport_id : 999}
              loading={loading}
              history={this.props.history}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => ({
  inFixtureList: state.match.inFixtureList,
  loading: state.match.isLoader,
});
export default connect(mapPropsToState)(InMatchList);
