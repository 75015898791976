import React from "react";
import Layout from "../../components/common/Layout";
import RulesComponent from "../../components/Rules/RulesComponent";

class Rules extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <RulesComponent />
      </Layout>
    );
  }
}

export default Rules;
