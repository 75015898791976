import React, { useState } from "react";
import Modal from "react-modal";
import  "./alertdialog.css";
export default function AlertDialog(props) {
    const [isOpen, setIsOpen] = useState(props.showModel);
  
    function toggleModal() {
      setIsOpen(!isOpen);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius :10,
        },
        width :'300',
      };
  const type = props.alertType;
    return (
      <div style={{position:'relative'}}>
  
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          style={customStyles}
        >
            <div style={{minWidth:'400',display:'flex',justifyContent:'center',alignItems:'center' ,flexDirection:'column',backgroundColor:(type=='success'?'#008000':'#FF0000'),width:'100%',paddingTop:20,paddingBottom:20,paddingLeft:50,paddingRight:50}}> 
                <div  style={{padding:20,color:'#FFFFFF',fontSize:16}}> {props.message?props.message:''}</div>
                <button style={{maxWidth:100,borderRadius:25,border:0, paddingLeft:30, paddingRight:30, paddingBottom:5, paddingTop:5}} onClick={toggleModal}>OK</button>
            </div>
        </Modal>
      </div>
    );
  }