import React from "react";

class GamesUpcomingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageUrl: {},
      token: "",
      show: false,
      casino_games: [],
      token: "",
    };
  }

  componentDidMount() {
    const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));

    this.setState({ casino_games: clientLogin.casino_game_list });
    this.setState({ token: clientLogin.token });
  }

  render() {
    let { casino_games, token } = this.state;

    return (
      <>
        <div className="container">
          <div className="row" style={{ textAlign: "center" }}>
            <h2 style={{ fontWeight: "bold" }}>Coming Soon!...</h2>
            <h5>
              The Game page is under construction.If you wish to go back, please{" "}
              <a
                href="/dashboard"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack();
                }}
              >
                click here.
              </a>
            </h5>
          </div>
        </div>
      </>
    );
  }
}

export default GamesUpcomingComponent;
