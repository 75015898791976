import React, { useEffect, useState } from "react";
import { LoginModal } from "../components/Modal/LoginModal";

const NavContext = React.createContext();
const VideoContext = React.createContext();
const LoginContext = React.createContext();
const BetPlaceContext = React.createContext();

const NavProvider = NavContext.Provider;
const NavConsumer = NavContext.Consumer;

const VideoProvider = VideoContext.Provider;
const NavContextComponent = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showVideo, setShowVideo] = useState(
    sessionStorage.getItem("show_tv") == 1 ? true : false
  );
  const [isLoginModal, setLoginModal] = useState(false);
  const [isBetPlaced, setBetPlaced] = useState(false);

  const toggleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <VideoProvider value={{ showVideo, setShowVideo }}>
      <LoginContext.Provider value={{ isLoginModal, setLoginModal }}>
        <BetPlaceContext.Provider value={{ isBetPlaced, setBetPlaced }}>
          <NavProvider value={{ showMenu, toggleShowMenu }}>
            {children}
            {isLoginModal && (
              <LoginModal
                isLoginModal={isLoginModal}
                setLoginModal={setLoginModal}
              />
            )}
          </NavProvider>
        </BetPlaceContext.Provider>
      </LoginContext.Provider>
    </VideoProvider>
  );
};

export {
  NavProvider,
  NavConsumer,
  NavContextComponent,
  VideoContext,
  LoginContext,
  BetPlaceContext,
  NavContext,
};
