import React from 'react';
import closeicon from "../../images/close.png";

const VideoModal = (props) => {
    const handleClick = () => {
        props.setStateOfParent(false);
    }

    let source = props.url ;

    return (
        <div className="video_modalNew" >
            <div class="container-fluid">
                <div class="row">
                    <div style={{ position: "relative" }} class="col-xs-12 col-md-12 col-lg-12 col-sm-12">
                        {
                            props.setStateOfParent ?
                                <div >
                                    <iframe
                                        
                                        // frameBorder="0"
                                        // src={source}
                                        src={props.url}
                                         className='tvifram'
                                    />
                                </div> : null
                        }
                        <div className='closeicon' onClick={handleClick}>
                            <img src={closeicon} alt="close-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoModal
