import React from "react";
import LoginNavDetails from "./LoginNavDetails";

export default function Header() {
  return (
    <header
      role="banner"
      className="navbar navbar-fixed-top navbar-inverse navbar-background-color anvNav_menu"
    >
      <div className="container fluid-container">
       <LoginNavDetails/>
      </div>
    </header>
  );
}
