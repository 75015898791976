import React, { useEffect, useState } from "react";
import { getMatchDetailRequest } from "../../_actions";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getMarketSessionBet, getclientBalance } from "../../_services";
import LiveMatches from "../Match/LiveMatches";
import "./details.css";
import AlertDialog from "./AlertDialog";

import HeadMarquee from "../common/HeadMarquee";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { commentaryType, getDateAndTime, getLeftBazarTime } from "../../utils";
import { Button, Col, Modal, Row } from "react-bootstrap";
import OddSettingModal from "../Settings/OddSettingModal";
import MatkaDetail from "./MatkaBetUI";
import MatkaHeader from "./MatkaHeader";
import Form from "react-bootstrap/Form";
import MatkaBetList from "./MatkaBetList";
import { AiOutlineLoading } from "react-icons/ai";

const Detail = (props) => {
  const messagesEndRef = React.createRef();
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setAllStateValue] = useState({
    betAmount: 0,
    redirect: "",
    width: window.innerWidth,
    height: window.innerHeight,
    bookmakerType: 0,
    bettingType: 0,
    price: 0,
    displayPrice: 0,
    marketId: "",
    selectionId: "",
    toggleBetForm: false,
    name: "",
    marketBetData: "",
    betTotalRecords: 0,
    sessionBetData: "",
    eventId: "",
    shown: false,
    showAlertDialog: false,
    alertType: "",
    alertMessage: "",
    commentaryData: "",
    timer: 0,
    betPlacedClick: false,
  });
  const [matchDetail, setMatchDetails] = useState({});
  const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
  const [oddsStack, setOddsStack] = useState([]);
  const [odd_diff, setOddDiff] = useState("");
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [callBalanceAPI, setCallBalanceAPI] = useState(false);
  const [marketType, setActiveMarket] = useState(
    localStorage.getItem("matka_market_id")
  );
  const [matkaType, setActiveMatka] = useState("");
  const [fetchBetList, fetchMatkaBetList] = useState(false);
  const [betClosed, setBetClosed] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    return () => {
      setMatchDetails({});
      localStorage.removeItem("matka_market_id");
      window.removeEventListener("resize", updateDimensions);
      if (state.socketValue) {
        setMatchDetails({});
      }
    };
  }, []);

  useEffect(() => {
    if (params?.event_id) {
      loadData();
    }
  }, [params?.event_id]);

  useEffect(() => {
    if (state.eventId && params.event_id !== state.eventId) {
      setState({ eventId: params.event_id });
      window.location.reload();
    }
  }, [params.event_id]);

  useEffect(() => {
    getclientBalanceData();
  }, [callBalanceAPI]);

  useEffect(()=>{
    if(localStorage.getItem("matka_market_id") !== marketType){
      localStorage.setItem("matka_market_id", marketType);
    }
  },[marketType])

  const getclientBalanceData = async () => {
    getclientBalance().then((response) => {
      if (response && response.data) {
        setOddsStack(
          response.data.odds_stack ? response.data.odds_stack.split(",") : []
        );
        setOddDiff(response?.data?.odd_diff ? response.data.odd_diff : "");
      }
    });
  };

  const handleCommentaryStatusModal = (commentary) => {
    let commentaryMessage = "";
    switch ("" + matchDetail?.event?.commentary_button_id) {
      case "1":
        commentaryMessage = commentaryType[1].message;
        break;
      case commentary.commentary_button_id:
        commentaryMessage =
          commentaryType[commentary.commentary_button_id].message;
        break;
      default:
        break;
    }
    if (commentaryMessage) {
      setState({
        alertType: "error",
        showAlertDialog: true,
        alertMessage: commentaryMessage,
      });
      setTimeout(() => {
        setState({ showAlertDialog: false, alertMessage: "" });
      }, 2000);
    }

    return !!commentaryMessage;
  };

  useEffect(() => {
    if (!state.toggleBetForm) {
      clearValues();
    }
  }, [state.toggleBetForm]);

  const setState = (obj, callback) => {
    setAllStateValue((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  const clearValues = () => {
    setState({
      betAmount: 0,
      bookmakerType: 0,
      bettingType: 0,
      price: 0,
      displayPrice: 0,
      marketId: "",
      selectionId: "",
      name: "",
    });
  };
  const updateDimensions = () => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  };

  const loadData = () => {
    // window.addEventListener("resize", updateDimensions);
    fetchMatchDetailData();
  };

  useEffect(() => {
    fetchMarketBet();
  }, [matkaType, fetchBetList]);

  const fetchMatchDetailData = () => {
    const { event_id } = params;
    if (event_id) {
      dispatch(
        getMatchDetailRequest({ event_id }, (res) => {
          matchDetailParse(res);
          setState({ eventId: event_id });
        })
      );
    } else {
      setState({ eventId: event_id });
    }
  };

  const matchDetailParse = (data) => {
    let finalData = [];
    finalData = data.market;
    data.market.map((market, markey) => {
      market.runners.map((run, key) => {
        market.runner_odds.find((odd) => {
          if (run.selectionId === odd.selectionId) {
            run["runner_odd"] = odd;
            finalData[markey].runners[key] = run;
          }
        });
      });
    });
    if (data?.event?.sport_id == 1) {
      let allMarket = finalData;
      for (let i = 0; i < allMarket.length; i++) {
        const marketRunner = allMarket[i].runners;
        const updatedRunner = [];
        let drawRunner = null;
        for (let j = 0; j < marketRunner.length; j++) {
          if (marketRunner[j].runnerName == "The Draw") {
            drawRunner = marketRunner[j];
          } else {
            updatedRunner.push(marketRunner[j]);
          }
        }
        if (drawRunner) {
          updatedRunner.push(drawRunner);
        }
        allMarket[i].runners = updatedRunner;
      }
      finalData = allMarket;
    }
    data.market = finalData;
    const liveData = data.event.score ? JSON.parse(data.event.score) : "";
    const parseData = parseLiveScore(liveData, data); //ig
    data.live_msg = parseData.liveScoreMsg;
    data.live_score1 = parseData.live_score1;
    data.live_score2 = parseData.live_score2;
    handleAllactiveFancy(state.allActiveFancySessions, data);
  };

  const parseLiveScore = (liveData, data) => {
    //
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

    if (liveData) {
      let liveScore = liveData.data;
      let liveScore1 = "";
      let liveScore2 = "";
      let liveScoreMsg = "";
      let team1 = "";
      let team2 = "";
      try {
        const runners = data.market[0].runners;
        team1 = runners[0].runnerName;
        team2 = runners[1].runnerName;
      } catch (error) { }
      liveScore1 = team1 + " (" + liveScore.spnnation1 + ") ";
      liveScore2 = team2 + " (" + liveScore.spnnation2 + ") ";

      if (liveScore.score1 && liveScore.score1 != "") {
        liveScore1 += liveScore.score1 + " Ovs";
      } else {
        liveScore1 += " 0-0 (0.0) Ovs";
      }

      if (liveScore.score2 && liveScore.score2 != "") {
        liveScore2 += liveScore.score2 + " Ovs";
      } else {
        liveScore2 += " 0-0 (0.0) Ovs";
      }
      let rrr = "";
      if (liveScore.spnreqrate1 != "" || liveScore.spnreqrate2 != "") {
        rrr = liveScore.spnreqrate1
          ? liveScore.spnreqrate1
          : liveScore.spnreqrate2;
        if (rrr != "") {
          rrr = " RRR: " + rrr;
        }
      }
      liveScoreMsg = liveScore.spnmessage + rrr;
      finalData = {
        live_score1: liveScore.activenation1 == "1" ? liveScore1 : liveScore2,
        live_score2: liveScore.activenation2 == "1" ? liveScore1 : liveScore2,
        liveScoreMsg: liveScoreMsg,
      };
    }
    return finalData;
  };

  const handleAllactiveFancy = (
    allActiveFancy = allActiveFancySessions,
    matchData
  ) => {
    let fancyData = matchData?.fancy.filter((itemFacny) =>
      allActiveFancy.length
        ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
        : true
    );
    matchData.fancy = fancyData;
    setBetClosed(
      getLeftBazarTime(
        getDateAndTime("YYYY-MM-DD HH:mm:ss", matchData?.event?.open_date)
      )?.bet_sec > matchData?.event?.bet_close_time ? 1 : 0
    );
    setMatchDetails(matchData);
    setActiveMarket(
      localStorage.getItem("matka_market_id")
        ? localStorage.getItem("matka_market_id")
        : matchData?.market[0]?.market_name
    );
    setIsloading(false);
    setAllActiveFancySessions(allActiveFancy);
  };

  const fetchMarketBet = () => {
    const { event_id } = params;
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.filter = 1;
    sendData.event_id = event_id;
    sendData.market_name = matkaType;

    getMarketSessionBet(sendData)
      .then((response) => {
        if (response && response?.data?.open_bets) {
          const betData =
            response?.data?.open_bets.length > 0
              ? response?.data?.open_bets.reduce((itemArray, item) => {
                const foundItem = itemArray.find(
                  (element) => element.market_name === item.market_name
                );

                if (foundItem) {
                  foundItem.list.push(item);
                } else {
                  itemArray.push({
                    market_name: item.market_name,
                    list: [item],
                  });
                }

                return itemArray;
              }, [])
              : [];
          setState({
            marketBetData: betData,
            betTotalRecords: response.data.total_records,
          });
        } else {
          setState({ marketBetData: [], betTotalRecords: 0 });
        }
      })
      .catch((error) => {
        setState({ marketBetData: [], betTotalRecords: 0 });
      });
  };

  const setBookmakerType = (bookmakerType) => {
    setState({ bookmakerType });
  };

  const setBettingType = (bettingType) => {
    setState({ bettingType });
  };

  const setPrice = (price) => {
    setState({ price });
  };

  const setDispalyPrice = (displayPrice) => {
    setState({ displayPrice });
  };

  const setMarketId = (marketId) => {
    setState({ marketId });
  };

  const setSelectionId = (selectionId) => {
    setState({ selectionId });
  };

  const setName = (name) => {
    setState({ name });
  };

  const setTeamName = (teamName) => {
    setState({ teamName });
  };

  const setBatType = (batType) => {
    setState({ batType });
  };

  const setToggleBetForm = (val) => {
    setState({ toggleBetForm: val });

    //start timer here
    if (val) {
      updateTimer();
    } else {
      setState({ timer: 0, betPlacedClick: true });
    }
  };

  const updateTimer = () => {
    if (state.timer == 10) {
      setState({ timer: 0, toggleBetForm: false });
    } else {
      setTimeout(() => {
        let time = state.timer;

        time += 1;
        setState({ timer: time }, () => {
          if (!state.betPlacedClick) {
            updateTimer();
          }
        });
      }, 1000);
    }
  };
  const setSelectedData = (selectedData) => {
    setState({ selectedData });
  };

  const getMarketList = (allMarket = []) => {
    let filteredList = [];
    for (let i = 0; i < allMarket.length; i++) {
      if (allMarket[i].market_type == 6 || allMarket[i].market_type == 7) {
        filteredList.push(allMarket[i]);
      }
    }
    return filteredList;
  };

  const scrollToBottom = (elementId, clickOffset) => {
    if (window.screen.width > 767) {
      setTimeout(() => {
        const offset1 = document.getElementById(elementId)?.offsetTop;
        const offset = document.getElementById("place-market-bet")?.offsetTop;
        const topValue = offset - (offset1 + 400);
        window.scrollTo({
          top: clickOffset
            ? offset - clickOffset - 190
            : topValue < 500
              ? 500
              : topValue,
          behavior: "smooth",
        });
      }, 300);
    }

    return true;
  };

  const resetViewAfterYellowBtn = () => {
    let data = matchDetail;
    data.event.btn_color = "Green";
    data.event.bet_close_time = "";
    data.event.button_label = "Open All";
    data.event.commentary_button_id = 2;
    setState({ matchData: data });
  };

  const { marketBetData, sessionBetData, betTotalRecords } = state;
  return (
    <>
      <div className="container">
        <MatkaHeader
          betClosed={betClosed}
          setBetClosed={setBetClosed}
          matchDetail={matchDetail}
        />
        {isLoading ? (
          <div>
            <h4>Loading...</h4>{" "}
          </div>
        ) : !isLoading && matchDetail?.market?.length > 0 ? (
          <>
            {matchDetail?.event?.score_widget &&
              matchDetail?.event.score_widget != "" && (
                <HeadMarquee
                  announcement={
                    matchDetail?.event && matchDetail?.event.announcement
                  }
                />
              )}
            {matchDetail?.event?.upline_hide_event === 1 ? (
              <div className="row matchBox-Row">
                <h3
                  className="in-play-row-left"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Match hide, please contact to uplines.
                </h3>
              </div>
            ) : (
              <>
                {matchDetail?.market?.length > 0 && (
                  <div className="matka_menu_box content-top-padding">
                    <div>
                      <Form.Control
                        as="select"
                        onChange={(e) => setActiveMarket(e.target.value)}
                        defaultValue={marketType}
                        value={marketType}
                      >
                        {matchDetail?.market?.map((market) => (
                          <option
                            key={market?.market_name}
                            value={market?.market_name}
                            className={
                              "matka_menu_item " +
                              (marketType === market?.market_name
                                ? "active"
                                : "")
                            }
                          >
                            {market?.market_name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                    <div>
                      <Form.Label>WIN RATE</Form.Label>:{" "}
                      <span
                        style={{
                          margin: "0",
                          fontSize: "17px",
                          fontWeight: "600",
                        }}
                      >
                        {
                          getMarketList(
                            matchDetail?.market?.filter(
                              (item) => item?.market_name === marketType
                            )
                          )[0]?.winning_multiple
                        }
                      </span>
                    </div>
                  </div>
                )}
                <MatkaDetail
                  event={matchDetail?.event}
                  marketType={marketType}
                  marketList={getMarketList(
                    matchDetail?.market?.filter(
                      (item) => item?.market_name === marketType
                    )
                  )}
                  setBookmakerType={setBookmakerType}
                  setBettingType={setBettingType}
                  setPrice={setPrice}
                  setDispalyPrice={setDispalyPrice}
                  setMarketId={setMarketId}
                  setSelectionId={setSelectionId}
                  setToggleBetForm={setToggleBetForm}
                  setName={setName}
                  setTeamName={setTeamName}
                  setBatType={setBatType}
                  scrollToBottom={scrollToBottom}
                  // websocketValue={value}
                  setSelectedData={setSelectedData}
                  resetViewAfterYellowBtn={resetViewAfterYellowBtn}
                  reloadChildView={state.reloadChildView}
                  // manageSocketValue={updateScoketValue}
                  handleCommentaryStatusModal={handleCommentaryStatusModal}
                  fetchMatkaBetList={fetchMatkaBetList}
                />
              </>
            )}
          </>
        ) : (
          <>
            <div className="row matchBox-Row">
              <h3
                className="in-play-row-left"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Market Closed
              </h3>
            </div>
          </>
        )}
        {/* Bet Section after place bet start*/}
        {betTotalRecords > 0 ? (
          <MatkaBetList
            betClosed={betClosed}
            fetchMatkaBetList={fetchMatkaBetList}
            matkaType={matkaType}
            setActiveMatka={setActiveMatka}
            marketBetData={marketBetData}
            betTotalRecords={betTotalRecords}
            event={matchDetail?.event}
            matkaMarket={matchDetail?.market}
          />
        ) : null}

        <div className=""></div>
        {matchDetail?.event?.sport_id && (
          <LiveMatches
            enventId={matchDetail?.event?.event_id}
            sessionBetData={sessionBetData}
            sportsId={matchDetail?.event?.sport_id}
          />
        )}

        <div className="row mob_view">
          <div className="col-sm-4" style={{ textAlign: "center" }}></div>
        </div>
      </div>
      <div ref={messagesEndRef} />
      {state.showAlertDialog && (
        <AlertDialog
          showModel={state.showAlertDialog}
          message={state.alertMessage}
          onClose={() => setState({ showAlertDialog: false })}
          alertType={state.alertType}
        />
      )}
      {openSettingModal && (
        <Modal
          className="setting_modal"
          size="lg"
          show={openSettingModal}
          onHide={() => setOpenSettingModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <Button variant="danger" onClick={() => setOpenSettingModal(false)}>
              Close
            </Button>
          </div>
          <OddSettingModal
            data={{ odd_diff, odds_stack: oddsStack }}
            callBalanceAPI={() => setCallBalanceAPI(!callBalanceAPI)}
          />
        </Modal>
      )}
    </>
  );
};

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default connect(mapPropsToState)(Detail);
