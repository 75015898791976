import React from "react";
import { NavConsumer } from "../../context/navContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { logoutUser } from "../../_actions";
import { connect } from "react-redux";
import { AiOutlineDown } from 'react-icons/ai'
import { Link } from "react-router-dom";
class MobileNavMenu extends React.Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       redirect: "",
  //       width: window.innerWidth,
  //       height: window.innerHeight,
  //     };
  //   }
  //   updateDimensions = () => {
  //     this.setState({ width: window.innerWidth, height: window.innerHeight });
  //   };
  //   componentWillUnmount() {
  //     window.removeEventListener("resize", this.updateDimensions);
  //   }
  //   componentDidMount() {
  //     window.addEventListener("resize", this.updateDimensions);
  //   }

  logout = () => {
    this.props.dispatch(
      logoutUser((res) => {
        if (res) this.setState({ redirect: "login" });
      })
    );
  };
  render() {
    return (
      <NavConsumer>
        {(value) => {
          return (
            <>
              <div className="left-menu-slide">
                <nav
                  id="ml-menu"
                  className={value.showMenu ? "menu menu--open" : "menu"}
                >
                  <nav className="menu__breadcrumbs">
                    {/* <a></a> */}
                  </nav>
                  <nav className="menu__breadcrumbs">
                    {/* <a></a> */}
                  </nav>
                  <button
                    className="action action--close pointer"
                    aria-label="Close Menu"
                  >
                    {/* <i className="fa fa-times fa-lg" aria-hidden="true"></i> */}
                    <FontAwesomeIcon
                      icon={faXmark}
                      // size="1.5x"
                      style={{
                        color: "#fff",
                        fontSize: "1.333333em",
                        lineHeight: "0.75em",
                        verticalAlign: "-15%",
                      }}
                      className="nav-icon pointer"
                      onClick={() => value.toggleShowMenu()}
                    />
                  </button>
                  <div
                    className="menu__wrap"
                    style={{ zIndex: "9999 !important" }}
                  >
                    <ul
                      data-menu="main"
                      className="menu__level menu__level--current"
                    >
                      {/* <li className="menu__item">
                        <a
                          className="menu__link "
                          data-submenu="submenu-1"
                          href="/"
                        >
                          HOME
                        </a>
                      </li> */}
                      {[
                        { name: "IN PLAY", link: "/match/inplay/4" },
                        { name: "RULES", link: "/rules" },
                        { name: "LEDGER", link: "/ledger" },
                        { name: "PASSWORD", link: "/change_password" },
                        { name: "UPCOMING", link: "/match/upcoming/4" },
                        { name: "MATKA", link: "/match/inplay/999" },
                        { name: "SETTINGS", link: "/settings" },
                        { name: "GAMES", link: "/match/inplay/6" },
                        { name: "STATMENT", link: "/account-statement" },
                      ].map(item =>
                        < li key={item?.name}
                          className="menu__item" >
                          <Link
                            className={(window.location.pathname === item?.link ? "active-page " : "") + "menu__link "}
                            data-submenu="submenu-2"
                            to={item?.link}
                            onClick={() => value.toggleShowMenu()}
                          >
                            {item?.name}
                          </Link>
                          <AiOutlineDown />
                        </li>)}
                      <li className="menu__item">
                        <a
                          className="menu__link"
                          data-submenu="submenu-3"
                          href="/"
                          data-method="delete"
                          rel="nofollow"
                          onClick={(e) => {
                            e.preventDefault();
                            value.toggleShowMenu()
                            this.logout();
                          }}
                        >
                          LOGOUT
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav >
              </div>
            </>
          );
        }
        }
      </NavConsumer >
    );
  }
}

const mapPropsToState = (state) => ({
  clientLogin: state.auth.clientLogin,
});
export default connect(mapPropsToState)(MobileNavMenu);
