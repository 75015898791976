import React from "react";
import SportNavBar from "../common/SportNavBar";
import MatchList from "../common/MatchList";
import { connect } from "react-redux";

class UpMatchList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { upFixtureList } = this.props;
    return (
      <div className="container matchList-matchBox-outer matchBox-outer">
        <div className="content-top-padding">
          <SportNavBar matchType="upcoming" upFixtureList={upFixtureList}/>
          <MatchList fixtureList={upFixtureList} />
        </div>
      </div>
    );
  }
}

const mapPropsToState = (state) => ({
  upFixtureList: state.match.upFixtureList,
});
export default connect(mapPropsToState)(UpMatchList);
