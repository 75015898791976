import React from "react";
// import MatchList from "../common/MatchList";
import { connect } from "react-redux";
import VideoModal from "./VideoIframe";

class GamesComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ImageUrl: {},
      token: "",
      show: false,
      casino_games: [],
      token: ''
    };
  }

  componentDidMount() {
    const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));

    this.setState({ casino_games: clientLogin.casino_game_list });
    this.setState({ token: clientLogin.token });
  }

  render() {

    let { casino_games, token } = this.state;

    return (
      <>
        <div className="container">
          <div className="row cst_row">
            
              {casino_games &&
                casino_games.map((element, i) => {
                  return (
                    <div className="game-box Game_ui_responsive" key={i}>
                      <a href={element.game_url + '/' + token}>
                        <img
                          className="game-img"
                          src={element.image}
                          alt=""
                        />
                        <p className="Game_last_name"> {element.game_name} </p>
                        <div className="game-inner">
                          <div className="icn">
                            <div className="icn-inner">
                              <img src="/images/play-button.svg" alt="" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          
        </div>

      </>
    );
  }
}

export default GamesComponent;