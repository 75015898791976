import React from "react";
import SportNavBar from "../common/SportNavBar";
import MatchList from "../common/MatchList";
import { connect } from "react-redux";

class TournamentComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { inFixtureList } = this.props;
    return (
      <>
        <div className="container">
          <div className="content-top-padding">
            <div className="cricket-football-main-div"></div>
            <div className="row alert-match-status-box" id="alert-status">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alert-match-status-text">
                <span>Currently No Tournament Available</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapPropsToState = (state) => ({
  inFixtureList: state.match.inFixtureList,
});
export default connect(mapPropsToState)(TournamentComponent);
