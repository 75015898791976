import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const getDisplayValue = (value) => {
  try {
    if (value) {
      return parseFloat(value).toFixed(2);
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

const SessionBet = (props) => {
  const { sessionBetData } = props;
  const [toggle, setToggle] = useState(true);
  return (
    <>
      <div className="row" style={{ paddingTop: "0px" }}>
        <table className="table table-bordered">
          <thead>
            <tr className="pointer">
              <th
                colSpan="7"
                className="bet-place-tbl-th market_type_row market-name-row-color"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    SESSION BETS {"{ " + sessionBetData.length + " }"}
                  </div>
                  <div className="d-flex" onClick={() => setToggle(!toggle)}>
                    {toggle ? (
                      <AiOutlineDown style={{ marginLeft: "10px" }} />
                    ) : (
                      <AiOutlineUp style={{ marginLeft: "10px" }} />
                    )}
                  </div>
                </div>
              </th>
            </tr>
            {toggle && (
              <tr>
                <th colSpan={3}className="bet-place-tbl-th mob-col-width-ch run-pos-rate-amt-run-mod-color">
                  SESSION
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  RUNS
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  RATE
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  AMOUNT
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  MODE
                </th>
              </tr>
            )}
          </thead>
          {toggle && (
            <tbody>
              {sessionBetData.map((session, index) => (
                <tr
                  key={index}
                  id={`runs-${session.betting_type}-value-box-color`}
                >
                  <td
                    className="fancy_session_th bet-place-tbl-td mob-col-width-ch session-value-box-color"
                    style={{ textAlign: "left" }}
                    colSpan={3}
                  >
                    {session.market_name}
                  </td>
                  <td colSpan={1}
                   className="bet-place-tbl-td md-trigger session-value-box-color">
                    {session.line}
                  </td>
                  <td colSpan={1} className="bet-place-tbl-td md-trigger session-value-box-color">
                    {getDisplayValue(session.display_price)}
                  </td>
                  <td colSpan={1} className="bet-place-tbl-td session-value-box-color">
                    {" "}
                    {session.stake}
                  </td>
                  <td colSpan={1} className="bet-place-tbl-td session-value-box-color">
                    {" "}
                    {session.betting_type === "1" ? "YES" : "NO"}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default SessionBet;
