// import { data } from "jquery";

import React from "react";
import { connect } from "react-redux";
import { commentaryType, showMinMax } from "../../utils";
import { setBetRequest } from "../../_actions";
import { getMarketPosition } from "../../_services"; // comment :reason for remove calculate_market_winloss
import { BsFillLockFill, BsInfoCircle } from "react-icons/bs";
import { AiFillMail, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FloatingField } from "../FloatingField";
import { BiSend } from "react-icons/bi";
class MarketDetail extends React.Component {
  state = {
    positionData: [],
    width: window.innerWidth,
    height: window.innerHeight,
    flag: true,
    showInput: false,
    detailsId: []
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setBet = (bettype, price, run, market) => {
    let betData = {
      event_id: market.event_id,
      market_id: market.market_id,
      selectionId: run.selectionId,
      name: run.runnerName,
      market_name: market.market_name,
      price,
      bookmaker_type: 2,
      bettype,
    };
    this.props.dispatch(setBetRequest(betData));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  getMarketPositionData = async () => {
    let arrObj = [];
    // let v = [];
    this.props.marketList.forEach(async (market) => {
      let sendData = {};
      sendData.event_id = market.event_id;
      sendData.market_id = market.market_id;
      getMarketPosition(sendData)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.runners &&
            response.data.runners.length
          ) {
            let runnersData = JSON.parse(JSON.stringify(response.data.runners));
            runnersData.forEach((val) => (val.market_id = market.market_id));
            arrObj = [...arrObj, ...runnersData];
            this.setState({ positionData: arrObj });
          } else {
            arrObj = [];
          }
        })
        .catch((error) => { });
    });
  };

  fetchMarketPosition = (market_id, selectionId) => {
    let valData;

    if (this.state.positionData.length > 0) {
      this.state.positionData.forEach((val, index) => {
        if (val.selectionId == selectionId && val.market_id == market_id) {
          valData = val.winloss;
        }
      });
      return valData;
    }
  };

  getDisplayValue = (value, market_type, isLagai) => {
    try {
      if (value) {
        if (market_type) {
          if (market_type === 1 || market_type === 4 || market_type === 5 || market_type === 6 || market_type === 7) {
            if (market_type == 1 && isLagai && value > 1.2) {
              value = value - 0.01;
            }

            let val = value * 100;
            let calValue = ((parseFloat(val) - 1 * 100) / 100).toFixed(2);

            return calValue;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val.toFixed(2)) - 1 * 100;
          } else {
            return value;
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  getMarketList(list) {
    for (let i = 0; i < list.length; i++) {
      let runners = list[i].runners;
      let showLagaiOnly = false;
      let lagaiBhav1 = 0;
      let lagaiBhav2 = 0;
      if (runners) {
        for (let j = 0; j < runners.length; j++) {
          let resData = this.isFavTeam(list[i], runners, j);
          runners[j].isFav = resData.isFav;
          if (!showLagaiOnly) {
            showLagaiOnly = resData.showLagaiOnly;
            lagaiBhav1 = resData.lagaiBhav1;
            lagaiBhav2 = resData.lagaiBhav2;
          }
        }
        if (showLagaiOnly) {
          list[i].showLagaiOnly = showLagaiOnly;
          list[i].lagaiBhav1 = lagaiBhav1;
          list[i].lagaiBhav2 = lagaiBhav2;
        }
        list[i].runners = runners;
      }
    }
    return list;
  }

  getValueForLagai(market, run, index, event) {
    let bhav = "";
    if (event?.sport_id == 1 || (event?.sport_id === 4 && market.runner_odds.length == 3)) {
      bhav = this.getDisplayValue(run?.runner_odd?.ex?.availableToBack[0]?.price, market.market_type, true);
      if (bhav > 10 && market.market_type != 2) {
        return '';
      }
      if (
        bhav != "" &&
        event?.btn_color &&
        ((event?.btn_color == "Red" &&
          event?.commentary_button_id != 5) ||
          event?.btn_color == "Yellow")
      ) {
        bhav = 0;
      }
      return bhav;
    }
    if (market.showLagaiOnly && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.showLagaiOnly && index == 1) {
      bhav = market.lagaiBhav2;
    }
    else if (market.market_type == 5 && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.market_type == 5 && index == 1) {
      bhav = market.lagaiBhav2;
    } else if (market.market_type == 6 && index == 0) {
      bhav = market.lagaiBhav1;
    } else if (market.market_type == 6 && index == 1) {
      bhav = market.lagaiBhav2;
    } else if (market.market_type == 7) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
    } else if (run.isFav) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToBack[0]?.price,
        market.market_type,
        true
      );
    }

    if (
      bhav != "" &&
      event?.btn_color &&
      ((event?.btn_color == "Red" &&
        event?.commentary_button_id != 5) ||
        event?.btn_color == "Yellow")
    ) {
      bhav = 0;
      if (event?.btn_color == "Yellow") {
        // this.reloadView();
      }
    }
    return bhav;
  }

  reloadView() {
    setTimeout(() => {
      this.props.resetViewAfterYellowBtn();
    }, 3000);
  }

  getValueForKhai(market, run, event) {
    let bhav = "";
    if (event?.sport_id === 1 || (event?.sport_id === 4 && market.runner_odds.length == 3)) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
      if (bhav > 10 && market.market_type != 2) {
        return '';
      }
      if (
        bhav != "" &&
        event?.btn_color &&
        ((event?.btn_color == "Red" &&
          event?.commentary_button_id != 5) ||
          event?.btn_color == "Yellow")
      ) {
        bhav = 0;
      }
      return bhav;
    }
    if (run.isFav && !market.showLagaiOnly) {
      bhav = this.getDisplayValue(
        run?.runner_odd?.ex?.availableToLay[0]?.price,
        market.market_type,
        false
      );
    }

    if (
      bhav != "" &&
      event?.btn_color &&
      ((event?.btn_color == "Red" &&
        event?.commentary_button_id != 5) ||
        event?.btn_color == "Yellow")
    ) {
      bhav = 0;
    }
    return bhav;
  }

  showMarket(market) {
    let show = false;
    if (market) {
      for (let i = 0; i < market.runners.length; i++) {
        if (market.runners[i].lock_team_betting == 0) {
          show = true;
          break;
        }
      }
    }
    if (market.market_type == 2 || market.market_type == 1) {
      return true;
    } else {
      return show;
    }
  }

  checkValueIsExist = (value = false) => {
    return value > 0 || value < 0
  }

  getTableRow(event, market, run, index) {
    let {

      setBookmakerType,
      setBettingType,
      setPrice,
      setDispalyPrice,
      setMarketId,
      setSelectionId,
      setToggleBetForm,
      setName,
      setTeamName,
      setBatType,
      scrollToBottom,
      setSelectedData,
    } = this.props;

    const lagaiValue = this.getValueForLagai(market, run, index, event);
    const khaiValue = this.getValueForKhai(market, run, event);
    let bgColorLagai = '';
    let bgColorKhai = '';
    let showRow = true;
    let cellHeight = 'auto';

    if (run.lock_team_betting) {
      bgColorLagai = '#dddddd';
      bgColorKhai = '#dddddd';
    }
    else if (event?.sport_id == 1 && lagaiValue != '' && khaiValue != '') {
      bgColorLagai = 'darkgreen';
      bgColorKhai = 'darkgreen';
    }
    else if (event?.sport_id == 1 && (lagaiValue == '' || khaiValue == '')) {
      bgColorLagai = '';
      bgColorKhai = '';
    }
    else if (market.market_type == 6 || market.market_type == 7) {
      if (index != 0) {
        bgColorLagai = '';
        bgColorKhai = '';
      }
      else if (lagaiValue != '' && lagaiValue != 0) {
        bgColorLagai = 'darkgreen';
      }
      else {
        bgColorLagai = '';
        bgColorKhai = '';
      }
    }
    else if (market.market_type == 5) {
      if (lagaiValue != '' && lagaiValue != 0) {
        bgColorLagai = 'darkgreen';
      }
      bgColorKhai = '';
    }
    else if (run.isFav) {
      bgColorLagai = 'darkgreen';
      bgColorKhai = 'darkgreen';
    }
    else if (market.showLagaiOnly) {
      bgColorLagai = 'darkgreen';
      bgColorKhai = '';
    }
    const isSoccer = (event?.sport_id == 1);
    let displayValueLagai = '';
    let displayValueKhai = '';
    if (isSoccer) {
      if (market.market_type != 1) {
        if ((run.runnerName).includes('Over')) {
          displayValueLagai = lagaiValue;
          displayValueKhai = khaiValue;
          bgColorLagai = 'darkgreen';
          bgColorKhai = 'darkgreen';
          // cellHeight = 65;
        } else if ((run.runnerName).includes('Under')) {
          bgColorLagai = '';
          bgColorKhai = '';
        }
        else {
          // showRow = false;
          bgColorLagai = '';
          bgColorKhai = '';
        }
      }
      else {
        displayValueLagai = lagaiValue;
        displayValueKhai = khaiValue;
      }
    }
    return (
      showRow &&
      <tr>

        <td colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? this.state.width <= 768 ? 7 : 6 : this.state.width <= 768 ? 6 : 5} data-column="runner_name" className="flag font-sans-serif"
          style={{
            height: cellHeight, textAlign: "left", paddingTop: '12px', fontWeight: "bold",
          }}
        >
          {run.runnerName}
        </td>
        {
          //   ''}

          // {
          (market.market_type == 2
            && market.match_status != 0 && market.match_status != "ACTIVE") ?
            <td
              id="run-pos-rate-amt-run-mod-color"
              colSpan={4} style={{ backgroundColor: "#33333380", height: cellHeight, color: 'red' }} data-column="back"

              className=" fw-80 open-keyboard MarketTable first-runner-bet-odds-yes-value cursor-pointer active-lagai-bet-box-color close-status font-sans-serif live-score-extraB"
            >
              {market.match_status}
            </td>
            :
            run.lock_team_betting == 1 ?
              // <td colSpan={4}>
              //   <div className="lock_team_betting">
              //     <p>SUSPENDED</p>
              //   </div>
              // </td>
              <td
                id="run-pos-rate-amt-run-mod-color"
                colSpan={4} style={{ backgroundColor: "#33333380", height: cellHeight, color: 'red' }} data-column="back"

                className=" fw-80 open-keyboard MarketTable first-runner-bet-odds-yes-value cursor-pointer active-lagai-bet-box-color close-status font-sans-serif live-score-extraB"
              >
                SUSPENDED
              </td>
              :
              <>
                <td
                  id={run.runnerName + index + "LAGAI"}
                  colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 3 : 2} style={{ height: cellHeight }} data-column="back"
                  onClick={(e) => {
                    if (this.props.handleCommentaryStatusModal(commentaryType[3])) {
                      return
                    }
                    if (run.lock_team_betting) {
                      return false;
                    } else {
                      if ((run.isFav || market.showLagaiOnly) || (!isSoccer && market.runner_odds.length == 3) || (isSoccer && displayValueLagai != '')) {
                        if (
                          run?.runner_odd?.ex?.availableToBack[0]
                            .price &&
                          parseFloat(
                            run?.runner_odd?.ex?.availableToBack[0]
                              .price
                          ) &&
                          parseFloat(
                            run?.runner_odd?.ex?.availableToBack[0]
                              .price
                          ) > 0
                        ) {
                          if (this.checkValueIsExist(lagaiValue)) {
                            setBookmakerType(2);
                            setBettingType(1);
                            setPrice(
                              run?.runner_odd?.ex?.availableToBack[0]
                                .price
                            );
                            setDispalyPrice(
                              isSoccer ? lagaiValue :
                                (!isSoccer && market.runner_odds.length == 3) ? lagaiValue
                                  : market.showLagaiOnly ? market.lagaiBhav1
                                    : market.market_type == 5 && index == 0
                                      ? market.lagaiBhav1
                                      : market.market_type == 5 && index == 1
                                        ? market.lagaiBhav2
                                        : run.isFav && this.getDisplayValue(run?.runner_odd?.ex?.availableToBack[0]?.price, market.market_type, true)
                            );
                            setMarketId(market.market_id);
                            setSelectionId(run.selectionId);
                            setName(market.market_name);
                            setTeamName(run.runnerName);
                            setSelectedData(market);
                            setBatType("LAGAI");
                            setToggleBetForm(true);
                            scrollToBottom(run.runnerName + index + "LAGAI", e.pageY);
                          }
                        }
                      }
                    }
                  }}
                  className={
                    (run.lock_team_betting ? " lock_team_betting_pt " : "") +
                    (this.checkValueIsExist(lagaiValue) ? 'cursor-pointer' : '') + " fw-80 open-keyboard MarketTable first-runner-bet-odds-yes-value active-lagai-bet-box-color close-status font-sans-serif live-score-extraB lagai-box-color"}
                >
                  <div className={run.lock_team_betting ? "lockedBx" : ''}>
                    <div className={run.lock_team_betting ? "lockedNum" : 'bhav_line_height'}>
                      {isSoccer ? displayValueLagai
                        : ((market.market_type == 5) ? lagaiValue
                          : ((market.market_type == 6 || market.market_type == 7) && lagaiValue != 's') ? lagaiValue
                            : ((market.market_type != 6 && market.market_type != 7)) ? lagaiValue
                              : 's')

                      }
                    </div>
                    {run.lock_team_betting ?
                      <div className="lock_team_betting">
                        <BsFillLockFill size={26} color="red" />
                      </div>
                      : ''}
                  </div>
                </td>
                {(market.market_type !== 6 && market.market_type !== 7 && market.market_type !== 5) &&
                  <>
                    <td
                      height={"40px"}
                      id={run.runnerName + index + "KHAI"}
                      colSpan={2}
                      style={{
                        height: cellHeight
                      }}
                      data-column="lay"
                      onClick={(e) => {
                        if (this.props.handleCommentaryStatusModal(commentaryType[3])) {
                          return
                        }
                        if (run.lock_team_betting) {
                          return false;
                        } else {
                          if ((run.isFav && !market.showLagaiOnly) || (!isSoccer && market.runner_odds.length == 3) || (isSoccer && displayValueKhai != '')) {
                            if (
                              run?.runner_odd?.ex?.availableToLay[0]
                                .price &&
                              parseFloat(
                                run?.runner_odd?.ex?.availableToLay[0]?.price
                              ) &&
                              parseFloat(
                                run?.runner_odd?.ex?.availableToLay[0]?.price
                              ) > 0
                            ) {
                              if (this.checkValueIsExist(khaiValue)) {
                                setBookmakerType(2);
                                setBettingType(2);
                                setPrice(
                                  run?.runner_odd?.ex?.availableToLay[0]?.price
                                );
                                setDispalyPrice(
                                  run?.runner_odd?.ex?.availableToLay[0]?.price
                                );
                                setMarketId(market.market_id);
                                setSelectionId(run.selectionId);
                                setName(market.market_name);
                                setTeamName(run.runnerName);
                                setSelectedData(market);
                                setBatType("KHAI");
                                setToggleBetForm(true);
                                scrollToBottom(run.runnerName + index + "KHAI", e.pageY);
                              }
                            }
                          }
                        }
                      }}
                      className={
                        (run.lock_team_betting ? " lock_team_betting_pt " : "") +
                        (this.checkValueIsExist(khaiValue) ? 'cursor-pointer' : '') + " fw-80 open-keyboard MarketTable first-runner-bet-odds-no-value active-khai-bet-box-color close-status font-sans-serif khai-box-color"}
                    >
                      <div className={run.lock_team_betting ? "lockedBx" : ''}>
                        <div className={run.lock_team_betting ? "lockedNum" : 'bhav_line_height'}>
                          {isSoccer ? displayValueKhai
                            :
                            (market.market_type == 5 ? khaiValue
                              : ((market.market_type == 6 || market.market_type == 7) && khaiValue != '') ? khaiValue
                                : ((market.market_type != 6 && market.market_type != 7)) ? khaiValue
                                  : '')
                          }
                        </div>
                        {run.lock_team_betting ?

                          <div className="lock_team_betting">
                            <BsFillLockFill size={26} color="red" />
                          </div>
                          : ""}
                      </div>
                    </td>
                  </>}
              </>}
        <td colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 3 : 3}
          style={{ height: cellHeight, paddingTop: '12px' }}
          data-column="position"
          className="bet-val-cent font-sans-serif"
          data-position="0"
        >
          <strong className={run.winloss > 0 ? "text-success" : "text-danger"}>{run.winloss}</strong>
        </td>
      </tr>
    )
  }


  toggleDetailsMenu = (id) => {
    const detailsArray = this.state?.detailsId;
    if (detailsArray.indexOf(id) >= 0) {
      detailsArray.splice(detailsArray.indexOf(id), 1);
    } else {
      detailsArray.push(id);
    }
    this.setState({ detailsArray: detailsArray })
  };

  render() {
    let { event, marketList } = this.props;
    marketList = marketList ? this.getMarketList(marketList) : [];
    return (
      <>
        <div className="row scroll-form" style={{ paddingTop: "15px" }}>
          {marketList.map((market, mIndex) => (
            this.showMarket(market) &&
            <table
              className="table table-bordered font-sans-serif anvSesstion_table"
              key={mIndex}
            >
              <thead>
                <tr>
                  <th
                    colSpan={this.state.width <= 768 ? "13" : "12"}
                    className="bet-place-tbl-th market_type_row market-name-row-color font-sans-serif ls-2"
                  >
                    <div className="anvHead_mark">
                      {/* <AiFillMail 
                        style={{cursor:"pointer"}}
                        color="#fff"
                        size={24}
                        className=""
                        onClick={() => this.setState({ showInput: !this.state?.showInput })}
                       /> */}
                      <div
                        className="pointer"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 12,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {market?.market_type == 7
                            ? "Lottery 9.50"
                            : "Market"}{" "}
                          : {market.market_name}
                        </div>
                        <div className="d-flex" onClick={() => this.toggleDetailsMenu(mIndex)}>
                          {!this.state.detailsId.includes(mIndex)
                            ? <AiOutlineDown style={{ marginLeft: "10px" }} />
                            : <AiOutlineUp style={{ marginLeft: "10px" }} />
                          }
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
                {!this.state.detailsId.includes(mIndex) && <tr>
                  <th colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? this.state.width <= 768 ? 7 : 6 : this.state.width <= 768 ? 6 : 5} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif">
                    <div style={{ display: 'flex', paddingTop: 3 }}>
                      {showMinMax("market", market, event, true)}
                    </div>
                  </th>
                  {(market.market_type !== 6 && market.market_type !== 7 && market.market_type !== 5) ?
                    <>
                      <th colSpan={2} className="ttt bet-odds-type bet-place-tbl-th lagai-box-color font-sans-serif">
                        LAGAI
                      </th>
                      <th colSpan={2} className="ttt bet-odds-type bet-place-tbl-th khai-box-color font-sans-serif">
                        KHAI
                      </th>
                    </>
                    :
                    <th colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 3 : 3} className="mm bet-odds-type bet-place-tbl-th khai-box-color font-sans-serif">
                      RATE
                    </th>
                  }
                  <th colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 3 : 3} className="mm bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif">
                    POSITION
                  </th>
                </tr>}
              </thead>

              {!this.state.detailsId.includes(mIndex) &&
                <tbody>
                  {market.runners.map((run, index) => (
                    <React.Fragment key={index}>
                      {(event?.sport_id == 1 && market.market_type != 1 && (run.runnerName).includes('Over') && market.match_status != 'OPEN') || (event?.sport_id == 1 && market.match_status != 'OPEN' && market.market_type == 1) ? (
                        <tr>
                          <td
                            colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 4 : this.state.width <= 768 ? 6 : 5}
                            data-column="runner_name"
                            className="flag font-sans-serif"
                            style={{
                              fontWeight: "bold",
                              textAlign: "left",
                              backgroundColor: "",
                            }}
                          >
                            {run.runnerName}
                          </td>
                          <td
                            colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 4 : 4}
                            className="text-red fw-80 open-keyboard MarketTable cursor-pointer close-status font-sans-serif"
                            style={{
                              color: '#FF0000',
                              textAlign: "center",
                              fontWeight: this.state.width <= 768 ? "none" : "bold",
                              backgroundColor: "#ECECEC",
                            }}
                          >
                            {(event?.sport_id == 1 && market.match_status != 'OPEN' && market.match_status != 0) && market.match_status}
                          </td>

                          <td
                            colSpan={(market.market_type == 5 || market.market_type == 6 || market.market_type == 7) ? 4 : 3}
                            data-column="runner_name"
                            className="flag font-sans-serif"
                            style={{
                              textAlign: "left",
                              fontWeight:
                                this.state.width <= 768 ? "none" : "bold",
                              backgroundColor: "",
                            }}
                          >
                          </td>
                        </tr>
                      ) : (

                        this.getTableRow(event, market, run, index)
                      )}
                    </React.Fragment>
                  ))}
                </tbody>}
              {this.state.showInput === true &&
                <tbody >
                  <tr>
                    <td id="anvTD" colSpan={"12"} style={{ padding: '2px', border: 0 }}>
                      <div className="anvAnounce_InputBx">
                        <input />
                        <Button><BiSend /></Button>
                      </div>

                    </td>
                  </tr>

                </tbody>}
              {market.announcement && <tfoot>
                <tr>
                  <th colSpan={this.state.width <= 768 ? 13 : 12}
                    style={{
                      whiteSpace: "initial",
                      background: "#45bccb",
                      color: "#fff",
                      borderRadius: "0"
                    }}
                  > <marquee>
                      {market.announcement}
                    </marquee> </th>
                </tr>
              </tfoot>}
            </table>

          ))}
        </div>
      </>
    );
  }

  isFavTeam(market, runners, index) {
    const minBhavLimit = 0.96;
    const maxBhavLimit = 1.0;
    let showLagaiOnly = false;
    let lagaiBhav1 = 0;
    let lagaiBhav2 = 0;
    let lagai1 = this.getDisplayValue(
      runners[0]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let lagai2 = this.getDisplayValue(
      runners[1]?.runner_odd?.ex?.availableToBack[0]?.price,
      market.market_type,
      true
    );
    let isFav = false;
    let resultData = {};
    const isSoccer = (this.props.event?.sport_id == 1);
    if (isSoccer) {
      if (market.market_type != 1) {
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
        if (market.market_type == 5 || market.market_type == 6 || market.market_type == 7) {
          showLagaiOnly = true;
          lagaiBhav1 = lagai1;
          lagaiBhav2 = lagai2;
        }
        resultData = {
          isFav: isFav,
          showLagaiOnly: showLagaiOnly,
          lagaiBhav1: lagaiBhav1,
          lagaiBhav2: lagaiBhav2,
        };
      }
    }
    else {
      if (runners.length == 3) {
        //TEST MATCH

        let lagai3 = this.getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToBack[0]?.price,
          market.market_type,
          true
        );
        let khai3 = this.getDisplayValue(
          runners[2]?.runner_odd?.ex?.availableToLay[0]?.price,
          market.market_type,
          false
        );
        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            // showLagaiOnly = true;
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            //  showLagaiOnly = true;
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 2) {
          if (khai3 > 9.99 && lagai3 > 9.99) {
            isFav = false;
          } else {
            isFav = true;
          }
        }
      } else if (runners.length == 2) {
        //Other MATCH

        if (index == 0) {
          if (lagai1 < lagai2) {
            isFav = true;
          }

          if (lagai1 < maxBhavLimit && lagai1 > minBhavLimit) {
            if (lagai1 < lagai2) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        } else if (index == 1) {
          if (lagai2 < lagai1) {
            isFav = true;
          }
          if (lagai2 < maxBhavLimit && lagai2 > minBhavLimit) {
            if (lagai2 < lagai1) {
              lagaiBhav1 = lagai1;
              lagaiBhav2 = lagai2;
            }
          }
        }
        // show only lagai value if runner value is 2 
        if (market.market_type == 2 && lagai1 >= 94) {
          showLagaiOnly = true;
          isFav = false;
          lagaiBhav1 = lagai1;
          lagaiBhav2 = lagai2;
        }
      }
      if (market.market_type == 2) {
        // if (market.runner_odds[index]?.status == 'ACTIVE') {
        const back = runners[index]?.runner_odd?.ex?.availableToBack[0]?.price
        const lay = runners[index]?.runner_odd?.ex?.availableToLay[0]?.price
        const selectedRunner = market.runner_odds.find(item => runners[index]?.selectionId == item?.selectionId)
        if (selectedRunner?.status == 'ACTIVE' && (lay || back)) {
          if (lagai1 < 100 && lagai1 > 95) {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai1;
            showLagaiOnly = true;
            isFav = false;
          }
          else if (lagai2 < 100 && lagai2 > 95) {
            showLagaiOnly = true;
            lagaiBhav1 = lagai2;
            lagaiBhav2 = lagai2;
            isFav = false;
          }
          else {
            lagaiBhav1 = lagai1;
            lagaiBhav2 = lagai2;
            isFav = true;
            showLagaiOnly = false;
          }
        }
        else {
          isFav = false;
        }

      }
      // hide value if lagai value is gt 100
      // if (market.market_type == 2 && lagai1 >= 100 ) {
      //   showLagaiOnly = false;
      //   isFav = false;
      // }

      if (market.market_type == 5 || market.market_type == 6 || market.market_type == 7) {
        showLagaiOnly = true;
        lagaiBhav1 = lagai1;
        lagaiBhav2 = lagai2;
      }

      if (+this?.props?.event?.cup_event === 1) {
        isFav = true;
      }

      resultData = {
        isFav: isFav,
        showLagaiOnly: showLagaiOnly,
        lagaiBhav1: lagaiBhav1,
        lagaiBhav2: lagaiBhav2,
      };
    }
    return resultData;
  }
}

export default React.memo(MarketDetail)
