import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import "./ScoreWidget.css"
import WebSocketContextComponent from "../../context/webSocketContext";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";



const ScoreWidgets2 = () => {

    let { id } = useParams();
    const [socket, setSocket] = useState(false);
    const [toggleRoom, setToggleRoom] = useState(false);
    const [allMarketIds, setAllMarketIds] = useState('');
    const [updateData, setUpdateData] = useState('')
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setAllMarketIds(id)
    }, [])

    const updateSocketResponse = (data) => {


        if (data?.updateScore?.match_id === id) {
            const liveData = data.updateScore.score
                ? JSON.parse(data.updateScore.score)
                : "";

            setUpdateData(liveData?.data)

            // setUpdateData(data?.data)
        }

    };




    return (
        <div className="container">
            {allMarketIds && <WebSocketContextComponent
                marketList={[allMarketIds]}
                handleSocketData={(e) => {
                    updateSocketResponse(e);
                }}
                closeAllRoom={toggleRoom}
                setSocket={setSocket}
                socket={socket}
            // event_id={event_id}
            />}
            <div className="row content-top-padding">
                <div
                    className="content_matches_wrapper "
                    id="content_matches_wrapper"
                >
                    <div className=" col-lg-8 col-xs-9 col-md-8 col-sm-7 bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color anvWidget score_widget">
                        <p
                            className="inplay-show-tbl-td match-status-text match-score-box-color "
                            id="score_table_383067"
                        // style={{ fontFamily: "sans-serif !imp  ortant" }}
                        >
                            <span
                                className="match-score-span-1 font-sans-serif"
                            // style={{ fontFamily: "sans-serif !im  portant" }}
                            >
                                {updateData ? `${updateData?.teams?.map((i) => i)[0].team_name} - ${updateData.teams?.map((i) => i?.score)[0]}` : ''
                                }
                            </span>
                            <span className="match-score-span-2 font-sans-serif">
                                {updateData ? `${updateData?.teams?.map((i) => i)[1].team_name} - ${updateData.teams?.map((i) => i?.score)[1]}` : ""
                                }
                            </span>
                            <span className="match-score-span-2 font-sans-serif">
                                {updateData?.msg} {updateData?.requireRunRate && <span>{`RRR: ${updateData?.requireRunRate}`}</span>}


                            </span>
                            {/* {<span className="match-score-span-2 font-sans-serif">

                            </span>} */}
                            <span className="match-score-span-2 font-sans-serif">
                                <div className="scoreBall_list anvFlex">
                                    <ul>
                                        <li className="LastBall">
                                            CR Over
                                        </li>
                                        {updateData ? updateData?.last24balls?.reverse().map((i) => <li>
                                            <span>{i?.score_card ? i?.score_card : 0}</span>
                                        </li>) : <><li>
                                            <span>0</span>
                                        </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                            <li>
                                                <span>0</span>
                                            </li>
                                        </>}

                                    </ul>
                                </div>
                            </span>
                            <span className="match-score-span-2 font-sans-serif anvBottom_arrow">

                                {open ? <FontAwesomeIcon icon={faArrowUp} className="refresh_img" onClick={() => {
                                    setOpen(!open)

                                }} /> :
                                    <FontAwesomeIcon icon={faArrowDown} className="refresh_img" onClick={() => {
                                        setOpen(!open)

                                    }} />}



                            </span>
                            {open && <span className="match-score-span-2 font-sans-serif">
                                {/* <div className='row'> */}
                                <div show={open} onHide={() => setOpen(false)} className="table_show">
                                    <Table className="score2_table">
                                        <thead>
                                            <tr className="tableHeadof_score">
                                                <th>Batsman</th>
                                                <th>R</th>
                                                <th>B</th>
                                                <th>4s</th>
                                                <th>6s</th>
                                                <th>SR</th>
                                            </tr>
                                        </thead>
                                        {updateData ? updateData?.currentPlayersScore?.Batsman?.map((i) => <tbody>
                                            <tr>
                                                <td>{i?.on_play ? i?.on_play : ""}</td>
                                                <td>{i?.runs ? i?.runs : ""}</td>
                                                <td>{i?.balls ? i?.balls : ""}</td>
                                                <td>{i?.fours ? i?.fours : ""}</td>
                                                <td>{i?.sixes ? i?.sixes : ''}</td>
                                                <td>{i?.strike_rate === "NaN" ? "" : i?.strike_rate}</td>
                                            </tr>

                                        </tbody>) : <><td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td><td></td></>}
                                    </Table>
                                    {updateData?.currentPlayersScore?.bowler?.player_name && <div className="bowler_name">
                                        {` Bowler -
                                        ${updateData?.currentPlayersScore?.bowler?.player_name} `}
                                    </div>}

                                    {/* {data[0]?.data?.currentPlayersScore?.bowler?.player_name && <div className="bowler_name">
                                        {` Bowler -
                                        ${data[0]?.data?.currentPlayersScore?.bowler?.player_name} `}
                                    </div>} */}
                                    {/* <Table className="score2_table">
                                        <thead >
                                            <tr className="tableHeadof_score">
                                                <th>Bowler</th>
                                                <th>R</th>
                                                <th>B</th>
                                                <th>4s</th>
                                                <th>6s</th>
                                                <th>SR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{updateData?.currentPlayersScore?.bowler?.player_name ? updateData?.currentPlayersScore?.bowler?.player_name : ""}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </Table> */}

                                </div>
                                {/* </div> */}

                            </span>}

                        </p>
                    </div>

                    {/* <div className=" col-lg-3 col-xs-3 col-md-3 col-sm-3 bet_status_box bet-status-text-div">
                        <div
                            className="bet-status-text-p event commentry-box-color font-sans-serif font-size-40"
                            id="event_table_383067"
                        >

                        </div>
                    </div> */}
                    {/* <div
                        id="refresh2"
                        onClick={() => window.location.reload()}
                        className=" col-lg-1 col-xs-2 col-md-1 col-sm-2 refresh-btn  reload-class refresh-btn-color"
                    >
                        <FontAwesomeIcon icon={faRotate} className="refresh_img" />
                    </div> */}
                </div>


            </div>

        </div>
    )
}

export default ScoreWidgets2