import React, { useContext, useEffect, useRef, useState } from "react";
import { Form, Spinner, Button } from "react-bootstrap";

import { LoginContext } from "../../context/navContext";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { AddonField } from "../AddonField";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "../../_actions";

export function Login(prpos) {
  const { formClass } = prpos;
  const [formValue, setInputValue] = useState({
    username: "",
    password: "",
  });
  const [formError, setFormError] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const { setLoginModal } = useContext(LoginContext);
  const dispatch = useDispatch();
  const validator = useRef(new SimpleReactValidator());
  const [, setForceUpdate] = useState(false);

  const submitLogin = async (act) => {
    const demoPayload = {
      username: "PL8239",
      password: "12345",
    };
    if (validator.current.allValid() || act === "demo") {
      setLoading(true);
      dispatch(
        loginUser(act === "demo" ? demoPayload : formValue, (result) => {
          if (result) {
            toast.success("Login successfully");
            setLoginModal(false);
          } else {
            toast.error("Invalid username or password.");
          }
          setLoading(false);
        })
      );
    } else {
      validator.current.showMessages();
      setForceUpdate((prevState) => !prevState);
    }
  };

  const onInputChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    setInputValue(stateObj);
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setFormError({});
    }, [5000]);
    return () => {
      clearTimeout(timeOut);
    };
  }, [formError]);

  return (
    <Form
      className={
        formClass ? formClass : "d-flex ms-auto mb-2 mb-lg-0 anvLogin_bx"
      }
    >
      <>
        <AddonField
          preAddon={<FaUserAlt className="loginIcon_design" />}
          addonClass="preAdd_iconBox"
          className="login_inputField"
          type="text"
          name="username"
          label="USER NAME"
          labelClass="login_inpLabelcls"
          inputBoxClass="login_input"
          placeholder="USERNAME"
          onChange={({ target: { name, value } }) => onInputChange(name, value)}
          value={formValue.username}
          // autoComplete="off"
          error={validator.current.message(
            "username",
            formValue.username,
            "required"
          )}
        />
        <AddonField
          preAddon={<FaKey className="loginIcon_design" />}
          addonClass="preAdd_iconBox"
          className="login_inputField"
          type="password"
          name="password"
          label="PASSWORD"
          labelClass="login_inpLabelcls"
          placeholder="PASSWORD"
          inputBoxClass="login_input"
          onChange={({ target: { name, value } }) => onInputChange(name, value)}
          value={formValue.password}
          autoComplete="off"
          error={validator.current.message(
            "password",
            formValue.password,
            "required"
          )}
        />

        {formClass && formError?.resError && (
          <p className="text-danger">{formError?.resError}</p>
        )}
        <div className="d-flex">
          <Button
            disabled={loading}
            className="login_submitBtn justify-content-between"
            variant="secondary"
            onClick={() => submitLogin(false)}
            type="button"
          >
            Login
          </Button>
          <Button
            disabled={loading}
            onClick={() => submitLogin("demo")}
            type="button"
            className="login_submitBtn demo_bogin_btn justify-content-between"
            variant="secondary"
          >
            Demo Login
          </Button>
        </div>
      </>
    </Form>
  );
}
