import React, { useEffect, useState } from "react";
import { asteriskList, getDateAndTime } from "../../utils";
import { Link } from "react-router-dom";
import "./matka.css";
import MatkaMatchList from "./MatkaMatchList";
import {
  getFastMatkaAnnouncement,
  getMatkaAnnouncement,
} from "../../_services";

function MatchList(props) {
  const { fixtureList, sport_id, loading } = props;
  let total_records = fixtureList?.total_records
    ? fixtureList.total_records
    : 0;
  const [matkaAnnounce, setMatkaAnnounce] = useState();

  useEffect(() => {
    sessionStorage.removeItem("show_tv");
    if (+sport_id === 999) {
      // matkaAnnouncement();
    }
    if (+sport_id === 777) {
      // fastMatkaAnnouncement();
    }
  }, [sport_id]);

  const getCount = (type, data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].bookmaker_type == type) {
        return data[i].bet_count;
      }
    }
    return "-";
  };

  const matkaAnnouncement = async () => {
    try {
      const res = await getMatkaAnnouncement();
      if (res?.data?.announcement) {
        setMatkaAnnounce(res?.data?.announcement);
      }
    } catch (error) {}
  };

  const fastMatkaAnnouncement = async () => {
    try {
      const res = await getFastMatkaAnnouncement();
      if (res?.data?.announcement) {
        setMatkaAnnounce(res?.data?.announcement);
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="match-index-row header-top-spacing match-text-flag-box">
        {loading ? (
          <h2>Loading...</h2>
        ) : total_records > 0 ? (
          +sport_id === 999 || +sport_id === 777 ? (
            <MatkaMatchList
              {...props}
              fixtureList={fixtureList}
              matkaAnnounce={matkaAnnounce}
            />
          ) : (
            fixtureList.records.map((match, index) => (
              <div>
                <div className="row matchBox-Row">
                  <div className="col-xs-12 top-time-dtl in-play-right-box-color">
                    {getDateAndTime("DD MMMM hh:mm A", match.open_date)}
                  </div>
                  <Link
                    to={"/detail/" + match.event_id}
                    key={index}
                    className="col-md-10 col-lg-10 col-sm-9 col-xs-12 in-play-row-left list_height"
                    style={{ padding: "0px 0px 0px 0px", zIndex: 1 }}
                  >
                    <div
                      className="col-lg-1 col-sm-2 col-xs-1 flags-container"
                      style={{ padding: "18px 0px 18px 0px" }}
                    ></div>
                    <div
                      className="col-lg-11 col-sm-10 col-xs-8 match-detail-container"
                      style={{ padding: "8px 0px 0px 15px" }}
                    >
                      <div className="match-title">
                        <p className="team-name-font-color">
                          {match.event_name}
                        </p>
                        <div className="match-info">
                          <p className="match-summary-text">
                            {match.event_text}
                          </p>
                          <div className="match_bet">
                            <p>
                              Match Bets :{" "}
                              {match?.bet_count?.length > 0
                                ? getCount(2, match.bet_count)
                                : 0}
                            </p>
                            {+match?.in_play === 1 && (
                              <p className="animate-inplay"> In-Play</p>
                            )}
                            <p>
                              Session Bets :{" "}
                              {match?.bet_count?.length > 0
                                ? getCount(3, match.bet_count)
                                : 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div>
                    <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 in-play-row-right in-play-right-box-color">
                      <div className="match-time-dtl">
                        <p className="match-time-dtl-date">
                          {getDateAndTime("DD", match.open_date)}
                        </p>
                        <p className="match-time-dtl-mounth">
                          {getDateAndTime("MMMM", match.open_date)}
                        </p>
                        <p className="match-time-dtl-mounth">
                          {getDateAndTime("hh:mm A", match.open_date)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )
        ) : (
          <h2>No Match</h2>
        )}
      </div>
    </>
  );
}

export default MatchList;
