export const matchConstants = {
  MATCH_ALL_SPORT_REQUEST: "MATCH_ALL_SPORT_REQUEST",
  MATCH_ALL_SPORT_SUCCESS: "MATCH_ALL_SPORT_SUCCESS",

  GET_UPCOMING_FIXTURE_REQUEST: "GET_UPCOMING_FIXTURE_REQUEST",
  GET_UPCOMING_FIXTURE_SUCCESS: "GET_UPCOMING_FIXTURE_SUCCESS",

  GET_INPLAAY_FIXTURE_REQUEST: "GET_INPLAAY_FIXTURE_REQUEST",
  GET_INPLAAY_FIXTURE_SUCCESS: "GET_INPLAAY_FIXTURE_SUCCESS",

  GET_MATCH_DETAIL_REQUEST: "GET_MATCH_DETAIL_REQUEST",
  GET_MATCH_DETAIL_SUCCESS: "GET_MATCH_DETAIL_SUCCESS",

  GET_CLIENT_ANNU_REQUEST: "GET_CLIENT_ANNU_REQUEST",
  GET_CLIENT_ANNU_SUCCESS: "GET_CLIENT_ANNU_SUCCESS",

  SET_BET_SUCCESS: "SET_BET_SUCCESS",
  CLEAR_BET_SUCCESS: "CLEAR_BET_SUCCESS",

  FAILED_RESPONSE: "FAILED_RESPONSE",
};
