import React from "react";
import Layout from "../../components/common/Layout";
import LedgerDetailsComponent from "../../components/LedgerDetails/LedgerDetailsComponent";

class LedgerDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <LedgerDetailsComponent />
      </Layout>
    );
  }
}

export default LedgerDetails;
