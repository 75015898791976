import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const MarketBet = (props) => {
  const { marketBetData } = props;
  const [toggle, setToggle] = useState(true);

  const getName = (data) => {
    if (data.selection_id && data.market_runners) {
      let run = data.market_runners.find(
        (val) => val.selectionId === data.selection_id
      );
      if (run && run.runnerName) {
        return run.runnerName;
      } else {
        return "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const getDisplayValue = (value, market_type) => {
    try {
      if (value) {
        if (market_type) {
          if (market_type === 1 || market_type === 4 || market_type === 5) {
            let val = value * 100;
            return (parseFloat(val).toFixed(2) - 1 * 100) / 100;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val).toFixed(2) - 1 * 100;
          } else {
            return parseFloat(value).toFixed(2);
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };
  return (
    <>
      <div className="row" style={{ paddingTop: "15px" }}>
        <table className="table table-bordered">
          <thead>
            <tr className="pointer">
              <th
                colSpan="5"
                className="bet-place-tbl-th market_type_row market-name-row-color"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    MATCH BETS {"{ " + marketBetData.length + " }"}
                  </div>
                  <div className="d-flex" onClick={() => setToggle(!toggle)} >
                    {toggle ? (
                      <AiOutlineDown style={{ marginLeft: "10px" }} />
                    ) : (
                      <AiOutlineUp style={{ marginLeft: "10px" }} />
                    )}
                  </div>
                </div>
              </th>
            </tr>
            {toggle && (
              <tr>
                <th colSpan={2} className="bet-place-tbl-th mob-col-width-ch run-pos-rate-amt-run-mod-color">
                  RUNNER
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  RATE
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  AMOUNT
                </th>
                <th colSpan={1} className="bet-place-tbl-th run-pos-rate-amt-run-mod-color">
                  MODE
                </th>
              </tr>
            )}
          </thead>
          {toggle && (
            <tbody>
              {marketBetData.map((market, index) => (
                <tr
                  key={index}
                  id={`runs-${market.betting_type}-value-box-color`}
                >
                  <td
                    colSpan={2}
                    className="fancy_session_th bet-place-tbl-td mob-col-width-ch"
                    style={{ textAlign: "left" }}
                  >
                    {market.team_name}
                  </td>
                  <td
                    colSpan={1}
                    data-modal="modal-1"
                    className="bet-place-tbl-td md-trigger"
                  >
                    {getDisplayValue(
                      market.display_price,
                      market.bookmaker_type
                    )}
                  </td>
                  <td colSpan={1} className="bet-place-tbl-td ">{market.stake}</td>
                  <td colSpan={1} className="bet-place-tbl-td ">
                    {market.betting_type === "1" ? "LAGAI" : "KHAI"}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default MarketBet;
