import { authConstants } from "../../_constants";
import { getStorage } from "../../utils";

let initialState = {
  clientLogin: getStorage("clientLogin", "object", {}),
  isLoader: false,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGIN_SUCCESS:
      localStorage.setItem("clientLogin", JSON.stringify(action.data));
      return {
        ...state,
        clientLogin: action.data,
        isLoader: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...state,
        clientLogin: {},
        isLoader: false,
      };
      //
      case authConstants.CHANGE_PASSWORD_REQUEST:
        return {
          ...state,
          isLoader: true,
        };
      case authConstants.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
           clientLogin: action.data,
          isLoader: false,
        };
        //
    case authConstants.FAILED_RESPONSE:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
