import React from "react";
// import MatchList from "../common/MatchList";
import { connect } from "react-redux";
import { getclientBalance } from "../../_services";
import VideoModal from "./VideoIframe";

class CasinoComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ImageUrl: {},
      token: "",
      show: false,
      casino_games: [],
      token: ''
    };
  }

  componentDidMount() {

    getclientBalance()
    .then((response) => {
      if (response && response.data) {
        if (+response?.data?.hide_casino === 0) {
          const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));
          this.setState({ casino_games: clientLogin.casino_game_list });
          this.setState({ token: clientLogin.token });

        }
      }
    })
    .catch((error) => { });
  }

  render() {

    let { casino_games, token } = this.state;

    return (
      <>
        <div className="container">
          <div className="row cst_row">
            
              {casino_games &&
                casino_games.map((element, i) => {
                  return (
                    <div className="game-box Game_ui_responsive" key={i}>
                      <a href={element.game_url + '/' + token}>
                        <img
                          className="game-img"
                          src={element.image}
                          alt=""
                        />
                        <p className="Game_last_name"> {element.game_name} </p>
                        <div className="game-inner">
                          <div className="icn">
                            <div className="icn-inner">
                              <img src="/images/play-button.svg" alt="" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  )
                })
              }
            </div>
          
        </div>

      </>
    );
  }
}

export default CasinoComponent;

// import React from "react";
// import { connect } from "react-redux";
// import { getClientLedger } from "../../_actions";
// import { toast, ToastContainer } from "react-toastify";
// import queryString from 'query-string';

// class CasinoComponent extends React.Component {

//   constructor(props) {
//     super(props);

//     const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));

//     const params = new Proxy(new URLSearchParams(window.location.search), {
//       get: (searchParams, prop) => searchParams.get(prop),
//     });

//     this.state = {
//       iframe_url: params.game_url
//     };
//   }

//   componentDidMount()
//   {}

//   render() {

//   var { iframe_url } = this.state;

//     return (
//        <div>
//         <iframe src={iframe_url} className="casinosupernowa"></iframe>
//       </div>
//     );
//   }
// }

// export default CasinoComponent;

