import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getTvURL } from "../../_services";
import { useState } from "react";
import { allowedWidgetDomains } from "../../lib/app-config";

export default function LiveTv() {
  const param = useParams();
  const [tvURL, setTvURL] = useState("");
  
  var url =
    window.location != window.parent.location
      ? document.referrer
      : window.location.origin;

  useEffect(() => {
    getLiveTvURL(param);
  }, [param?.event_id]);

  const getLiveTvURL = async (payload) => {
    try {
      const res = await getTvURL(payload);
      if (res?.data?.live_tv_url) {
        setTvURL(res?.data?.live_tv_url);
      } else {
        setTvURL("");
      }
    } catch (error) {
      setTvURL("");
    }
  };

  if (allowedWidgetDomains.includes(url)) {
    return (
      <iframe
        style={{
          height: "99.5vh",
        }}
        src={tvURL}
        width="100%"
      ></iframe>
    );
  } else {
    return (
      <div style={{ height: "100vh", width: "100vw", backgroundColor: "#000" }}>
        404
      </div>
    );
  }
}
