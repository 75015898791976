import React from "react";
import "./index.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
export const AddonField = (props) => {
  const {
    value,
    label,
    labelClass,
    focus = true,
    type,
    boxClass = "",
    error,
    errorClass = "",
    preAddon,
    addonClass,
    inputBoxClass = "",
    postAddon = "",
  } = props;
  const filled = focus && !!value;
  const filedError = error?.props?.children;

  return (
    <>
      <div className={inputBoxClass}>
        {/* <Form.Label className={labelClass}>{label}</Form.Label> */}
        <InputGroup className={boxClass + "mb-3 d-flex"}>
          {preAddon && (
            <div className={addonClass}>
              <span>{preAddon}</span>
            </div>
          )}
          <Form.Control
            type={type}
            {...props}
            aria-label="Example text with button addon"
            aria-describedby="basic-addon1"
          />
          {postAddon && (
            <div className={addonClass}>
              <span>{postAddon}</span>
            </div>
          )}
        </InputGroup>
        {filedError ? (
          <div
            className={errorClass + " input_error_box"}
            style={{ paddingBottom: "28px" }}
          >
            {filedError}
          </div>
        ) : (
          <div style={{ marginTop: "10px" }}></div>
        )}
      </div>
    </>
  );
};
