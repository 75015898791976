import React from "react";
import Layout from "../../components/common/Layout";
import GamesComponent from "../../components/Games/GamesComponent";
import GamesUpcomingComponent from "../../components/Games/GamesUpcomingComponent";

class Games extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        {/* <GamesComponent /> */}
        <GamesUpcomingComponent {...this.props} />
      </Layout>
    );
  }
}

export default Games;
