import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import { StyleReset } from 'atomize';
// import jquery from 'jquery';
// import metismenu from 'metismenu';
// import bootstrap from 'bootstrap';

// import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
// import './../node_modules/font-awesome/css/font-awesome.css'
// import './../node_modules/animate.css/animate.min.css'

// import './index.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./_helpers";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <StyleReset /> */}
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
