import React, { useCallback, useEffect, useMemo, useState } from "react";
import io from "socket.io-client";
import config from "../lib/app-config";

function WebSocketContextComponent(props) {
  const closeAllRoom = useMemo(() => props.closeAllRoom, [props.closeAllRoom]);
  const marketList = props?.marketList ? props?.marketList : [];
  const [connected, setConnected] = useState(false);
  const [roomCreated, setRoomCreated] = useState(false);
  useEffect(() => {
    if (props.socket.connected && marketList.length) {
      if (roomCreated) {
        createRoom(props.socket, marketList);
      } else {
        setTimeout(() => {
          createRoom(props.socket, marketList);
        }, 1000);
      }
    }
  }, [closeAllRoom, marketList, connected]);

  const createConnection = useCallback((socket) => {
    socket.on("connect", () => {
      setConnected(socket.connected);
      console.log("socket connected", socket.connected); // true
    });
    socket.on("updateMarketOdds", handleConnected);
    socket.on("updateFancyOdds", handleFancyOdds);
    socket.on("addUpdateFancy", handleAppUpdateFancyOdds);
    socket.on("updateScore", handleUpdateScore);
    socket.on("settlement_done", handleSettlementDone);
    socket.on("updateCommentary", handleCommentary);
    socket.on("updateEventAnnouncement", handleEventAnnouncement);
    socket.on("updateTVURL", handleTVURL);
    socket.on("updateLockMarketBetting", handleLocalMarketing);
    socket.on("updateUserLockStatus", handleUpdateUserLockStatus);
    socket.on("updateFancyBetLock", handleUpdateFancyBetLock);
    socket.on("updateFancySectionLock", handleUpdateFancySectionLock);
    socket.on("reloadPage", handleReloadPageStatus);
    socket.on("allActiveFancy", handleAllActiveFancy);
    socket.on("marketAnnouncement", handleMarketAnnouncement);
    socket.on("update_balance", handleUpdateUserBalance);
    props.setSocket(socket);
  }, []);

  useEffect(() => {
    let socket = io(config.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });
    createConnection(socket);
    return () => {
      if(marketList?.length > 0 ){
        closeRoom(socket, marketList);
      }
      socket.off("updateMarketOdds", handleConnected);
      socket.off("updateFancyOdds", handleFancyOdds);
      socket.off("addUpdateFancy", handleAppUpdateFancyOdds);
      socket.off("updateScore", handleUpdateScore);
      socket.off("settlement_done", handleSettlementDone);
      socket.off("updateCommentary", handleCommentary);
      socket.off("updateEventAnnouncement", handleEventAnnouncement);
      socket.off("updateTVURL", handleTVURL);
      socket.off("updateLockMarketBetting", handleLocalMarketing);
      socket.off("updateUserLockStatus", handleUpdateUserLockStatus);
      socket.off("updateFancyBetLock", handleUpdateFancyBetLock);
      socket.off("updateFancySectionLock", handleUpdateFancySectionLock);
      socket.off("reloadPage", handleReloadPageStatus);
      socket.off("allActiveFancy", handleAllActiveFancy);
      socket.off("marketAnnouncement", handleMarketAnnouncement);
      socket.off("update_balance", handleUpdateUserBalance);
      socket.off();
      socket.disconnect();
    };
  }, [createConnection]);

  const handleConnected = (payload) => {
    if (payload) {
      props.handleSocketData({ updateMarketOdds: payload });
    }
  };

  const handleFancyOdds = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancyOdds: payload });
    }
  };

  const handleAppUpdateFancyOdds = (payload) => {
    if (payload) {
      props.handleSocketData({ addUpdateFancy: payload });
    }
  };

  const handleUpdateScore = (payload) => {
    if (payload) {
      props.handleSocketData({ updateScore: payload });
    }
  };

  const handleCommentary = (payload) => {
    if (payload) {
      props.handleSocketData({ updateCommentary: payload });
    }
  };

  const handleEventAnnouncement = (payload) => {
    if (payload) {
      props.handleSocketData({ updateEventAnnouncement: payload });
    }
  };

  const handleTVURL = (payload) => {
    if (payload) {
      props.handleSocketData({ updateTVURL: payload });
    }
  };

  const handleLocalMarketing = (payload) => {
    if (payload) {
      props.handleSocketData({ updateLockMarketBetting: payload });
    }
  };

  const handleUpdateFancyBetLock = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancyBetLock: payload });
    }
  };
  const handleUpdateFancySectionLock = (payload) => {
    if (payload) {
      props.handleSocketData({ updateFancySectionLock: payload });
    }
  };

  const createRoom = (socket, market) => {
    for (let i = 0; i < market.length; i++) {

      if (props?.event_id) {
        socket.emit("market_room", props.event_id + "_" + market[i]);
      } else {
        socket.emit("market_room", market[i]);
      }

    }
    setRoomCreated(true);
  };

  const closeRoom = (socket, market) => {
    const list = market?.map( mId => props?.event_id ? props.event_id + "_" + mId : mId)
    console.log("marketListmarketList",list)
    socket.emit("unsubscribe", list);
  };

  const handleUpdateUserLockStatus = (payload) => {
    if (payload) {
      props.handleSocketData({ updateUserLockStatus: payload }); // not used in details page
    }
  };

  const handleReloadPageStatus = (payload) => {
    if (payload) {
      props.handleSocketData({ reloadPage: true });
    }
  };

  const handleSettlementDone = (payload) => {
    if (payload) {
      props.handleSocketData({ settlementDone: payload });
    }
  };

  const handleAllActiveFancy = (payload) => {
    if (payload) {
      props.handleSocketData({ allActiveFancy: payload });
    }
  };

  const handleMarketAnnouncement = (payload) => {
    if (payload) {
      props.handleSocketData({ marketAnnouncement: payload });
    }
  };
  const handleUpdateUserBalance = (payload) => {
    if (payload) {
      props.handleSocketData({ UpdateUserBalance: payload });
    }
  };

  return <></>;
}
export default React.memo(WebSocketContextComponent);
