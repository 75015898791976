import React from "react";
import Header from "./Header";
import MobileNavMenu from "./MobileNavMenu";
import HeadMarquee from "./HeadMarquee";

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-root-contian">
        <MobileNavMenu {...this.props} />
        <Header {...this.props} />
        <HeadMarquee />
        {this.props.children}
      </div>
    );
  }
}

export default Layout;
