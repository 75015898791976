import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { NavContextComponent } from "./context/navContext";

import HomeComp from "./components/HomeComp/HomeComp";
import Login from "./pages/Auth";
import LoginNew from "./pages/AuthNew";
import Detail from "./pages/Detail";
import InPlay from "./pages/Match/InPlay";
import UpComing from "./pages/Match/UpComing";
import PrivateRoute from "./components/authHoc/PrivateRoute";
import Ledger from "./pages/Ledger/Ledger";
import LedgerDetails from "./pages/Ledger/LedgerDetails";
import Dashboard from "./pages/Dashboard";
import Rules from "./pages/Rules/Rules";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Tournament from "./pages/Tournament/Tournament";
import Settings from "./pages/Settings/Settings";
import Games from "./pages/Games/Games";
import Casino from "./pages/Games/Casino";
import VideoModal from '../src/components/MatchDetail/VideoModel'
import Football from "./pages/Match/Football";
import { ToastContainer } from "react-toastify";
import SuperNowa from "./pages/Games/SuperNowa";
import ChartDetail from "./pages/ChartDetail";
import LiveTv from "./pages/LiveTv";
import ScoreWidgets from "./pages/ScoreWidgets/ScoreWidgets";
import ScoreWidgets2 from "./pages/ScoreWidgets/ScoreWidgets2";
import AccountStatement from "./pages/Report/AccountStatement";

class App extends Component {
  render() {
    const isProduction = process.env.NODE_ENV === "production";
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={isProduction}
        isVerboseMode={false}
      >

        <NavContextComponent>
          <Switch>
            <Route exact path="/" component={Football} />
            <Route exact path="/match/inplay/:sport_id" component={Football} />
            <PrivateRoute exact path="/dashboard" component={Football} />
            <PrivateRoute exact path="/detail/:event_id" component={Detail} />
            <PrivateRoute exact path="/chart/:event_id/:cate_id" component={ChartDetail} />
            <PrivateRoute exact path="/match/inplay" component={InPlay} />
            <PrivateRoute exact path="/match/upcoming/:sport_id" component={UpComing} />
            <PrivateRoute exact path="/ledger" component={Ledger} />
            <PrivateRoute exact path="/ledger/:id" component={LedgerDetails} />
            <PrivateRoute exact path="/rules" component={Rules} />
            <PrivateRoute exact path="/account-statement" component={AccountStatement} />
            <PrivateRoute
              exact
              path="/change_password"
              component={ChangePassword}
            />
            <PrivateRoute exact path="/tournaments" component={Tournament} />
            <PrivateRoute exact path="/settings" component={Settings} />
            {/* <PrivateRoute exact path="/games" component={Games} /> */}
            {/* <PrivateRoute exact path="/games" component={Casino} /> */}
            <PrivateRoute exact path="/games" component={SuperNowa} />
            <Route exact path="/livetv/:event_id" component={LiveTv} />
            {/* <PrivateRoute exact path={paths.fnb.dashboard.dietitian_dashboard.list} component={FnbDietitian} /> */}
          </Switch>
        </NavContextComponent>
        <ToastContainer />
      </CacheBuster>
    );
  }
}

export default App;
