import React, { useContext, useEffect, useState } from "react";
import { getMatchDetailRequest, betPlaceAction } from "../../_actions";
import { connect } from "react-redux";
import SessionDetail from "./SessionDetail";
import MarketDetail from "./MarketDetail";
import { ToastContainer } from "react-toastify";
import PlaceMarketBet from "./PlaceMarketBet";
import NumericCode from "./NumericKeyboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import {
  getUserbalance,
  getMarketSessionBet,
  getclientBalance,
  betPlace,
  getSessionBook,
} from "../../_services";
import WebSocketContextComponent from "../../context/webSocketContext";
import MarketBet from "./MarketBet";
import SessionBet from "./SessionBet";
import LiveMatches from "../Match/LiveMatches";
import tv from "../../images/tv.png";
import "./details.css";
import VideoModal from "./VideoModel";
import AlertDialog from "./AlertDialog";

import HeadMarquee from "../common/HeadMarquee";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { commentaryType, differentFancyList } from "../../utils";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import OddSettingModal from "../Settings/OddSettingModal";
import SettingsComponent from "../Settings/SettingsComponent";
import {
  faCircleChevronUp,
  faCircleChevronDown,
} from "@fortawesome/free-solid-svg-icons";
// import "../../pages/ScoreWidgets/ScoreWidgets.css"

const Detail = (props) => {
  const messagesEndRef = React.createRef();
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setAllStateValue] = useState({
    betAmount: 0,
    redirect: "",
    width: window.innerWidth,
    height: window.innerHeight,
    bookmakerType: 0,
    bettingType: 0,
    price: 0,
    displayPrice: 0,
    marketId: "",
    selectionId: "",
    toggleBetForm: false,
    name: "",
    marketBetData: "",
    sessionBetData: "",
    eventId: "",
    shown: false,
    showAlertDialog: false,
    alertType: "",
    alertMessage: "",
    commentaryData: "",
    timer: 0,
    betPlacedClick: false,
  });
  const [matchDetail, setMatchDetails] = useState({});
  const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
  const [allMarketIds, setAllMarketIds] = useState([]);
  const [socket, setSocket] = useState(false);
  const [toggleRoom, setToggleRoom] = useState(false);
  const [oddsStack, setOddsStack] = useState([]);
  const [odd_diff, setOddDiff] = useState("");
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [callBalanceAPI, setCallBalanceAPI] = useState(false);
  const [scorURL, setScorURL] = useState("score1");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadData();
    return () => {
      window.removeEventListener("resize", updateDimensions);
      if (state.socketValue) {
        state.socketValue.closeRoom(getAllMarketIds());
        setMatchDetails({});
      }
    };
  }, []);

  useEffect(() => {
    if (state.shown) {
      scrollToView();
    }
  });

  useEffect(() => {
    if (state.eventId && params.event_id !== state.eventId) {
      setState({ eventId: params.event_id });
      window.location.reload();
    }
  }, [params.event_id]);

  useEffect(() => {
    getclientBalanceData();
  }, [callBalanceAPI]);

  const getclientBalanceData = async () => {
    getclientBalance().then((response) => {
      if (response && response.data) {
        setOddsStack(
          response.data.odds_stack ? response.data.odds_stack.split(",") : []
        );
        setOddDiff(response?.data?.odd_diff ? response.data.odd_diff : "");
      }
    });
  };

  const handleCommentaryStatusModal = (commentary) => {
    let commentaryMessage = "";
    switch ("" + matchDetail?.event?.commentary_button_id) {
      case "1":
        commentaryMessage = commentaryType[1].message;
        break;
      case commentary.commentary_button_id:
        commentaryMessage =
          commentaryType[commentary.commentary_button_id].message;
        break;
    }
    if (commentaryMessage) {
      setState({
        alertType: "error",
        showAlertDialog: true,
        alertMessage: commentaryMessage,
      });
      setTimeout(() => {
        setState({ showAlertDialog: false, alertMessage: "" });
      }, 2000);
    }

    return !!commentaryMessage;
  };

  useEffect(() => {
    if (!state.toggleBetForm) {
      clearValues();
    }
  }, [state.toggleBetForm]);

  const setState = (obj, callback) => {
    setAllStateValue((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  const clearValues = () => {
    setState({
      betAmount: 0,
      bookmakerType: 0,
      bettingType: 0,
      price: 0,
      displayPrice: 0,
      marketId: "",
      selectionId: "",
      name: "",
    });
  };
  const updateDimensions = () => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  };

  const loadData = () => {
    window.addEventListener("resize", updateDimensions);
    fetchMatchDetailData();
    fetchMarketBet();
    fetchSessionBet();
    let shouldShowTV = sessionStorage.getItem("show_tv");
    if (shouldShowTV == 1) {
      setState({ shown: true });
    }
  };

  const fetchMatchDetailData = () => {
    const { event_id } = params;

    dispatch(
      getMatchDetailRequest({ event_id }, (res) => {
        matchDetailParse(res);
        setState({ eventId: event_id });
      })
    );
  };

  const matchDetailParse = (data) => {
    let finalData = [];
    finalData = data.market;
    data.market.map((market, markey) => {
      market.runners.map((run, key) => {
        market.runner_odds.find((odd) => {
          if (run.selectionId === odd.selectionId) {
            run["runner_odd"] = odd;
            finalData[markey].runners[key] = run;
          }
        });
      });
    });
    if (data?.event?.sport_id == 1) {
      let allMarket = finalData;
      for (let i = 0; i < allMarket.length; i++) {
        const marketRunner = allMarket[i].runners;
        const updatedRunner = [];
        let drawRunner = null;
        for (let j = 0; j < marketRunner.length; j++) {
          if (marketRunner[j].runnerName == "The Draw") {
            drawRunner = marketRunner[j];
          } else {
            updatedRunner.push(marketRunner[j]);
          }
        }
        if (drawRunner) {
          updatedRunner.push(drawRunner);
        }
        allMarket[i].runners = updatedRunner;
      }
      finalData = allMarket;
    }
    data.market = finalData;
    const liveData = data.event.score ? JSON.parse(data.event.score) : "";
    const parseData = parseLiveScore(liveData, data); //ig
    data.live_msg = parseData.liveScoreMsg;
    data.live_score1 = parseData.live_score1;
    data.live_score2 = parseData.live_score2;
    handleAllactiveFancy(state.allActiveFancySessions, data);
    setToggleRoom((toggle) => !toggle);
  };

  const getAllMarketIds = ({ market = [], fancy = [] }) => {
    const sessions = fancy;
    const ids = [];
    if (market?.length > 0) {
      for (let i = 0; i < market.length; i++) {
        ids.push(market[i].market_id);
      }
    }
    if (sessions?.length > 0) {
      for (let i = 0; i < sessions.length; i++) {
        ids.push("" + sessions[i].session_id);
      }
    }
    setAllMarketIds(ids);
  };

  const parseLiveScore = (liveData) => {
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };
    if (liveData) {
      const liveScoreMsg = liveData?.data?.result
        ? liveData?.data?.result
        : liveData?.data?.trail_lead
          ? liveData?.data?.trail_lead
          : liveData?.data?.need_run_ball
            // ? liveData?.data?.need_run_ball
            ? liveData.data?.match_type == "Test" ? liveData.data?.trail_lead :
              // liveData.data?.team_b + " Need " + liveData.data?.run_need + " Runs In " + liveData.data?.ball_rem + " Balls To Win"
              (liveData.data?.batting_team == liveData?.data?.team_a_id ? liveData.data?.team_a : liveData.data?.team_b) + " Need " + liveData.data?.run_need + " Runs In " + liveData.data?.ball_rem + " Balls RRR " + liveData.data?.rr_rate//India women needs 120 runs from 72 balls. RRR 10.0
            // : liveData?.data?.toss;
            : null;
      const liveScore1 = `${liveData.data?.team_a} - ${liveData.data?.team_a_scores ? liveData.data?.team_a_scores : ""
        } ( ${liveData.data?.team_a_over ? liveData.data?.team_a_over : 0} Ovs )`;
      const liveScore2 = `${liveData.data?.team_b} - ${liveData.data?.team_b_scores ? liveData.data?.team_b_scores : ""
        }  ( ${liveData.data?.team_b_over ? liveData.data?.team_b_over : 0
        }  Ovs )`;
      finalData = {
        live_score1:
          // liveData?.data?.current_inning % 2 === 0 ? liveScore2 : liveScore1,
          liveData?.data?.batting_team == liveData?.data?.team_a_id ? liveScore1 : liveScore2,
        live_score2:
          // liveData?.data?.current_inning % 2 === 0 ? liveScore1 : liveScore2,
          liveData?.data?.batting_team == liveData?.data?.team_a_id ? liveScore2 : liveScore1,
        sixBall: liveData?.data?.last36ball,
        // //liveData?.data?.last4overs?.length
        //   ? liveData?.data?.last4overs
        //     ?.slice(-2)
        //     .reduce((itemArray, item) => {
        //       itemArray.push(...item?.balls);
        //       return itemArray;
        //     }, [])
        //     .slice(-6)
        //   : ["."],
        players: {
          Batsman: liveData?.data?.batsman,
          bolwer: liveData?.data?.bolwer,
        },
        liveScoreMsg: liveScoreMsg,
        liveCrrScore: liveScoreMsg,
      };
    }

    return finalData;
  };

  const updateSocketResponse = (data) => {
    const { event_id } = params;
    if (data && data.settlementDone) {
      updateSettleMent(data);
      return;
    }
    if (data && data.reloadPage) {
      setToggleRoom((toggle) => !toggle);
      return;
    }
    if (
      data &&
      data?.allActiveFancy &&
      data?.allActiveFancy?.event_id == event_id
    ) {
      handleAllactiveFancy(data.allActiveFancy.session_ids, matchDetail);
      return;
    }
    if (data?.addUpdateFancy?.event_id == event_id) {
      fetchMatchDetailData();
      return;
    }
    setMatchDetails((socketData) => {
      switch (true) {
        case !!data?.updateMarketOdds?.odds &&
          data?.updateMarketOdds?.event_id == event_id:
          const updatedData = data.updateMarketOdds;
          try {
            let finalData = [];
            finalData = socketData.market;
            socketData.market.map((market, markey) => {
              if (updatedData.market_id == market.market_id) {
                market.match_status = updatedData.status;
                market.runner_odds = updatedData.odds;
                market.runners.map((run, key) => {
                  updatedData.odds.find((odd) => {
                    if (run.selectionId == odd.selectionId) {
                      run["runner_odd"] = odd;
                      finalData[markey].runners[key] = run;
                    }
                  });
                });
              }
            });
            return { ...socketData, market: finalData };
          } catch (error) {
            return socketData;
          }
        case !!data?.updateFancyOdds &&
          data?.updateFancyOdds?.event_id == event_id:
          const updatedFancy = data.updateFancyOdds;
          let finalData = [...socketData.fancy];
          socketData.fancy.map((fancyItem, fancyKey) => {
            if (
              fancyItem.session_id == updatedFancy.market_id &&
              fancyItem.event_id == updatedFancy.event_id
            ) {
              fancyItem.lay_price1 = updatedFancy.lay_price1;
              fancyItem.lay_size1 = updatedFancy.lay_size1;
              fancyItem.back_price1 = updatedFancy.back_price1;
              fancyItem.back_size1 = updatedFancy.back_size1;
              fancyItem.game_status = updatedFancy.game_status;
              finalData[fancyKey] = fancyItem;
            }
          });
          return { ...socketData, fancy: finalData };
        case data?.updateScore?.event_id == socketData?.event?.event_id:
          const liveData = data.updateScore.score
            ? JSON.parse(data.updateScore.score)
            : "";
          const parseData = parseLiveScore(
            liveData,
            data?.updateScore?.match_id == data?.updateScore?.event_id
          );
          return {
            ...socketData,
            live_msg: parseData.liveScoreMsg,
            live_score1: parseData.live_score1,
            live_score2: parseData.live_score2,
            SixBall: parseData.sixBall,
            players: parseData.players,
            RunRate: parseData.liveCrrScore,
          };

        case data?.updateCommentary?.event_id == event_id:
          return {
            ...socketData,
            event: {
              ...socketData.event,
              button_label: data.updateCommentary.button_label,
              commentary_button_id: data.updateCommentary.commentary_button_id,
              btn_color: data.updateCommentary.btn_color,
            },
          };
        case data?.updateEventAnnouncement?.event_id == event_id:
          return {
            ...socketData,
            event: {
              ...socketData.event,
              announcement: data.updateEventAnnouncement.announcement,
            },
          };

        case data?.updateTVURL?.event_id == event_id:
          return {
            ...socketData,
            event: {
              ...socketData.event,
              live_tv_url: data.updateTVURL.live_tv_url,
            },
          };
        case data?.updateFancyBetLock?.event_id == event_id:
          let fancy = socketData.fancy;
          for (let i = 0; i < fancy.length; i++) {
            if (fancy[i].session_id == data.updateFancyBetLock.session_id) {
              fancy[i].bet_lock = data.updateFancyBetLock.bet_lock;
            }
          }
          return {
            ...socketData,
            fancy,
          };
        case data?.updateFancySectionLock?.event_id == event_id:
          let fancy1 = socketData.fancy;
          for (let i = 0; i < fancy1.length; i++) {
            if (
              data.updateFancySectionLock.session_type.includes(
                fancy1[i].session_type
              )
            ) {
              fancy1[i].bet_lock = data.updateFancySectionLock.bet_lock;
            }
          }
          return {
            ...socketData,
            fancy1,
          };
        case data?.updateLockMarketBetting?.event_id == event_id:
          let market = socketData.market;
          for (let i = 0; i < market.length; i++) {
            if (market[i].market_id == data.updateLockMarketBetting.market_id) {
              let runner = market[i].runners;
              for (let j = 0; j < runner.length; j++) {
                if (
                  data.updateLockMarketBetting.selection_id === "" ||
                  runner[j].selectionId ==
                  data.updateLockMarketBetting.selection_id
                ) {
                  runner[j].lock_team_betting =
                    data.updateLockMarketBetting.lock_status;
                }
              }
              market[i].runners = runner;
            }
          }
          return {
            ...socketData,
            market,
          };
        case data?.marketAnnouncement?.event_id == event_id:
          let marketI = socketData.market;
          for (let i = 0; i < marketI.length; i++) {
            if (marketI[i].market_id == data.marketAnnouncement.market_id) {
              marketI[i].announcement = data.marketAnnouncement.announcement
            }
          }
          return {
            ...socketData,
            marketI
            // fancy1,
          };
        default:
          return socketData;
      }

    });
  };

  const handleAllactiveFancy = (
    allActiveFancy = allActiveFancySessions,
    matchData
  ) => {
    let fancyData = matchData?.fancy.filter((itemFacny) =>
      allActiveFancy.length
        ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
        : true
    );
    matchData.fancy = fancyData;
    setMatchDetails(matchData);
    getAllMarketIds(matchData);
    setAllActiveFancySessions(allActiveFancy);
  };

  const updateSettleMent = (data) => {
    setMatchDetails((socketData) => {
      if (socketData?.event?.event_id == data?.settlementDone.event_id) {
        if (data.settlementDone.bookmaker_type == 3) {
          let allFancy = socketData.fancy;

          let iIndex = -1;
          for (let i = 0; i < allFancy.length; i++) {
            if (allFancy[i].session_id == data.settlementDone.session_id) {
              iIndex = i;
              break;
            }
          }
          if (iIndex > -1) {
            allFancy.splice(iIndex, 1);
            return { ...socketData, fancy: allFancy };
          }
        }
        if (data.settlementDone.bookmaker_type == 2) {
          let allMarket = socketData.market;
          let iIndex = -1;
          for (let i = 0; i < allMarket.length; i++) {
            if (allMarket[i].market_id == data.settlementDone.market_id) {
              iIndex = i;
              break;
            }
          }
          if (iIndex > -1) {
            allMarket.splice(iIndex, 1);
            return { ...socketData, market: allMarket };
          }
        }
        return { ...socketData };
      } else {
        return { ...socketData };
      }
    });
  };

  const fetchMarketBet = () => {
    const { event_id } = params;
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.filter = 1;
    sendData.event_id = event_id;

    getMarketSessionBet(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.open_bets &&
          response.data.open_bets.length
        ) {
          setState({ marketBetData: response.data.open_bets });
        } else {
          setState({ marketBetData: [] });
        }
      })
      .catch((error) => {
        setState({ marketBetData: [] });
      });
  };

  const fetchSessionBet = () => {
    const { event_id } = params;
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.filter = 3;
    sendData.event_id = event_id;

    getMarketSessionBet(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.open_bets &&
          response.data.open_bets.length
        ) {
          setState({ sessionBetData: response.data.open_bets });
        } else {
          setState({ sessionBetData: [] });
        }
      })
      .catch((error) => {
        setState({ sessionBetData: [] });
      });
  };

  const setbetAmount = (betAmount) => {
    setState({ betAmount });

    //reset timer here
    setState({ timer: 0 });
  };

  const setBookmakerType = (bookmakerType) => {
    setState({ bookmakerType });
  };

  const setBettingType = (bettingType) => {
    setState({ bettingType });
  };

  const setPrice = (price) => {
    setState({ price });
  };

  const setDispalyPrice = (displayPrice) => {
    setState({ displayPrice });
  };

  const setMarketId = (marketId) => {
    setState({ marketId });
  };

  const setSelectionId = (selectionId) => {
    setState({ selectionId });
  };

  const setName = (name) => {
    setState({ name });
  };

  const setTeamName = (teamName) => {
    setState({ teamName });
  };

  const setBatType = (batType) => {
    setState({ batType });
  };

  const setToggleBetForm = (val) => {
    setState({ toggleBetForm: val });

    //start timer here
    if (val) {
      updateTimer();
    } else {
      setState({ timer: 0, betPlacedClick: true });
    }
  };

  const donePressed = () => {
    setState({ timer: 0, betPlacedClick: true });
  };

  const updateTimer = () => {
    if (state.timer == 10) {
      setState({ timer: 0, toggleBetForm: false });
    } else {
      setTimeout(() => {
        let time = state.timer;

        time += 1;
        setState({ timer: time }, () => {
          if (!state.betPlacedClick) {
            updateTimer();
          }
        });
      }, 1000);
    }
  };
  const setSelectedData = (selectedData) => {
    setState({ selectedData });
  };

  const getMarketList = (type, allMarket = []) => {
    let filteredList = [];
    for (let i = 0; i < allMarket.length; i++) {
      if (type == 2) {
        if (
          allMarket[i].market_type == 4 ||
          allMarket[i].market_type == 6 ||
          allMarket[i].market_type == 7
        ) {
          filteredList.push(allMarket[i]);
        }
      } else if (type == 1) {
        if (
          allMarket[i].market_type != 4 &&
          allMarket[i].market_type != 6 &&
          allMarket[i].market_type != 7
        ) {
          filteredList.push(allMarket[i]);
        }
      }
    }
    if (filteredList?.length) {
      const tieMatchInd = filteredList?.findIndex(
        (item) => matchDetail?.event?.sport_id == 4 && item?.market_type == 4
      );
      if (tieMatchInd >= 0) {
        filteredList.push(...filteredList.splice(tieMatchInd, 1));
      }
    }
    return filteredList;
  };

  const placeBetRequest = (callback = {}) => {
    let sendData = {};
    sendData.bookmaker_type = state.bookmakerType;
    sendData.event_id = matchDetail?.event?.event_id
      ? matchDetail?.event.event_id
      : "";
    sendData.market_id = state.marketId;
    sendData.stake = parseFloat(state.betAmount);
    sendData.selection_id = state.selectionId;
    sendData.price = state.price;
    sendData.betting_type = state.bettingType;
    betPlace(sendData)
      .then((resp) => {
        setState({ toggleBetForm: false });

        if (resp && resp.error === "") {
          setState({
            alertType: "success",
            showAlertDialog: true,
            alertMessage: resp.message,
          });
          fetchMarketBet();
          fetchSessionBet();

          getUserbalance()
            .then((res) => {
              let clientLogin = JSON.parse(localStorage.getItem("clientLogin"));
              clientLogin.balance = res.data.balance;
              localStorage.setItem("clientLogin", JSON.stringify(clientLogin));
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              //  callback()
            });

          clearValues();
        } else {
          clearValues();
          setState({
            alertType: "error",
            showAlertDialog: true,
            alertMessage: resp.message,
          });
          setTimeout(() => {
            setState({ showAlertDialog: false });
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        setState({ toggleBetForm: false });
        clearValues();
        setState({
          alertType: "error",
          showAlertDialog: true,
          alertMessage: error?.response?.data?.error
            ? error?.response?.data?.error
            : "Bet Placed Failed",
        });
        setTimeout(() => {
          setState({ showAlertDialog: false });
          window.location.reload();
        }, 1000);
      });
  };
  const handleClick = () => {
    // need to check beofre push
    setState({ shown: true });
    sessionStorage.setItem("show_tv", 1);
  };

  const scrollToBottom = (elementId, clickOffset) => {
    if (window.screen.width > 767) {
      // const totalBat = state.marketBetData.length + state.sessionBetData.length;
      setTimeout(() => {
        const offset1 = document.getElementById(elementId)?.offsetTop;
        const offset = document.getElementById("place-market-bet")?.offsetTop;
        const topValue = offset - (offset1 + 400);
        window.scrollTo({
          top: clickOffset
            ? offset - clickOffset - 190
            : topValue < 500
              ? 500
              : topValue,
          behavior: "smooth",
        });
      }, 300);
    }

    return true;
  };

  const scrollToView = () => {
    const ptDiv = document.getElementById("is_append_video");
    const myDiv = document.getElementById("append_video");
    const oddDiv = document.getElementById("content_matches_wrapper");
    window.addEventListener("scroll", function () {
      const rect = ptDiv.getBoundingClientRect();
      if (rect.top <= 50) {
        myDiv.classList.add("append_video");
        oddDiv.style.marginTop = myDiv.getBoundingClientRect().height + "px";
      } else {
        myDiv.classList.remove("append_video");
        oddDiv.style.removeProperty("margin-top");
      }
    });
  };

  const setStateOfParent = (condition) => {
    setState({ shown: condition });
    sessionStorage.setItem("show_tv", condition ? 1 : 0);
  };

  const resetViewAfterYellowBtn = () => {
    let data = matchDetail;
    data.event.btn_color = "Green";
    data.event.bet_close_time = "";
    data.event.button_label = "Open All";
    data.event.commentary_button_id = 2;
    setState({ matchData: data });
  };

  const showSessionBook = async (id) => {
    const payload = {
      event_id: params?.event_id,
      session_id: id,
    };
    const res = await getSessionBook(payload);
    if (res?.data) {
      setOpenSessionModal(res?.data);
    }
  };

  const {
    betAmount,
    price,
    displayPrice,
    toggleBetForm,
    name,
    marketBetData,
    sessionBetData,
    refreshPrice,
    selectedData,
  } = state;
  const { event } = matchDetail;
  var { betPriceDetail, event_id } = props;
  return (
    <div id="is_append_video">
      <div id="append_video" className={"video_container container-fluid"}>
        {allMarketIds.length ? (
          <WebSocketContextComponent
            marketList={allMarketIds}
            handleSocketData={(e) => {
              updateSocketResponse(e);
            }}
            closeAllRoom={toggleRoom}
            setSocket={setSocket}
            socket={socket}
            event_id={event_id}
          />
        ) : (
          ""
        )}
        <div className="row mob_view">
          <div
            className="col-sm-4"
            style={{
              textAlign: "center",
              width: "100%",
              margin: "0",
              padding: "0",
            }}
          >
            {state.shown ? (
              <VideoModal
                setStateOfParent={() => {
                  setStateOfParent();
                  document
                    .getElementById("content_matches_wrapper")
                    .style.removeProperty("margin-top");
                }}
                url={matchDetail?.event?.live_tv_url}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="container">
        {matchDetail?.event?.score_widget &&
          matchDetail?.event.score_widget != "" &&
          matchDetail?.event?.sport_id == 1 ? (
          <iframe
            style={{
              marginLeft: "-10px",
            }}
            id="frame"
            src={
              matchDetail?.event.score_widget
                ? matchDetail?.event.score_widget
                : ""
            }
            width="102%"
            height="110"
            frameBorder="0"
          ></iframe>
        ) : (
          <div className="row content-top-padding">
            {matchDetail?.event?.sport_id === 4 && (
              <div
                className=" d-flex mt-1 score-button-box"
                style={{ marginTop: "10px", marginBottom: "-10px" }}
              >
                {[
                  { name: "score1", title: "score 1" },
                  { name: "score2", title: "score 2" },
                ].map((item, index) => (
                  <button
                    onClick={() => setScorURL(item.name)}
                    className={
                      "cricket-button " +
                      (scorURL === item.name ? " cricket-btn-color-change" : "")
                    }
                    id="cricket-button"
                    key={index}
                  >
                    {item.title}
                  </button>
                ))}
              </div>
            )}
            {scorURL === "score2" ? (
              <div
                className="content_matches_wrapper"
                id="content_matches_wrapper"
              >
                <div className="col-lg-8 col-xs-9 col-md-8 col-sm-7 bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color anvWidget score_widget ">
                  <iframe
                    id="frame_scoreurl1"
                    src={
                      matchDetail?.event.score_widget
                        ? matchDetail?.event.score_widget
                        : ""
                    }
                    width="100%"
                    height="135"
                    frameBorder="0"
                  ></iframe>
                </div>
              </div>
            ) : (
              <div
                className="content_matches_wrapper"
                id="content_matches_wrapper"
              >
                <div className=" col-lg-8 col-xs-9 col-md-8 col-sm-7 bet_status_box match-score content_in_play_wrapper match-status-table match-score-box-color anvWidget score_widget">
                  <p
                    className="inplay-show-tbl-td match-status-text match-score-box-color"
                    id="score_table_383067"
                  // style={{ fontFamily: "sans-serif !imp  ortant" }}
                  >
                    <span
                      className="match-score-span-1 font-sans-serif"
                    // style={{ fontFamily: "sans-serif !im  portant" }}
                    >

                      {matchDetail?.live_score1
                        ? matchDetail?.live_score1
                        : matchDetail?.event?.league_name}
                    </span>
                    <span className="match-score-span-2 font-sans-serif customePeddingFormobile">
                      {matchDetail?.live_score2
                        ? matchDetail?.live_score2
                        : matchDetail?.event?.event_name}
                    </span>

                    {matchDetail?.RunRate && (
                      <span className="match-score-span-2 font-sans-serif customePeddingFormobile">
                        {matchDetail?.RunRate ? matchDetail?.RunRate : ""}
                      </span>
                    )}
                    {matchDetail?.event?.announcement && (
                      <span className="match-score-span-2 font-sans-serif customePeddingFormobile">
                        {matchDetail?.event?.announcement}
                      </span>
                    )}
                    <span className="match-score-span-2 font-sans-serif customePeddingFormobile">
                      <div className="scoreBall_list anvFlex">
                        <span className="LastBall">CR Over</span>
                        <ul>
                          {matchDetail?.SixBall ? (
                            matchDetail?.SixBall?.map((i, index) => (
                              <li key={index}>
                                <span
                                  style={{
                                    backgroundColor:
                                      i === "6"
                                        ? "#FF69B4"
                                        : i === "4"
                                          ? "#87CEEB"
                                          : i.includes("Wb")
                                            ? "#808080"
                                            : i === "W"
                                              ? "#FF0000"
                                              : i === "Nb"
                                                ? "#008000"
                                                : "",
                                  }}
                                >
                                  {i}
                                </span>
                              </li>
                            ))
                          ) : (
                            <>
                              <li>
                                <span>0</span>
                              </li>
                              <li>
                                <span>0</span>
                              </li>
                              <li>
                                <span>0</span>
                              </li>
                              <li>
                                <span>0</span>
                              </li>
                              <li>
                                <span>0</span>
                              </li>
                              <li>
                                <span>0</span>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </span>
                    <span className="match-score-span-2 font-sans-serif  customePeddingFormobile anvBottom_arrow">
                      {open ? (
                        <FontAwesomeIcon
                          icon={faCircleChevronUp}
                          className="refresh_img"
                          onClick={() => {
                            setOpen(!open);
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCircleChevronDown}
                          className="refresh_img"
                          onClick={() => {
                            setOpen(!open);
                          }}
                        />
                      )}
                    </span>
                    {open && (
                      <span className="match-score-span-2 font-sans-serif customePeddingFormobile">
                        {/* <div className='row'> */}
                        <div
                          show={open}
                          onHide={() => setOpen(false)}
                          className="table_show"
                        >
                          <Table className="score2_table">
                            <thead>
                              <tr className="tableHeadof_score">
                                <th>Batsman</th>
                                <th>R</th>
                                <th>B</th>
                                <th>4s</th>
                                <th>6s</th>
                                <th>SR</th>
                              </tr>
                            </thead>
                            {matchDetail?.players ? (
                              matchDetail?.players?.Batsman?.map((i, index) => (
                                <tbody key={index}>
                                  <tr>
                                    <td>{i?.name ? i?.name : ""}</td>
                                    <td>{i?.run ? i?.run : ""}</td>
                                    <td>{i?.ball ? i?.ball : ""}</td>
                                    <td>{i?.fours ? i?.fours : ""}</td>
                                    <td>{i?.sixes ? i?.sixes : ""}</td>
                                    <td>
                                      {i?.strike_rate === "NaN"
                                        ? ""
                                        : i?.strike_rate}
                                    </td>
                                  </tr>
                                </tbody>
                              ))
                            ) : (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                          </Table>
                          {matchDetail?.players?.bolwer?.name && (
                            <Table className="score2_table">
                              <thead>
                                <tr className="tableHeadof_score">
                                  <th>Boller</th>
                                  <th>O</th>
                                  <th>R</th>
                                  <th>W</th>
                                  <th>E</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    {matchDetail?.players?.bolwer?.name
                                      ? matchDetail?.players?.bolwer?.name
                                      : ""}
                                  </td>
                                  <td>
                                    {matchDetail?.players?.bolwer?.over
                                      ? matchDetail?.players?.bolwer?.over
                                      : ""}
                                  </td>
                                  <td>
                                    {matchDetail?.players?.bolwer?.run
                                      ? matchDetail?.players?.bolwer?.run
                                      : ""}
                                  </td>
                                  <td>
                                    {matchDetail?.players?.bolwer?.wicket
                                      ? matchDetail?.players?.bolwer?.wicket
                                      : 0}
                                  </td>
                                  <td>
                                    {matchDetail?.players?.bolwer?.economy
                                      ? matchDetail?.players?.bolwer?.economy
                                      : ""}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )}

                          {/* {data[0]?.data?.currentPlayersScore?.bowler?.player_name && <div className="bowler_name">
                                        {` Bowler -
                                        ${data[0]?.data?.currentPlayersScore?.bowler?.player_name} `}
                                    </div>} */}
                          {/* <Table className="score2_table">
                                        <thead >
                                            <tr className="tableHeadof_score">
                                                <th>Bowler</th>
                                                <th>R</th>
                                                <th>B</th>
                                                <th>4s</th>
                                                <th>6s</th>
                                                <th>SR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{updateData?.currentPlayersScore?.bowler?.player_name ? updateData?.currentPlayersScore?.bowler?.player_name : ""}</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>

                                        </tbody>
                                    </Table> */}
                        </div>
                        {/* </div> */}
                      </span>
                    )}
                  </p>
                </div>

                <div className=" col-lg-3 col-xs-3 col-md-3 col-sm-3 bet_status_box bet-status-text-div">
                  <div
                    className="bet-status-text-p event commentry-box-color font-sans-serif font-size-40"
                    id="event_table_383067"
                  >
                    {matchDetail?.event?.button_label
                      ? matchDetail?.event.button_label
                      : matchDetail &&
                      matchDetail?.event && (
                        <>
                          {" "}
                          BET
                          <br />
                          OPEN
                        </>
                      )}
                  </div>
                </div>

                <div
                  id="refresh2"
                  onClick={() => window.location.reload()}
                  className=" col-lg-1 col-xs-2 col-md-1 col-sm-2 refresh-btn  reload-class refresh-btn-color"
                >
                  <FontAwesomeIcon icon={faRotate} className="refresh_img" />
                </div>
              </div>
            )}
          </div>
        )}
        {/* {matchDetail?.event?.score_widget &&
          matchDetail?.event.score_widget != "" && (
            <HeadMarquee
              announcement={
                matchDetail?.event && matchDetail?.event.announcement
              }
            />
          )} */}
        {matchDetail?.event?.upline_hide_event === 1 ? (
          <div className="row matchBox-Row">
            <h3
              className="in-play-row-left"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Match hide, please contact to uplines.
            </h3>
          </div>
        ) : (
          <>
            <MarketDetail
              event={matchDetail?.event}
              marketList={getMarketList(1, matchDetail?.market)}
              setBookmakerType={setBookmakerType}
              setBettingType={setBettingType}
              setPrice={setPrice}
              setDispalyPrice={setDispalyPrice}
              setMarketId={setMarketId}
              setSelectionId={setSelectionId}
              setToggleBetForm={setToggleBetForm}
              setName={setName}
              setTeamName={setTeamName}
              setBatType={setBatType}
              scrollToBottom={scrollToBottom}
              // websocketValue={value}
              setSelectedData={setSelectedData}
              resetViewAfterYellowBtn={resetViewAfterYellowBtn}
              reloadChildView={state.reloadChildView}
              // manageSocketValue={updateScoketValue}
              handleCommentaryStatusModal={handleCommentaryStatusModal}
            //   fetchMatchDetailData={fetchMatchDetailData}
            />

            {/* <!-- Session bet board start --> */}
            {matchDetail?.fancy?.length ? (
              differentFancyList(
                matchDetail?.fancy,
                sessionBetData ? sessionBetData : []
              ).map((fancy, index) => (
                <SessionDetail
                  index={index}
                  key={fancy?.session_type}
                  matchDetailEvents={matchDetail?.event}
                  sessionList={fancy}
                  setBookmakerType={setBookmakerType}
                  setBettingType={setBettingType}
                  setPrice={setPrice}
                  setDispalyPrice={setDispalyPrice}
                  setMarketId={setMarketId}
                  setSelectionId={setSelectionId}
                  setToggleBetForm={setToggleBetForm}
                  setName={setName}
                  setTeamName={setTeamName}
                  setBatType={setBatType}
                  scrollToBottom={scrollToBottom}
                  // websocketValue={value}
                  f_exposure={matchDetail?.f_exposure}
                  handleCommentaryStatusModal={handleCommentaryStatusModal}
                  setSelectedData={setSelectedData}
                  showSessionBook={showSessionBook}
                //      fetchMatchDetailData={fetchMatchDetailData}
                />
              ))
            ) : (
              <></>
            )}
            <MarketDetail
              matchDetail={matchDetail}
              event={matchDetail?.event}
              marketList={getMarketList(2, matchDetail?.market)}
              setBookmakerType={setBookmakerType}
              setBettingType={setBettingType}
              setPrice={setPrice}
              setDispalyPrice={setDispalyPrice}
              setMarketId={setMarketId}
              setSelectionId={setSelectionId}
              setToggleBetForm={setToggleBetForm}
              setName={setName}
              setTeamName={setTeamName}
              setBatType={setBatType}
              scrollToBottom={scrollToBottom}
              // websocketValue={value}
              setSelectedData={setSelectedData}
              resetViewAfterYellowBtn={resetViewAfterYellowBtn}
              reloadChildView={state.reloadChildView}
              // manageSocketValue={updateScoketValue}
              handleCommentaryStatusModal={handleCommentaryStatusModal}
            //   fetchMatchDetailData={fetchMatchDetailData}
            />
            {/* <!-- session bet board end --> */}

            {/* Bet Section after place bet start*/}
            {marketBetData && marketBetData.length ? (
              <MarketBet marketBetData={marketBetData} />
            ) : null}

            {sessionBetData && sessionBetData.length ? (
              <SessionBet sessionBetData={sessionBetData} />
            ) : null}

            {/* Bet Section after place bet end*/}
            {toggleBetForm ? (
              state.width <= 992 ? (
                <NumericCode
                  betPriceDetail={betPriceDetail}
                  betAmount={betAmount}
                  setBetAmount={setbetAmount}
                  placeBetRequest={placeBetRequest}
                  setToggleBetForm={setToggleBetForm}
                  onDonePressed={donePressed}
                  price={price}
                  displayPrice={displayPrice}
                  name={name}
                  teamName={state.teamName}
                  batType={state.batType}
                  event={event}
                  selectedData={selectedData}
                  oddsStack={oddsStack}
                  setOpenSettingModal={(e) => setOpenSettingModal(e)}
                  {...props}
                />
              ) : (
                <PlaceMarketBet
                  // setStateOfParent = {setStateOfParent}
                  betPriceDetail={betPriceDetail}
                  betAmount={betAmount}
                  setBetAmount={setbetAmount}
                  placeBetRequest={placeBetRequest}
                  setToggleBetForm={setToggleBetForm}
                  onDonePressed={donePressed}
                  price={price}
                  displayPrice={displayPrice}
                  name={name}
                  teamName={state.teamName}
                  batType={state.batType}
                  event={event}
                  selectedData={selectedData}
                  oddsStack={oddsStack}
                  setOpenSettingModal={(e) => setOpenSettingModal(e)}
                />
              )
            ) : (
              ""
            )}

            {state.shown ? null : (
              <div
                className="dragOuter-toggler jello-horizontal showToggler"
                id="tvDiv"
                onClick={() => {
                  sessionStorage.setItem("show_tv", 1);
                  setState({ shown: true });
                  scrollToView();
                }}
                data-id=" 31697188"
              >
                <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="512.000000pt"
                  height="512.000000pt"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#000000"
                    stroke="none"
                  >
                    <path
                      d="M1535 5096 c-27 -13 -62 -36 -77 -52 -92 -98 -72 -269 40 -347 41
              -28 57 -32 123 -35 l76 -4 234 -234 234 -234 -865 0 c-713 0 -877 -3 -933 -15
              -182 -38 -330 -193 -358 -377 -6 -39 -8 -603 -7 -1513 3 -1311 5 -1454 20
              -1495 53 -148 156 -249 303 -300 53 -18 84 -20 331 -20 l272 0 4 -197 c3 -218
              6 -230 66 -259 46 -22 98 -11 134 28 26 28 28 36 28 109 l0 79 1400 0 1400 0
              0 -79 c0 -73 2 -81 28 -109 36 -39 88 -50 134 -28 60 29 63 41 66 259 l4 197
              272 0 c247 0 278 2 331 20 147 51 250 152 303 300 15 41 17 184 20 1495 1 910
              -1 1474 -7 1513 -21 135 -117 270 -238 335 -105 56 -110 57 -1053 57 l-865 0
              234 234 234 234 76 4 c66 3 82 7 123 35 112 78 132 249 40 347 -38 40 -118 76
              -172 76 -52 0 -134 -35 -167 -71 -38 -42 -65 -115 -62 -170 l3 -50 -320 -320
              -319 -319 -65 0 -65 0 -319 319 -320 320 3 50 c3 55 -24 128 -62 170 -33 36
              -115 71 -167 71 -27 0 -66 -10 -95 -24z m3210 -1159 c51 -23 91 -61 118 -112
              l22 -40 3 -1425 c2 -975 -1 -1441 -8 -1476 -15 -69 -59 -127 -122 -158 l-52
              -26 -2146 0 -2146 0 -52 26 c-63 31 -107 89 -122 158 -7 35 -10 501 -8 1476
              l3 1425 22 41 c37 69 91 107 178 127 11 3 976 4 2145 3 2024 -1 2127 -2 2165
              -19z"
                    ></path>
                  </g>
                </svg>
              </div>
            )}
          </>
        )}

        <div className=""></div>
        {matchDetail?.event?.sport_id && (
          <LiveMatches
            enventId={matchDetail?.event?.event_id}
            marketBetData={marketBetData}
            sessionBetData={sessionBetData}
            sportsId={matchDetail?.event?.sport_id}
          />
        )}

        <div className="row mob_view">
          <div className="col-sm-4" style={{ textAlign: "center" }}></div>
        </div>
      </div>
      <div ref={messagesEndRef} />
      {state.showAlertDialog && (
        <AlertDialog
          showModel={state.showAlertDialog}
          message={state.alertMessage}
          onClose={() => setState({ showAlertDialog: false })}
          alertType={state.alertType}
        />
      )}
      {openSettingModal && (
        <Modal
          className="setting_modal"
          size="md"
          show={openSettingModal}
          onHide={() => setOpenSettingModal(false)}
          backdrop="static"
          keyboard={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "10px",
            }}
          >
            <Button variant="danger" onClick={() => setOpenSettingModal(false)}>
              Close
            </Button>
          </div>
          <OddSettingModal
            data={{ odd_diff, odds_stack: oddsStack }}
            callBalanceAPI={() => setCallBalanceAPI(!callBalanceAPI)}
          />
        </Modal>
      )}
      {openSessionModal && (
        <Modal
          className="setting_modal"
          size="lg"
          show={openSessionModal}
          onHide={() => setOpenSessionModal(false)}
          keyboard={false}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginRight: "10px",
              color: "#000000",
              cursor: "pointer",
            }}
            onClick={() => setOpenSessionModal(false)}
          >
            <h4 style={{ marginLeft: "10px" }}>Session Book </h4>
            <div> X </div>
          </div>
          <div
            style={{
              margin: "10",
            }}
          >
            <table
              className="table"
              style={{ border: "1px solid #EFEFEF", marginBottom: 50 }}
            >
              <thead>
                <tr style={{ background: "#E0E0E0" }}>
                  <th style={{ color: "#E0E0E0" }}>RUNS</th>
                  <th style={{ color: "#E0E0E0" }}>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {openSessionModal &&
                  openSessionModal.map((item, index) => {
                    return (
                      <tr key={item.range + index + item.net_pl}>
                        <td>{item.range}</td>
                        <td
                          style={{
                            borderLeft: "1px solid #EFEFEF",
                            fontWeight: 700,
                          }}
                          className={
                            item?.net_pl > 0 ? "text-success" : "text-danger"
                          }
                        >
                          {item.net_pl}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </Modal>
      )}
    </div>
  );
};

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default connect(mapPropsToState)(Detail);
