import { Modal } from "react-bootstrap";
import { Login } from "./Login";

import React from "react";

export function LoginModal(props) {
  const { isLoginModal, setLoginModal } = props;
  return (
    <Modal
      className="setting_modal loginModal_main"
      show={isLoginModal}
      onHide={() => setLoginModal(false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: "10px",
          marginRight: "10px",
          cursor: "pointer",
          fontWeight: 700,
          fontSize: "20px"
        }}
      >
        <div variant="danger" onClick={() => setLoginModal(false)}>
          X
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <h3>Login</h3>
      </div>
      <Login formClass="login_modal_form" />
    </Modal>
  );
}
