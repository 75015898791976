import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateOddDiff, updateStakeValue } from "../../_services";
const oddDiffList = [0.01, 0.02, 0.03, 0.04, 0.05];

function OddSettingModal({ data, callBalanceAPI }) {
  const [formValue, setFormVlaue] = useState(data);
  const [initialValue, setInitialValue] = useState(data);

  const handleButtonValue = (value, ind) => {
    const oddsStack = [...formValue.odds_stack];
    oddsStack.splice(ind, 1, value);
    setFormVlaue((s) => ({ ...s, odds_stack: oddsStack }));
  };

  useEffect(() => {
    if (!callBalanceAPI) {
      setFormVlaue(data);
      setInitialValue(data);
    }
  }, [data]);

  const updateOddsDifference = async () => {
    const payload = {
      odd_diff: formValue.odd_diff,
    };
    const res = await updateOddDiff(payload);
    if (res) {
      callBalanceAPI && callBalanceAPI();
      toast.success(res.message ? res.message : "success");
    } else {
      toast.error(res.message ? res.message : "error");
    }
  };

  const updateOddsButtonValue = async () => {
    if (
      !formValue.odds_stack.includes("") &&
      formValue.odds_stack.every((stack) => stack >= 100)
    ) {
      const payload = {
        odds_stack: formValue.odds_stack.toString(),
      };
      const res = await updateStakeValue(payload);
      if (res) {
        callBalanceAPI && callBalanceAPI();
        toast.success(res.message ? res.message : "success");
      } else {
        toast.error(res.message ? res.message : "error");
      }
    } else {
      toast.error("button value is required");
    }
  };

  const handleClick = (e) => {
    const vale = e.target.value
    setFormVlaue((s) => ({ ...s, odd_diff: vale }))
  }
  return (
    <div className="card_container">
      <div className="">
        <label>Set odds difference</label>
        <select
          className="form-control"
          name="account"
          value={formValue?.odd_diff}
          onChange={(e) =>
            handleClick(e)
          }
        >
          {oddDiffList.map((val, indexVal) => (
            <option key={indexVal} value={val}>
              {val}
            </option>
          ))}
        </select>
        <div className="d-flxe gap-1">
          <Button
            // disabled={formValue?.odd_diff === initialValue?.odd_diff}
            className="btn action_btn"
            onClick={() => updateOddsDifference()}
          >
            Save
          </Button>
        </div>
      </div>
      <hr />
      <div className=" setting_input_box">
        <label>Set button values</label>
        <div className="setting_button_box">
          {formValue?.odds_stack.map((item, ind) => (
            <div key={ind + "str"}>
              <InputGroup invalid>
                <Form.Control
                  type="number"
                  invalid
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  className={
                    (item && item >= 100 ? "" : "input_error ") + "form-control"
                  }
                  value={item}
                  maxLength="7"
                  onChange={(e) =>
                    e.target.value.length <= 7 &&
                    handleButtonValue(e.target.value, ind)
                  }
                />
              </InputGroup>
            </div>
          ))}
        </div>
        <div className="d-flxe gap-1">
          <Button
            // disabled={
            //   JSON.stringify(formValue?.odds_stack) ===
            //   JSON.stringify(initialValue.odds_stack)
            // }
            className="btn action_btn"
            onClick={() => updateOddsButtonValue()}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OddSettingModal;
