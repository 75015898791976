import React from 'react'
import { Button, InputGroup, Form } from "react-bootstrap";

const Maintenance = () => {
    return (
        <>
            <div className="container-fluid containerLogin">

                <div className='maintenanceStyle'>
                    <img
                        className="img-mainten"
                        src="/images/maintain.png"
                        alt=""
                    />
                </div>



            </div></>
    )
}

export default Maintenance