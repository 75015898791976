import React, { Component } from "react";
import LoginComponent from "../../components/Login";
import { getStorage } from "../../utils";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Maintenance from "../../components/Maintenance";
import { sideMaintenance } from "../../_services";
import { loginMaintenance } from "../../_actions";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: {},
    }
  }
  componentDidMount() {
    this.verifyMaintenance()
  }

  verifyMaintenance() {
    const payload = {}
    this.props.dispatch(
      loginMaintenance(payload, (result) => {
        if (result) {
          this.setState({ apiData: result });
        } else {
          this.setState({ apiData: result });

        }
      })
    );
    // sideMaintenance(payload).then((data) => this.setState({ apiData: data }))
  }

  render() {
    var { user_id } = this.props.auth.clientLogin;
    const { apiData } = this.state;

    if (user_id) {
      return <Redirect to="/rules" />;
    }

    return (
      <>
        {apiData?.data?.maintenance_mode
          ? < Maintenance /> :
          <LoginComponent />}
      </>
    );
  }
}
const maptostateprops = (state) => ({
  ...state,
});
export default connect(maptostateprops)(Login);
