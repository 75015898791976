import React , {Component} from "react";
// import MatchList from "../common/MatchList";

import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { changePassword } from "../../_actions";
import { toast, ToastContainer } from "react-toastify";
import { Redirect } from "react-router-dom";

class ChangePasswordComponent extends Component{
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      formObj: {
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
    this.validator = new SimpleReactValidator();
  }
  
  handleChange = (e) => {
    var { formObj } = this.state;
    formObj[e.target.name] = e.target.value;
    this.setState({ formObj });
  };

  onSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let { redirect, formObj } = this.state;
      this.setState({ isLoader: true });
      if (formObj.new_password !== formObj.confirm_password) {
        toast.error("Invalid new_password and confirm_password.");
        return;
      }
      this.props.dispatch(
        changePassword(formObj, (result) => {
          if (result) {
            this.setState({ redirect: true });
          } else {
            this.setState({ redirect: false });
            toast.error("Invalid username or password.");
          }
        })
      );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };   
  render() {
    let { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/match/inplay" />;
    }
    return (
      <>
        <div className="container chng-pass-container">
          <div
            className="row content-top-padding"
            style={{ paddingTop: "13%" }}
          >
            <div className="col-lg-4 col-md-4 col-sm-6 col-xs-10 col-centered login_stripe change-password-box-color">
              <form
                className="form-horixonatal"
                id="edit_user_4"
                method="post"
                pb-autologin="true"
                autoComplete="off"
              >
                <h1 className="login_heading">Change Password</h1>
                <label id="invalid_old_password" style={{ display: "none" }}>
                  Entered old Password is Wrong,please provide correct password.
                </label>
                <label id="invalid_new_password" style={{ display: "none" }}>
                  Entered New password and confirm password must be same.
                </label>
                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                    <input
                      type="password"
                      id="old_password"
                      name="old_password"
                      className="form-control login_text_field"
                      placeholder="OLD PASSWORD"
                      value = {this.state.formObj.old_password}
                      // onChange = { (e) => setFormObj({old_password : e.target.value})}
                      // onChange={e => setFormObj.old_password(e.target.value) }
                       onChange={(e) => this.handleChange(e)}
                    />
                    <br />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      className="form-control login_text_field"
                      placeholder="NEW PASSWORD"
                      value = {this.state.formObj.new_password}
                       onChange={(e) => this.handleChange(e)}
                    />
                    <br />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-10 col-xs-10 col-centered">
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      className="form-control login_text_field"
                      placeholder="CONFIRM PASSWORD"
                      value = {this.state.formObj.confirm_password}
                       onChange={(e) => this.handleChange(e)}
                      // onChange={e => setFormObj({ confirm_password: e.target.value })}
                    />
                    <br />
                  </div>
                </div>
                <div className="row">
                <div className="col-lg-12 col-md-12">
                  {/* <button
                    className="btn btn-danger purp_btn change_password_btn"
                    id="create_bets"
                   >
                    Done
                  </button> */}
                   <input
                  className="btn btn-danger purp_btn"
                  name="commit"
                  value="submit"
                  pb-role="submit"
                  type="button"
                  onClick={(event) => this.onSubmit(event)}
                />
                </div>
              </div>
              </form>

              {/* <div className="row">
                <div className="col-lg-12 col-md-12">
                  <button
                    className="btn btn-danger purp_btn change_password_btn"
                    id="create_bets"
                   >
                    Done
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <ToastContainer />
        </div>
      </>
    );
   }
  }


const mapPropsToState = (state) => ({
  ...state,
});
export default connect(mapPropsToState)(ChangePasswordComponent);
