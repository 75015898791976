// import axios from "axios";
import axiosAuth from "../lib/axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
// import { getStorage } from "../utils";

export const getAllSport = () => {
  return axiosAuth
    .post(
      config.api_baseurl + "get_all_sports",
      {},
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getInPlayFixture = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "inplay_fixtures", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUpcomingFixture = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "upcoming_fixtures", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatchDetail = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "match_detail", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getclientAnnouncement = (data) => {
  return axiosAuth
    .post(
      config.api_baseurl + "client_announcement",
      {},
      { headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const betPlace = (data) => {
  return axiosAuth
    .post(config.api_baseurl + "place_bet", data, { headers: config.headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUserbalance = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "balance", data, { headers: config.headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMarketPosition = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "calculate_market_winloss", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getSessionPosition = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "calculate_fancy_winloss", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMarketSessionBet = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "open_bets", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getclientBalance = (data) => {
  return axiosAuth
    .post(
      config.api_baseurl + "balance",
      {}
      ,{ headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const updateOddDiff = (data) => {
  return axiosAuth
    .post(
      config.api_baseurl + "update_odd_diff", data
      ,{ headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const updateStakeValue = (data) => {
  return axiosAuth
    .post(
      config.api_baseurl + "update_stake_values", data
      ,{ headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const supernowaLogin = () => {
  return axiosAuth
    .post(
      config.api_baseurl + "casino_login"
      ,{ headers: config.headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getSessionBook = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "session_book", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const placeMatkaBet = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "matka_place_bet", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const cancelMatkaBet = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "cancel_bet", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUserLimit = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "user_limit", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatkaCategoryResult = (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "get_matka_category_result", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getTvURL= (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "get_tv_url", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getMatkaAnnouncement= (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "matka_announcement", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};
export const getFastMatkaAnnouncement= (data = {}) => {
  return axiosAuth
    .post(config.api_baseurl + "fast_matka_announcement", data, {
      headers: config.headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};