import React, { useEffect, useState } from "react";
import { supernowaLogin } from "../../_services";

import { BarLoader } from "react-spinners";

export default function SuperNowaComponent() {
  const [launchURL, setLaunchUrl] = useState("");
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    getSuperNowaDetails();
  }, []);
  const getSuperNowaDetails = async () => {
    const res = await supernowaLogin();
    if (res?.data?.url) {
      setLaunchUrl(res?.data?.url);
    }
    setLoading(false);
  };
  return (
    <>
      {!isLoading ? (
        launchURL ? (
          <div className="d-flex justify-content-center">
            <iframe
              style={{ width: "100%", height: "calc(100vh - 171px)" }}
              allowFullScreen
              src={launchURL}
              // className="tvifram"
            />
          </div>
        ) : null
      ) : (
        <BarLoader></BarLoader>
      )}
    </>
  );
}
