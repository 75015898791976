import React from "react";
import Layout from "../../components/common/Layout";
import InMatchList from "../../components/Match/InMatchList";

class Football extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <InMatchList {...this.props}/>
      </Layout>
    );
  }
}

export default Football;
