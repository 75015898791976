import React, { useCallback, useContext, useEffect, useState } from "react";
import { asteriskList, getDateAndTime } from "../../utils";
import { Link, useHistory } from "react-router-dom";
import "./matka.css";
import io from "socket.io-client";
import AppConfig from "../../lib/app-config";
import { getInPlayFixtureRequest } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { LoginContext } from "../../context/navContext";
import { auth } from "../../_reducers/auth/auth.reducer";

const MatkaMatchList = (props) => {
  const sportsID = props.sport_id;
  let { fixtureList, matkaAnnounce } = props;
  const [dataList, setDataList] = useState(fixtureList?.records);
  const { setLoginModal } = useContext(LoginContext);
  const { clientLogin } = useSelector((state) => state.auth);
  const [isFetch, setForceFetch] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setDataList(fixtureList?.records);
  }, [fixtureList?.records]);

  useEffect(() => {
    let socket = io(AppConfig.socketurl, {
      reconnectionDelayMax: 100000,
      extraHeaders: {},
    });
    createConnection(socket);
    return () => {
      socket.off("matka_result");
      socket.close();
    };
  }, []);

  useEffect(() => {
    const payload = {
      sport_id: 999,
      event_id: [],
      current_page: 1,
      items_perpage: 100,
    };
    if (isFetch) {
      dispatch(getInPlayFixtureRequest(payload));
      setForceFetch((s) => !s);
    }
  }, [isFetch]);

  const createConnection = useCallback((socket) => {
    socket.on("connect", () => {
      console.log("socket connected", socket.connected); // true
    });
    socket.on("matka_result", handleMatkaResult);
  }, []);

  const handleMatkaResult = (res) => {
    if (res?.event_id) {
      setForceFetch((s) => !s);
      // const listArray = dataList.map((item) =>
      //   +item?.event_id === +res?.event_id || +item?.category_id === +res?.category_id
      //     ? { ...item, matka_game_result: res?.matka_game_result }
      //     : item
      // );
    }
  };
  const getMatkaValue = (result, ind) => {
    const res = result ? result?.split("-")?.join("") : [];
    return res[ind] ? res[ind] : "*";
  };

  return (
    <>
      {matkaAnnounce && (
        <div className="row">
          {/* <marquee className="marquee">
            <p style={{ padding: "10px", margin: "0" }}>{matkaAnnounce}</p>
          </marquee> */}
        </div>
      )}
      {dataList.map((match, index) => (
        <div key={index + match.open_date}>
          <div className="row matchBox-Row">
            <div className="col-xs-12 top-time-dtl in-play-right-box-color">
              {getDateAndTime("DD MMMM hh:mm A", match.open_date)}
            </div>
            <div
              className="col-md-10 col-lg-10 col-sm-9 col-xs-12 in-play-row-left bet-left-row"
              style={{
                padding: "0px",
                backgroundColor: match?.background_color
                  ? match?.background_color
                  : "#ffffff",
                color: "green",
                zIndex: 1,
              }}
            >
              <p
                onClick={() =>
                  clientLogin?.user_id
                    ? history.push("/detail/" + match.event_id)
                    : setLoginModal(true)
                }
                type="button"
                className="chart_link_box team-name-font-color matka_name"
              >
                <b
                  style={{
                    color: match?.title_color ? match?.title_color : "#49418c",
                  }}
                >
                  {match.event_name.toUpperCase()}
                </b>
              </p>
              <div
                className="stick_box"
                onClick={() =>
                  clientLogin?.user_id
                    ? history.push("/detail/" + match.event_id)
                    : setLoginModal(true)
                }
              >
                {asteriskList
                  .slice(0, +sportsID === 777 ? 4 : asteriskList.length)
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 5 && <span> - </span>}
                      {index === 3 || index === 4 ? (
                        <sup>
                          {getMatkaValue(match?.matka_game_result, index)}
                        </sup>
                      ) : (
                        getMatkaValue(match?.matka_game_result, index)
                      )}
                      {index === 2 && <span> - </span>}
                    </React.Fragment>
                  ))}
              </div>
              <p
                onClick={() =>
                  clientLogin?.user_id
                    ? history.push(
                        "/chart/" +
                          match?.event_name.replace(/Open|Close/g, "") +
                          "/" +
                          match.category_id
                      )
                    : setLoginModal(true)
                }
                className="chart_link_box"
              >
                <b
                  style={{
                    color: match?.title_color ? match?.title_color : "#49418c",
                  }}
                >
                  {match?.event_name.replace(/Open|Close/g, "")} चार्ट
                </b>
              </p>
            </div>

            <div>
              <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 in-play-row-right in-play-right-box-color">
                <div className="match-time-dtl">
                  <p className="match-time-dtl-date">
                    {getDateAndTime("DD", match.open_date)}
                  </p>
                  <p className="match-time-dtl-mounth">
                    {getDateAndTime("MMMM", match.open_date)}
                  </p>
                  <p className="match-time-dtl-mounth">
                    {getDateAndTime("hh:mm A", match.open_date)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default MatkaMatchList;
