import React from "react";
import { connect } from "react-redux";
import OddSettingModal from "./OddSettingModal";
import { getclientBalance } from "../../_services";
class SettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      odds_stack: [],
      odd_diff: 0,
    };
  }

  componentDidMount() {
    this.getclientBalanceData();
  }
  getclientBalanceData = async () => {
    getclientBalance()
      .then((response) => {
        if (response && response.data) {
          const data = {
            odds_stack: response.data.odds_stack
              ? response.data.odds_stack.split(",")
              : [],
            odd_diff: response.data.odd_diff,
          };
          this.setState({ ...data });
        }
      })
  };

  render() {
    return (
      <>
        <div className="container dashboard_content setting_modal_container">
          <OddSettingModal data={this.state} />
        </div>
      </>
    );
  }
}

const mapPropsToState = (state) => ({
  inFixtureList: state.match.inFixtureList,
});
export default connect(mapPropsToState)(SettingsComponent);
