import React, { useEffect, useState } from "react";
import {
  getInPlayFixtureRequest,
  getAllSportRequest,
  getUpcomingFixtureRequest,
} from "../../_actions";
import { connect } from "react-redux";
import { svgImage } from "./svgConstant";
import { withRouter, useParams } from "react-router-dom";

const SportNavBar = (props) => {
  const params = useParams();
  const [state, setAllStateValue] = useState({
    formData: {
      sport_id: params?.sport_id ? +params?.sport_id : 999,
      current_page: 1,
      items_perpage: 100,
    },
    activeSport: params?.sport_id ? +params?.sport_id : 999,
    pathName: "/match/inplay/"
        // : "/match/upcoming/",
  });

  const setState = (obj, callback) => {
    setAllStateValue((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  useEffect(() => {
    setState({ activeSport: params?.sport_id ? +params?.sport_id : 999});
  }, [params?.sport_id]);

  useEffect(() => {
    let { formData, activeSport } = state;
    let { matchType } = props;
    if(props?.sportList?.length === 0){
      props.dispatch(
        getAllSportRequest((res) => {
          if (res) {
            if (activeSport === 1) {
              getMatchList(activeSport);
            } else {
              const sport_id =
                +activeSport == 111 || +activeSport == 898
                  ? 4
                  : activeSport
                    ? activeSport
                    : res[0].sport_id;
              formData.sport_id = sport_id ? sport_id : res[0].sport_id;
              if (+activeSport == 111) {
                formData.cup_event = 1;
              } else {
                delete formData.cup_event;
              }
              if (+activeSport == 898) {
                formData.election_event = 1;
              } else {
                delete formData.election_event;
              }
  
              // setState({ activeSport: +activeSport });
              if (matchType === "inplay")
                props.dispatch(getInPlayFixtureRequest(formData));
              else props.dispatch(getUpcomingFixtureRequest(formData));
            }
          } else {
          }
        })
      );
    }else{
      formData.sport_id = activeSport;

      if (matchType === "inplay")
      props.dispatch(getInPlayFixtureRequest(formData));
    else props.dispatch(getUpcomingFixtureRequest(formData));
    }
  }, [state.activeSport]);

  const getMatchList = (sportID) => {
    if (+sportID === 6) {
      props.history.push("/games");
      return;
    }
    let { formData } = state;
    formData.sport_id = sportID == 111 ? 4 : sportID;

    if (sportID == 111) {
      formData.cup_event = 1;
    } else {
      delete formData.cup_event;
    }
    let { matchType } = props;
    setState({ activeSport: sportID });
    props.history.push(state.pathName + sportID);
    if (matchType === "inplay")
      props.dispatch(getInPlayFixtureRequest(formData));
    else props.dispatch(getUpcomingFixtureRequest(formData));
  };

  let { sportList, inFixtureList, upFixtureList } = props;
  let { activeSport } = state;
  return (
    <>
      <div className="_crkMenu_main">
        <div className="_crkMenu_mainFlow">
          <div className="cricket-football-main-div CFH-Btn-outer">
            {sportList && sportList.length
              ? [
                ...sportList,
                // { sport_id: 111, sports_name: "Cup Rate" },
                // { sport_id: 898, sports_name: "Election" },
              ].map((sport, index) => (
                <button
                  onClick={() => props.history.push(state.pathName + +sport.sport_id)}
                  className={
                    "cricket-button " +
                    ((inFixtureList?.football_match ||
                      upFixtureList?.football_match) &&
                      +sport.sport_id == 1
                      ? "inplay-match "
                      : "") +
                    (+activeSport === +sport.sport_id
                      ? " cricket-btn-color-change"
                      : "")
                  }
                  id="cricket-button"
                  key={index}
                >
                  {+sport.sport_id == 4 && svgImage.cricket}
                  {+sport.sport_id == 1 && svgImage?.football}
                  {+sport.sport_id == 999 && svgImage?.matka}
                  {+sport.sport_id == 6 && svgImage?.casino}
                  {+sport.sport_id == 333 && svgImage?.lottery}
                  {+sport.sport_id == 111 && svgImage?.cupRate}
                  {+sport.sport_id == 777 && svgImage?.matka}
                  {+sport.sport_id == 898 && svgImage?.election}
                  <span>
                    {sport.sports_name == "Soccer"
                      ? "FOOTBALL"
                      : sport.sports_name}
                  </span>
                  <div className="active"></div>
                </button>
              ))
              : null}
          </div>
        </div>
      </div>
    </>
  );
};
const mapPropsToState = (state) => ({
  sportList: state.match.sportList,
  inFixtureList: state.match.inFixtureList,
});
export default connect(mapPropsToState)(withRouter(SportNavBar));
