import { useSelector } from 'react-redux'

import React, { useState } from 'react';
import closeicon from "../../images/close.png";

const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));

const url = ''; //(clientLogin.casino_games.map( (el) => el.game_url) + "/" + clientLogin?.token)


const VideoModal = (props) => {

    // const counter = useSelector((state) => state.auth.clientLogin)

    // const gameUrl = (counter?.casino_games[0]?.game_url/counter?.token)

    // const [logintoken, setLoginToken] = useState({})

    // setLoginToken(counter?.token);

  

    // const [gamesUrl, setGamesUrl] = useState({})

    // const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));

    // // setGamesUrl(clientLogin);


    const handleClick = () => {
        props.setStateOfParent(false);
    }

    return (
        <div style={{ position: "fixed", top: "15%", left: "0", }}>
            <div className="container-fluid">
                <div className="row">
                    <div style={{ position: "relative" }} className="col-xs-12 col-md-12 col-lg-12 col-sm-12">
                        {
                            props.setStateOfParent ?
                                <div >
                                    <iframe
                                        title={"My Ifram"}
                                        allowFullScreen
                                        frameBorder="0"
                                        height="250px"
                                        src={url}
                                        width="310px"
                                    />
                                </div> : (null)
                        }
                        <div className='closeicon' onClick={handleClick}>
                            <img src={closeicon} alt="close-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VideoModal
