import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ledgerDetails } from "../../_services/ledger.services"

export default function LedgerDetailsComponent() {
  const imageURL = "https://casinoassets.kgf99.com/static"; 
  const { id } = useParams();

  const [ledgerDetailsData, setLedgerDetailsData] = useState({})

  useEffect(() => {

    let sendObj = {}
    sendObj.event_id = id;

    ledgerDetails(sendObj)
      .then((response) => {

        if (response && response?.data) {

          setLedgerDetailsData(response.data);

        }

      }).catch((error) => {

      })
  }, [])

  const getCardImage = (card) => {
    return `${imageURL}/${card}.png`
  }

  const organizeCards = (players, cardResult) => {
    const playerObj = players ? players : null;

    cardResult.forEach((element, index) => {
      const keys = Object.keys(playerObj);
      const minIndex = Math.min(...keys.map((key) => playerObj[key].length));
      const minKey = keys.find((key) => playerObj[key].length === minIndex);
      if(!!element && element?.length > 2) playerObj[minKey].push(element);
    });

    return playerObj;
  };

  const renderCardContainer = (title, cards) => (
    <div className="card-container row1">
      <b>{title}:</b>{" "}
      {cards
        .filter((p) => p !== "1")
        .map((card) => (
          <img key={card} src={getCardImage(card)} alt="card" />
        ))}
    </div>
  );

  const ReusableCardComponent = ({ players, cardResult }) => {
    const playerObj = organizeCards(players, cardResult);

    return (
      <>
        {Object.keys(playerObj).map((suitTitle) =>
          renderCardContainer(suitTitle, playerObj[suitTitle])
        )}
      </>
    );
  };

  const renderResult = (result) => {
    const cardResult = result.cards.split(",");
    const gtype = ledgerDetailsData?.game_code;
    switch (true) {
      case gtype === "aaa" || gtype === "lucky7" || gtype === "lucky7eu":
        return (
          <div className="card-container">
            <img src={getCardImage(result.cards)} alt="card" />
          </div>
        );

      case gtype === "teen20" || gtype === "teen":
        return (
          <ReusableCardComponent
            players={{
              "player A": [],
              "player B": [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "ab20" || gtype === "abj":
        return (
          <ReusableCardComponent
            players={{
              Andar: [],
              Bahar: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "card32" || gtype === "card32eu":
        return (
          <ReusableCardComponent
            players={{
              "player 8": [],
              "player 9": [],
              "player 10": [],
              "player 11": [],
            }}
            cardResult={cardResult}
          />
        );

      case gtype === "dt20" || gtype === "dt202":
        return (
          <ReusableCardComponent
            players={{
              Dragon: [],
              Tiger: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "teen9" || gtype === "dtl20":
        return (
          <ReusableCardComponent
            players={{
              Dragon: [],
              Tiger: [],
              Lion: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "queen":
        return (
          <div className="card-container">
            {cardResult.map((card) => (
              <img key={card} src={getCardImage(card)} alt="card" />
            ))}
          </div>
        );
      case gtype === "baccarat2" || gtype === "baccarat":
        return (
          <ReusableCardComponent
            players={{
              Player: [],
              Banker: [],
            }}
            cardResult={cardResult}
          />
        );
      case gtype === "race20":
        return (
          <ReusableCardComponent
            players={{
              Spade: [],
              Heart: [],
              Diamond: [],
              Club: [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "poker20":
        return (
          <ReusableCardComponent
            players={{
              "Player A": [],
              "Player B": [],
              "Board": [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "poker6":
        return (
          <ReusableCardComponent
            players={{
              "Player 1": [],
              "Player 2": [],
              "Player 3": [],
              "Player 4": [],
              "Player 5": [],
              "Player 6": [],
              "Board": [],
            }}
            cardResult={cardResult}
          />
        );
        case gtype === "teen8":
        return (
          <ReusableCardComponent
            players={{
              "Player 1": [],
              "Player 2": [],
              "Player 3": [],
              "Player 4": [],
              "Player 5": [],
              "Player 6": [],
              "Player 7": [],
              "Player 8": [],
              "Dealer": [],
            }}
            cardResult={cardResult}
          />
        );
      default:
        return (
          <div className="card-container">
            {cardResult.map((card) => (
              <img key={card} src={getCardImage(card)} alt="card" />
            ))}
          </div>
        );
    }
  };

  return (
    <>

      {
        ledgerDetailsData?.mdata?.map((element) => (
          <div className="container ledger-table-container-3">
            <div
              className="row content-top-padding"
              style={{ paddingBottom: "0px" }}
            >
              <table className="table table-bordered header-top-spacing arjun">
                <thead>
                  <tr>
                    <th className="bet-place-tbl-th-1"> {element.market_name} Market Bets </th>
                  </tr>
                </thead>
              </table>

              <table className="table table-bordered header-top-spacing">
                <thead>
                  <tr>
                    <th className="bet-place-tbl-th-1">RATE</th>
                    <th className="bet-place-tbl-th-1">AMOUNT</th>
                    <th className="bet-place-tbl-th-1">MODE</th>
                    <th className="bet-place-tbl-th-1">TEAM</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    element?.bs?.map((values) => (
                      <tr>
                        <td className="ldg-tbl-td match-value-box-color"> {parseFloat(values.display_price).toFixed(2)} </td>
                        <td className="ldg-tbl-td match-value-box-color"> {values.stake} </td>
                        <td className="ldg-tbl-td match-value-box-color"> {values.betting_type == 1 ? "LAGAI" : "KHAI"} </td>
                        <td className="ldg-tbl-td match-value-box-color"> {values.team_name} </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                ledgerDetailsData?.casino_result?.map((result, ind) => (
                  <div className='result-container' key={ind + 'text123'}>
                    <div className='round-id'>
                      {result?.mid && <p>Round Id: {result?.mid}</p>}
                    </div>
                    <div className="card-container">
                      <div className="card-container-box">
                        {renderResult(result)}
                        {result?.desc && <p className='result-text'><b>Result:</b> {result?.desc}</p>}
                      </div>
                    </div>
                  </div>
                )
                )
              }

              <div
                className="bet-place-tbl-td"
                style={{
                  // color: (element.profit_loss > 1) ? "black" : "red",
                  // margin: "0% 0% 4% 0%"
                }}
              >
                {
                  element.profit_loss === 0 ?
                    (
                      <span>{element.profit_loss}</span>
                    )
                    :
                    (null)
                }

                {
                  element.profit_loss > 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}> You Won {element.profit_loss}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  element.profit_loss < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-(element.profit_loss)}/- Coins. </span>
                    )
                    :
                    (null)
                }

              </div>
            </div>
          </div>
        ))
      }

      {
        (ledgerDetailsData?.fbs?.length) > 0 ?
          (
            <div className="container ledger-table-container-3">
              <div
                className="row content-top-padding"
                style={{ paddingBottom: "0px" }}
              >
                <table className="table table-bordered header-top-spacing arjun">
                  <thead>
                    <tr>
                      <th className="bet-place-tbl-th-1"> Match Session Bets </th>
                    </tr>
                  </thead>
                </table>

                <table className="table table-bordered header-top-spacing">
                  <thead>
                    <tr>
                      <th className="bet-place-tbl-th-1">SESSION</th>
                      <th className="bet-place-tbl-th-1">RATE</th>
                      <th className="bet-place-tbl-th-1">AMOUNT</th>
                      <th className="bet-place-tbl-th-1">RUN</th>
                      <th className="bet-place-tbl-th-1">MODE</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      ledgerDetailsData?.fbs?.map((element) => (
                        <tr>
                          <td className="ldg-tbl-td match-value-box-color"> {element.market_name} </td>
                          <td className="ldg-tbl-td match-value-box-color"> {parseFloat(element.display_price).toFixed(2)} </td>
                          <td className="ldg-tbl-td match-value-box-color"> {element.stake} </td>
                          <td className="ldg-tbl-td match-value-box-color"> {element.line} </td>
                          <td className="ldg-tbl-td match-value-box-color"> {element.team_name} </td>
                        </tr>
                      )
                      )
                    }
                  </tbody>
                </table>
                <div
                  className="bet-place-tbl-td"
                  style={{ margin: "0% 0% 4% 0%" }}
                >
                  {
                    ledgerDetailsData?.fancy_pl === 0 ?
                      (
                        <span>{ledgerDetailsData?.fancy_pl}</span>
                      )
                      :
                      (null)
                  }

                  {
                    ledgerDetailsData?.fancy_pl > 0 ?
                      (
                        <span style={{ color: "Green", fontWeight: "600" }}> You Won {ledgerDetailsData?.fancy_pl}/- Coins. </span>
                      )
                      :
                      (null)
                  }

                  {
                    ledgerDetailsData?.fancy_pl < 0 ?
                      (
                        <span style={{ color: "red", fontWeight: "600" }}> You Lost {-(ledgerDetailsData?.fancy_pl)}/- Coins. </span>
                      )
                      :
                      (null)
                  }
                </div>
              </div>
            </div>
          )
          :
          (null)
      }


      {
        (ledgerDetailsData?.detail) ?
          (
            <div className="container">
              <div className="bet-place-tbl-th" style={{ margin: "0px" }}>
                Match Plus Minus
              </div>
              <div
                className="bet-place-tbl-td"
                style={{ margin: "0% 0% 4% 0%" }}
              >
                {
                  ledgerDetailsData?.detail?.profit_loss === 0 ?
                    (
                      <span>{ledgerDetailsData?.detail?.profit_loss}</span>
                    )
                    :
                    (null)
                }

                {
                  ledgerDetailsData?.detail?.profit_loss > 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}> You Won {ledgerDetailsData?.detail?.profit_loss}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  ledgerDetailsData?.detail?.profit_loss < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-(ledgerDetailsData?.detail?.profit_loss)}/- Coins. </span>
                    )
                    :
                    (null)
                }
              </div>

              <div className="bet-place-tbl-th" style={{ margin: "0px" }}>
                My Commission
              </div>
              <div
                className="bet-place-tbl-td"
                style={{ margin: "0% 0% 4% 0%" }}
              >
                {/* {(ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)} */}

                {
                  ((ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) === 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}>{(ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)}</span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) > 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}> You Won {(ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-((ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission))}/- Coins. </span>
                    )
                    :
                    (null)
                }
              </div>

              <div className="bet-place-tbl-th" style={{ margin: "0px" }}>
                Amount After Comm. &amp; Share
              </div>

              <div
                className="bet-place-tbl-td"
                style={{ margin: "0% 0% 4% 0%" }}
              >
                {/* {(ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)} */}

                {
                  ((ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) === 0 ?
                    (
                      <span>{(ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)}</span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) > 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}> You Won {(ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission)) < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-((ledgerDetailsData?.detail?.profit_loss) + (ledgerDetailsData?.detail?.match_commission) + (ledgerDetailsData?.detail?.session_commission))}/- Coins. </span>
                    )
                    :
                    (null)
                }
              </div>

              <div className="bet-place-tbl-th" style={{ margin: "0px" }}>
                Mob. App. Charges
              </div>
              <div
                className="bet-place-tbl-td"
                style={{ margin: "0% 0% 4% 0%" }}
              >
                {/* {ledgerDetailsData?.detail?.other_charges} */}

                {
                  (ledgerDetailsData?.detail?.other_charges) === 0 ?
                    (
                      <span style={{ color: "green", fontWeight: "600" }}>{ledgerDetailsData?.detail?.other_charges}</span>
                    )
                    :
                    (null)
                }

                {
                  (ledgerDetailsData?.detail?.other_charges) > 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {ledgerDetailsData?.detail?.other_charges}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  (ledgerDetailsData?.detail?.other_charges) < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-(ledgerDetailsData?.detail?.other_charges)}/- Coins. </span>
                    )
                    :
                    (null)
                }

              </div>

              <div className="bet-place-tbl-th" style={{ margin: "0px" }}>
                Net Plus Minus
              </div>

              <div
                className="bet-place-tbl-td"
                style={{ margin: "0% 0% 4% 0%" }}
              >
                {/* {(ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)} */}

                {
                  ((ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)) === 0 ?
                    (
                      <span>{(ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)}</span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)) > 0 ?
                    (
                      <span style={{ color: "Green", fontWeight: "600" }}> You Won {(ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)}/- Coins. </span>
                    )
                    :
                    (null)
                }

                {
                  ((ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges)) < 0 ?
                    (
                      <span style={{ color: "red", fontWeight: "600" }}> You Lost {-((ledgerDetailsData?.detail?.profit_loss + ledgerDetailsData?.detail?.match_commission + ledgerDetailsData?.detail?.session_commission) - (ledgerDetailsData?.detail?.other_charges))}/- Coins. </span>
                    )
                    :
                    (null)
                }

              </div>

              <a href="/ledger">
                <div className="bet-place-tbl-th" style={{ margin: "0% 0% 8% 0%" }}>
                  BACK TO LIST
                </div>
              </a>
            </div>
          )
          :
          (null)
      }
    </>
  )
}