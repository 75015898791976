import React, { Component } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../_actions";
import SimpleReactValidator from "simple-react-validator";
import { toast, ToastContainer } from "react-toastify";
import { Button, InputGroup, Form } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";
import {
  loadCaptchaEnginge,
  // LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { getUserIP, getUserLocation } from "../../_services";
import { FloatingField } from "../FloatingField";
import { AddonField } from "../AddonField";
import { BiDownload } from "react-icons/bi";
import { FiLogIn } from "react-icons/fi";

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      formObj: {
        username: "",
        password: "",
        captcha: "",
        location: {},
      },
    };
    this.validator = new SimpleReactValidator();
  }

  handleChange = (e) => {
    var { formObj } = this.state;
    formObj[e.target.name] = e.target.value;
    this.setState({ formObj });
  };

  onSubmit = (e) => {
    if (e === "demo") {
      const payload = {
        username: "PL8239",
        password: "5678",
      };
      this.props.dispatch(
        loginUser(payload, (result) => {
          if (result) {
            this.setState({ redirect: true });
          } else {
            this.setState({ redirect: false });
            toast.error("Invalid username or password.");
          }
        })
      );
    } else {
      e.preventDefault();
      let { formObj } = this.state;
      if (this.validator.allValid()) {
        if (validateCaptcha(formObj?.captcha, false) === true) {
          this.setState({ isLoader: true });
          this.props.dispatch(
            loginUser(formObj, (result) => {
              if (result) {
                this.setState({ redirect: true });
              } else {
                this.setState({ redirect: false });
                toast.error("Invalid username or password.");
              }
            })
          );
        } else {
          toast.error("Captcha Code Doesn't Match");
        }
      } else {
        this.validator.showMessages();
        this.forceUpdate();
      }
    }
  };

  componentDidMount() {
    this.getUserLocationDetails();
    loadCaptchaEnginge(4, "transparent", "#3F368E", "numbers");
  }

  getUserLocationDetails() {
    // getUserIP().then((res) => {
      getUserLocation().then((response) => {
        if (response) {
          let { formObj } = this.state;
          formObj["location"] = response;
          this.setState({ formObj });
        }
      // });
    });
  }

  handleDownload = () => {
    const url = "/Top2020.apk";

    const a = document.createElement("a");
    a.href = url;
    a.download = "Top2020.apk";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    let { redirect, formObj } = this.state;
    if (redirect) {
      return <Redirect to="/match/inplay" />;
    }
    return (
      <>
        <div className="container-fluid containerLogin">
          {/* <img src="images/dhoni.svg"/> */}
          <div className="login_logo">
            {/* <img width={120} src="/images/top2020-logo.png" alt="logo" /> */}
            <img width={120} src="/images/top2020_new_logo.png" alt="logo" />
          </div>

          <div className="screen">
            <div className="screen_content">
              <div className="login_logo">
                {/* <img width={120} src="/images/Top-2020.png" alt="logo" /> */}
                <h4>LOGIN</h4>
              </div>

              <Form
                className="login_form"
                style={{ paddingBottom: 0 }}
                onSubmit={(e) => this.onSubmit(e)}
              >
                <AddonField
                  preAddon={<FaUserAlt className="loginIcon_design" />}
                  addonClass="preAdd_iconBox"
                  className="login_inputField"
                  type="text"
                  name="username"
                  label="USER NAME"
                  labelClass="login_inpLabelcls"
                  placeholder="USERNAME"
                  value={formObj.username}
                  autoComplete="off"
                  onChange={(e) => this.handleChange(e)}
                  error={this.validator.message(
                    "username",
                    formObj.username,
                    "required"
                  )}
                />

                <AddonField
                  preAddon={<FaKey className="loginIcon_design" />}
                  addonClass="preAdd_iconBox"
                  className="login_inputField"
                  type="password"
                  name="password"
                  label="PASSWORD"
                  labelClass="login_inpLabelcls"
                  placeholder="PASSWORD"
                  value={formObj.password}
                  onChange={(e) => this.handleChange(e)}
                  autoComplete="off"
                  error={this.validator.message(
                    "password",
                    formObj.password,
                    "required"
                  )}
                />


                <div>
                  {/* <Form.Label className="captcha_label">
                    Captcha Code
                  </Form.Label> */}
                  <div className=" captcha_input_box" style={{ paddingBottom: '12px' }}>
                    <LoadCanvasTemplateNoReload />
                    <FloatingField
                      className={
                        (formObj?.captcha.length > 0 ? "captcha_value " : "") +
                        "login_inputField captcha_input"
                      }
                      type="text"
                      // label="Enter Captcha code"
                      placeholder="ENTER CAPTCHA CODE"
                      name="captcha"
                      value={formObj.captcha}
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      error={this.validator.message(
                        "captcha",
                        formObj.captcha,
                        "required|numeric"
                      )}
                    />
                  </div>
                </div>


                { }
                {/* {respErr !== "" ? (
                  <p style={{ color: "#ed2732" }} className="mb-0">
                    {respErr}
                  </p>
                ) : (
                  ""
                )} */}
                <Button
                  // disabled={isLoading}
                  className="login_submitBtn justify-content-between"
                  variant="secondary"
                  type="submit"
                >
                  <span></span><span>Login</span> <FiLogIn />
                  {/* <BiLogInCircle style={{ marginLeft: "10px" }} size={24} /> */}
                  {/* {isLoading ? <Loader /> : ""} */}
                </Button>
              </Form>
            </div>
            <div className="login_form">
              {/* <div className="d-flex" style={{ justifyContent: "center" }}>
                <b>
                  <h4 style={{ marginBottom: 0 }}>~ OR ~</h4>
                </b>
              </div> */}
              <Button
                onClick={() => this.onSubmit("demo")}
                type="button"
                className="login_submitBtn demo_bogin_btn justify-content-between"
                variant="secondary"
              >
                <span></span>
                Login with demo ID <FiLogIn />
              </Button>
            </div>
            <div className="login_privacy">
              <p class="mt-1 " onClick={() => this.handleDownload("demo")}><i class="fab fa-android "></i><IoLogoAndroid size={16} /> <span>Download APK</span> </p>
            </div>
            {/* <div className="login_form">
              <div
                onClick={() => this.handleDownload("demo")}
                type="button"
                className="login_submitBtn download_apk"
                variant="secondary"
              >
                Download APK <BiDownload />
              </div>
            </div> */}
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <ToastContainer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(LoginComponent);
