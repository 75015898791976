import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import closeicon from "../../assets/images/close_icon.png"; //"../../images/close.png";
import deleteBtnPng from "../../assets/images/delete-button.png";
import { toast, ToastContainer } from "react-toastify";
import settingBtnPng from "../../assets/images/setting.png";
import { withRouter } from "react-router-dom";
import { appendStackValue, getBetDelayTime, showMinMax } from "../../utils";

class PlaceMarketBet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        amount: 0,
      },
      oneClick: false,
    };
  }

  handleChange = (e) => {
    let { setBetAmount } = this.props;
    setBetAmount(e.target.value);
  };

  onResponseReceived = () => {
    // this.setState({ oneClick : false })
  };

  getOddStackArr = () => {
    const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));
    if (clientLogin && clientLogin.odds_stack) {
      let arr = clientLogin.odds_stack.split(",");
      return arr;
    } else {
      return [];
    }
  };

  checkMinMax = (val) => {
    val = +val
    try {
      let { name, event, selectedData } = this.props;
      if (name !== "" && event !== "" && selectedData !== "") {
        const { min =0 ,max= 0 } = showMinMax(name, selectedData, event)
        if (min && max) {
          if (val >= min && val <= max) {
            return true
          } else {
            toast.error(`Bet amount min value is ${min} and max value is ${max}`);
            return false
          }
        } else {
          return true
        }
      }
    } catch (err) {
      toast.error(`Bet Error`);
      return false
    }
  };

  render() {
    let { setBetAmount, betAmount, placeBetRequest, onDonePressed, selectedData, batType ,name ,event } = this.props;

    return (
      <div id="place-market-bet">
        <form
          className="input-coin even-odd-market-form show_bet_place_btn"
          data-remote="true"
          id="place-amount-field-div"
          onSubmit={(e) => {
            e.stopPropagation()
            e.preventDefault()
            if (!this.state.oneClick && betAmount != 0) {
              if (
                this.checkMinMax(betAmount) === true
              ) {
                onDonePressed();
                this.setState({ oneClick: true });
                setTimeout(() => {
                  placeBetRequest(this.onResponseReceived);
                }, getBetDelayTime(name, selectedData, event, 0));
              }
            }
          }}
        >
          <div style={{ display: "none" }}>
            <input name="utf8" type="hidden" value="✓" />
          </div>
          <div className="market-block" style={{ float: "left" }}>
            <text
              className="align-to-right-on-mobile"
              style={{ fontWeight: "bold" }}
            >
              Market :
            </text>
            <text
              className="align-to-right-on-mobile textEllipsis"
              name="runner_nm"
              id="even-odd-market-nm"
            >
              {this.props.name}
            </text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br className="break-on-mobile" />
            <text
              className="align-to-right-on-mobile"
              style={{ fontWeight: "bold" }}
            >
              RATE :
            </text>
            <text className="align-to-right-on-mobile" id="even_odd_odds">
            {selectedData?.market_type == 1 && batType === "KHAI"
                ? Math.abs(((+this.props.displayPrice )- 1).toFixed(2))
                : selectedData?.market_type == 2 && batType === "KHAI"
                ? Math.abs(((+this.props.displayPrice) - 1) *100).toFixed(2)
                : this.props.displayPrice
              } 
            </text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br className="break-on-mobile" />
            {this.props.teamName && (
              <text
                className="align-to-right-on-mobile"
                style={{ fontWeight: "bold" }}
              >
                {this.props.name == "SESSION" ? "SESSION" : "TEAM"} :
              </text>
            )}
            {this.props.teamName && (
              <text
                className="align-to-right-on-mobile"
                id="even_odd_odds"
                style={{ overflowX: "hidden", textOverflow: "ellipsis" }}
              >
                {this.props.teamName}
              </text>
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <br className="break-on-mobile" />
            {this.props.teamName && (
              <text
                className="align-to-right-on-mobile"
                style={{ fontWeight: "bold" }}
              >
                BAT :
              </text>
            )}
            {this.props.teamName && (
              <text className="align-to-right-on-mobile" id="even_odd_odds">
                {this.props.batType}
              </text>
            )}
          </div>
          <div
            className="place-amount load-button-new-class place-amount-div"
            style={{ position: "relative", paddingRight: 40 }}
          >
            AMOUNT :
            <span className="">
              <input
                className="amt_field_even_odd"
                autoComplete="off"
                onChange={(e) => this.handleChange(e)}
                value={this.props.betAmount == 0 ? "" : this.props.betAmount}
                style={{ textDecoration: "none", lineHeight: "normal" }}
                id="coin_countity_even_odd"
                type="text"
                autoFocus={true}
                name="stack"
                title="Coin"
              />
            </span>
            {this.state.oneClick ? (
              <div className="button alert round amount-btn-color place-bet-done-btn">
                <span
                  style={{ paddingRight: 5, paddingTop: 5, paddingBottom: 5 }}
                >
                  DONE{"  "}
                </span>
                <ClipLoader color={"#666666"} loading={true} size={12} />
              </div>
            ) : (
              <button
                type="submit"
                className="button alert round amount-btn-color place-bet-done-btn"
                onDoubleClick={()=> console.log("click")}
                
              >
                Done
              </button>
            )}
          </div>
        </form>

        <div
          className="place-amount load-button-new-class enter-default-value place-bet-buttons-div"
          style={{ display: "block" }}
        >
          <table className="bet-value-buttons-table">
            <tbody>
              <tr>
                {this.props.oddsStack.map((stack, ind) =>
                <td key={stack + ind}> 
                  <button
                    amt-value={stack}
                    className="default-value-btn"
                    onClick={() => !this.state.oneClick && setBetAmount(stack)}
                  >
                    {appendStackValue(stack)}
                  </button>
                </td>)}
                <td >
                  <button
                    id="setting_button"
                    className=" default-value-btn clear-btn "
                    onClick={() =>
                      this.props.setOpenSettingModal(true)
                    }
                  >
                    <img width="20px" src={settingBtnPng} alt="" />
                  </button>
                </td>
                <td >
                  <button
                    amt-value="clear"
                    className="default-value-btn bet-value-clear-button cancel-btn"
                    onClick={() =>
                      !this.state.oneClick && this.props.setToggleBetForm(false)
                    }
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(PlaceMarketBet);
