import React, { Component } from "react";
import LoginComponent from "../../components/Login";
import { getStorage } from "../../utils";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class LoginNew extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    var { user_id } = this.props.auth.clientLogin;
    if (user_id) {
      return <Redirect to="/rules" />;
    }

    return (
      <>
        <LoginComponent />
      </>
    );
  }
}
const maptostateprops = (state) => ({
  ...state,
});
export default connect(maptostateprops)(LoginNew);
