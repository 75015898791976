import React from "react";
import Layout from "../../components/common/Layout";
import CasinoComponent from "../../components/Games/CasinoComponent";

class Casino extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <CasinoComponent />
      </Layout>
    );
  }
}

export default Casino;
