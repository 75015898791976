import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { connect } from "react-redux";
import deletePng from "../../assets/images/delete.png";
import settingBtnPng from "../../assets/images/setting.png";
import { toast, ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import { appendStackValue, getBetDelayTime, showMinMax } from "../../utils";

class NumericCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oneNumClick: false,
    };
  }
  state = {
    numData: 0,
  };

  addNumValue = (val) => {
    const { numData } = this.state;
    let value = numData ? parseInt(`${numData}${val}`) : parseInt(val);
    this.setState({ numData: value });
    this.props.setBetAmount(value);
  };

  addBtnValue = (val) => {
    this.setState({ numData: val });
    this.props.setBetAmount(val);
  };

  removeNumValue = () => {
    const { numData } = this.state;
    if (numData) {
      const text = numData.toString();
      if (text.length > 1) {
        const editedText = parseInt(text.slice(0, -1));
        this.setState({ numData: editedText });
        this.props.setBetAmount(editedText);
      } else {
        this.setState({ numData: 0 });
        this.props.setBetAmount(0);
      }
    }
  };

  onResponseReceived = () => {};

  checkMinMax = (val) => {
    val = +val
    try {
      let { name, event, selectedData } = this.props;
      if (name !== "" && event !== "" && selectedData !== "") {
        const { min =0 ,max= 0 } = showMinMax(name, selectedData, event)
        if (min && max) {
          if (val >= min && val <= max) {
            return true
          } else {
            toast.error(`Bet amount min value is ${min} and max value is ${max}`);
            return false
          }
        } else {
          return true
        }
      }
    } catch (err) {
      toast.error(`Bet Error`);
      return false
    }
  };


  render() {
    const { setBetAmount, betAmount, placeBetRequest, onDonePressed, selectedData, batType, name , event} = this.props;
    return (
      <>
        <form
          acceptCharset="UTF-8"
          className="numeric-keyboard-market input-coin show_bet_place_btn"
          data-remote="true"
          id="desk-amount-demo"
          method="post"
          style={{}}
        >
          <div style={{ display: "none" }}>
            <input name="utf8" type="hidden" value="✓" />
          </div>
          <div
            className="numeric-keypad-main-div"
            id="numeric-keyboard"
            style={{ display: "block" }}
          >
            <table className="market-rate-table">
              <tbody>
                <tr>
                  <td className="cls-td">
                    <span
                      id="mobile-keyboard-runner-name"
                      style={{ fontWeight: "bold" }}
                    >
                      {this.props.teamName}
                    </span>
                  </td>
                  <td className="cls-td">
                    <span
                      id="mobile-keyboard-odds-type"
                      style={{ fontWeight: "bold" }}
                    >
                      {this.props.batType}
                    </span>
                  </td>
                  <td className="cls-td">
                    RATE :{" "}
                    <span
                      id="mobile-keyboard-odds"
                      style={{ fontWeight: "bold" }}
                    >
                      {selectedData?.market_type == 1 && batType === "KHAI"
                        ? Math.abs(((+this.props.displayPrice )- 1).toFixed(2))
                        : selectedData?.market_type == 2 && batType === "KHAI"
                        ? Math.abs(((+this.props.displayPrice) - 1) *100).toFixed(2)
                        : this.props.displayPrice
                      } 
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="num-pad input-close-table">
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      id="quantity"
                      className="amt_field1 quantity quantity-market"
                      name="stack"
                      data-finder=""
                      placeholder="Enter Coins"
                      value={
                        this.props.betAmount == 0 ? "" : this.props.betAmount
                      }
                      readOnly
                      autoFocus
                    />
                  </td>
                  <td
                    className="close-num-keyboard"
                    id="close-numeric-keyboard"
                    onClick={() =>
                      this.props.setOpenSettingModal(true)
                    }
                    style={{ backgroundImage: `url(${settingBtnPng})`, backgroundColor:'grey'}}
                  ></td>
                </tr>
              </tbody>
            </table>
            <table className="num-pad min-max-stack-table">
              <tbody>
                <tr className="bet-values-row">
                 { this.props.oddsStack.slice(0,5).map((stack, ind) => 
                  <td className="pointer" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>)}
                </tr>
                <tr className="bet-values-row">
                { this.props.oddsStack.slice(5,10).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
              </tbody>
            </table>
            <table className="num-pad numpad-table">
              <tbody>
                <tr>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="1"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(1)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="2"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(2)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="3"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(3)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="4"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(4)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="5"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(5)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="6"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(6)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="7"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(7)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="8"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(8)
                      }
                    />
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="9"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(9)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    className="clear-all cls-td"
                    onClick={() =>
                      !this.state.oneNumClick && this.addBtnValue(0)
                    }
                  >
                    C
                  </td>
                  <td className="cls-td">
                    <input
                      type="button"
                      value="0"
                      onClick={() =>
                        !this.state.oneNumClick && this.addNumValue(0)
                      }
                    />
                  </td>
                  <td
                    className="clear-button cls-td"
                    style={{ backgroundImage: `url(${deletePng})` }}
                    onClick={() =>
                      !this.state.oneNumClick && this.removeNumValue()
                    }
                  >
                    <input
                      type="button"
                      id="back"
                      style={{ fontSize: "15px" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <input id="odds_type" type="hidden" name="odds_type" value="" />
            <input id="runner_id" type="hidden" name="runner_id" value="" />
            <input id="odds_val" type="hidden" name="odds_val" value="" />
            <input id="match_id" type="hidden" name="match_id" value="" />
            <input id="market_id" type="hidden" name="market_id" value="" />
            <input
              id="match_winner_heroic_market_type"
              type="hidden"
              name="heroic_market_type"
              value=""
            />

            <table className="landscape-view-super-values hide-table">
              <tbody>
                <tr>
                {this.props.oddsStack.slice(0,2).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
                <tr>
                {this.props.oddsStack.slice(2,4).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
                <tr>
                {this.props.oddsStack.slice(4,6).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
                <tr>
                {this.props.oddsStack.slice(6,8).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
                <tr>
                {this.props.oddsStack.slice(8,10).map((stack, ind) => 
                  <td className="" key={stack + ind}>
                    <input
                      type="button"
                      value={appendStackValue(stack)}
                      onClick={() =>
                        !this.state.oneNumClick && this.addBtnValue(stack)
                      }
                    />
                  </td>
                )}
                </tr>
              </tbody>
            </table>

            <table className="done-button-table">
              <tbody>
                <tr>
                  <td
                    className="clear-button-td clear-all cls-td"
                    onClick={() =>
                      // this.props.dispatch(setBetRequest(undefined))
                      !this.state.oneNumClick &&
                      this.props.setToggleBetForm(false)
                    }
                  >
                    <input
                      type="button"
                      value="CANCEL"
                      id="cancel-numeric-keyboard"
                    />
                  </td>
                  <td className="numpad-done-button cls-td">
                    {this.state.oneNumClick ? (
                      <div
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: 5,
                          paddingTop: 10,
                        }}
                      >
                        <span
                          style={{
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                          }}
                        >
                          DONE{"  "}
                        </span>
                        <ClipLoader
                          color={"#ffffff"}
                          loading={true}
                          size={20}
                        />
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="button alert round amount-btn-color"
                        onDoubleClick={()=>console.log("click")}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (!this.state.oneNumClick && betAmount != 0) {
                            if ( this.checkMinMax(betAmount) === true) {
                              onDonePressed();
                              this.setState({ oneNumClick: true });
                              setTimeout(() => {
                                placeBetRequest(this.onResponseReceived);
                               }, getBetDelayTime(name, selectedData, event, 0));
                            }
                          }
                        }}
                      >
                        DONE
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
        <div className="overlay-div" style={{ display: "block" }}></div>
      </>
    );
  }
}

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default withRouter(connect(mapPropsToState)(NumericCode));
