import React from "react";
import Layout from "../../components/common/Layout";
import DashboardComponent from "../../components/Dashboard";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <DashboardComponent />
      </Layout>
    );
  }
}

export default Dashboard;
