import React, { useEffect, useState } from "react";
import { asteriskList, getDateAndTime } from "../../utils";
import { Link } from "react-router-dom";
import {
  getInPlayFixtureRequest,
  getUpcomingFixtureRequest,
} from "../../_actions";
import { connect } from "react-redux";
import MatkaMatchList from "./MatkaMatchList";

function LiveMatchList(props) {
  const sportsID = props.sportsId;
  const [formData, setFormData] = useState({
    sport_id: sportsID,
    event_id: [],
    current_page: 1,
    items_perpage: 100,
  });
  let { fixtureList, matchType, detailspageenventId } = props;
  let total_records = fixtureList?.total_records
    ? fixtureList.total_records
    : 0;
  useEffect(() => {
    setFormData({ activeSport: sportsID });
    if (matchType === "inplay") {
      props.dispatch(getInPlayFixtureRequest(formData));
    } else props.dispatch(getUpcomingFixtureRequest(formData));
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("show_tv");
  });

  function getCount(type, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].bookmaker_type == type) {
        return data[i].bet_count;
      }
    }
    return "-";
  }

  const getMatkaValue = (result, ind) => {
    const res = result?.split("-")?.join("");
    return res[ind] ? res[ind] : "*";
  };

  return (
    <>
      <div className="match-index-row header-top-spacing match-text-flag-box">
        {total_records == 0 ? (
          <h2>No Match</h2>
        ) :
          (+sportsID === 999 || +sportsID === 777) ? (
            <MatkaMatchList {...props} fixtureList={fixtureList} />
          ) : (
            fixtureList.records.map((match, index) =>
              detailspageenventId != match.event_id ? (
                <div>
                  <div className="row matchBox-Row">
                    <div className="col-xs-12 top-time-dtl in-play-right-box-color">
                      {getDateAndTime("DD", match.open_date)}&nbsp;&nbsp;{" "}
                      {getDateAndTime("MMMM", match.open_date)}&nbsp;&nbsp;{" "}
                      {getDateAndTime("hh:mm A", match.open_date)}
                    </div>
                    {match?.sports_name === "Matka" ? (
                      <div
                        className="col-md-10 col-lg-10 col-sm-9 col-xs-12 in-play-row-left bet-left-row"
                        style={{
                          padding: "0px",
                          backgroundColor: match?.background_color
                            ? match?.background_color
                            : "#ffffff",
                          color: "green",
                          zIndex: 1,
                        }}
                      >
                        <Link
                          className="event_link"
                          to={"/detail/" + match.event_id}
                          key={index}
                        >
                          <h3
                            className="team-name-font-color"
                            style={{ marginTop: 0 }}
                          >
                            <b
                              style={{
                                color: match?.title_color
                                  ? match?.title_color
                                  : "#49418c",
                              }}
                            >
                              {match.event_name.toUpperCase()}
                            </b>
                          </h3>
                        </Link>
                        <div className="stick_box">
                          {asteriskList.map((item, index) => (
                            <React.Fragment key={index}>
                              {index === 5 && <span> - </span>}
                              {index === 3 || index === 4 ? (
                                <sup>
                                  {getMatkaValue(match?.matka_game_result, index)}
                                </sup>
                              ) : (
                                getMatkaValue(match?.matka_game_result, index)
                              )}
                              {index === 2 && <span> - </span>}
                            </React.Fragment>
                          ))}
                        </div>
                        <Link
                          to={
                            "/chart/" +
                            match?.event_name.replace(/Open|Close/g, "") +
                            "/" +
                            match.category_id
                          }
                          className="chart_link_box"
                        >
                          <b
                            style={{
                              color: match?.title_color
                                ? match?.title_color
                                : "#49418c",
                            }}
                          >
                            {match?.event_name.replace(/Open|Close/g, "")} चार्ट
                          </b>
                        </Link>
                      </div>
                    ) : (
                      <Link
                        to={"/detail/" + match.event_id}
                        key={index}
                        className="col-md-10 col-lg-10 col-sm-9 col-xs-12 in-play-row-left"
                        style={{ padding: "0px 0px 0px 0px", zIndex: 1 }}
                      >
                        <div
                          className="col-lg-1 col-sm-2 col-xs-1 flags-container"
                          style={{ padding: "18px 0px 18px 0px" }}
                        ></div>
                        <div
                          className="col-lg-11 col-sm-10 col-xs-8 match-detail-container"
                          style={{ padding: "15px 0px 0px 15px" }}
                        >
                          <div className="match-title">
                            <p className="team-name-font-color">
                              {match.event_name}
                            </p>
                            <div className="match-info">
                              <p className="match-summary-text">
                                {" "}
                                {match.event_text}{" "}
                              </p>
                              <p>Match Bets : {getCount(2, match.bet_count)}</p>
                              <p>Session Bets : {getCount(3, match.bet_count)}</p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    )}{" "}
                    <Link to={"/detail/" + match.event_id} key={index}>
                      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 in-play-row-right in-play-right-box-color">
                        <div className="match-time-dtl">
                          <p className="match-time-dtl-date">
                            {getDateAndTime("DD", match.open_date)}
                          </p>
                          <p className="match-time-dtl-mounth">
                            {getDateAndTime("MMMM", match.open_date)}
                          </p>
                          <p className="match-time-dtl-mounth">
                            {getDateAndTime("hh:mm A", match.open_date)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              ) : null
            )
          )}
      </div>
    </>
  );
}
const mapPropsToState = (state) => ({
  sportList: state.match.sportList,
});
export default connect(mapPropsToState)(LiveMatchList);
