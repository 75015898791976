import { matchConstants } from "../../_constants";
import { getAllSport, getInPlayFixture, getUpcomingFixture, getMatchDetail, getclientAnnouncement, betPlace } from "../../_services";
import { toast } from 'react-toastify';

export const getAllSportRequest = (callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.MATCH_ALL_SPORT_REQUEST, data: {} });
    getAllSport().then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.MATCH_ALL_SPORT_SUCCESS, data: response.data });
    }).catch((error) => {
      callback(false);
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getInPlayFixtureRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_INPLAAY_FIXTURE_REQUEST, data: {} });
    getInPlayFixture(data).then((response) => {
      dispatch({ type: matchConstants.GET_INPLAAY_FIXTURE_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getUpcomingFixtureRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_UPCOMING_FIXTURE_REQUEST, data: {} });
    getUpcomingFixture(data).then((response) => {
      dispatch({ type: matchConstants.GET_UPCOMING_FIXTURE_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getMatchDetailRequest = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_MATCH_DETAIL_REQUEST, data: {} });
    getMatchDetail(data).then((response) => {
      callback(response.data);
      dispatch({ type: matchConstants.GET_MATCH_DETAIL_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const getclientAnnouncementRequest = () => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CLIENT_ANNU_REQUEST, data: {} });
    getclientAnnouncement().then((response) => {
      dispatch({ type: matchConstants.GET_CLIENT_ANNU_SUCCESS, data: response.data });
    }).catch((error) => {
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};

export const setBetRequest = (data) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.SET_BET_SUCCESS, data });
  };
};

export const betPlaceAction = (data, callback) => {
  return (dispatch) => {
    dispatch({ type: matchConstants.GET_CLIENT_ANNU_REQUEST, data: {} });
    betPlace(data).then((response) => {
      callback({ status: true, message: "Bet Placed Successfully" });
      dispatch({ type: matchConstants.GET_CLIENT_ANNU_SUCCESS, data: response.data });
    }).catch((error) => {
      let msg = '';
      if (error?.response?.data?.error) {
        msg = error?.response?.data?.error;
      } else {
        msg = "Bet Placed Failed";
      }
      // toast.error(msg);

      callback({ status: false, message: msg });
      dispatch({ type: matchConstants.FAILED_RESPONSE, data: error });
    });
  };
};



