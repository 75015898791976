import React from "react";
import "./index.css";
export const FloatingField = (props) => {
  const {
    value,
    label,
    focus = true,
    type,
    boxClass = "",
    error,
    errorClass = "",
  } = props;
  const filled = focus && !!value;
  const filedError = error?.props?.children;

  return (
    <>
      <div className={boxClass + " floating_input_box"}>
        <input type={type} {...props} />
        <label className={filled ? " filled" : ""}>{label}</label>
        {filedError && <div className={errorClass + " input_error_box"}>{filedError}</div>}
      </div>
    </>
  );
};
