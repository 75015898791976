import axios from "axios";
import axiosAuth from "../lib/axios";
import { toast } from "react-toastify";
import config from "../lib/app-config";
import { getStorage } from "../utils";
const { REACT_APP_GOOGLE_KEY } = process.env

export const userLogin = (postData) => {
  return axios
    .post(config.api_baseurl + "login", postData)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const logout = () => {
  const userDetail = getStorage("clientLogin", "object", {});
  config.headers.Authorization = userDetail?.token
    ? "Bearer " + userDetail.token
    : "";
  return axios
    .post(config.api_baseurl + "logout", {}, { headers: config.headers })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const changePasswordLogin = (old_password, new_password, confirm_password) => {
  return axiosAuth
    .post(config.api_baseurl + "change_password", {
      old_password: old_password,
      new_password: new_password,
      confirm_password: confirm_password
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};


export const getUserLocation = (ip) => {
  return axios
    .get(`https://pro.ip-api.com/json?key=${REACT_APP_GOOGLE_KEY}`)
    .then((response) => {
      if (response?.status === 200) {
        return response?.data
      }
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const getUserIP = () => {
  // https://geolocation-db.com/json/
  return axios
    .get('https://api.db-ip.com/v2/free/self')
    .then((response) => {
      if (response?.status === 200) {
        return response?.data

      }
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};

export const sideMaintenance = (postData) => {
  return axios
    .post(config.api_baseurl + "maintenance_mode", postData)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err && err.message === "Network Error") {
        toast.error("Please check your network connection.");
      }
      throw err;
    });
};