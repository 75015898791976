import React from "react";
import SportNavBar from "../common/SportNavBar";
import MatchList from "../common/MatchList";
import { connect } from "react-redux";
import LiveMatchList from "../common/LiveMatchList";

function LiveMatches(props) {
  let { inFixtureList,enventId ,sportsId} = props;
  return (
    <>
      <LiveMatchList fixtureList={inFixtureList} matchType="inplay" detailspageenventId={enventId} sportsId={sportsId} />

    </>

     
  );
}
 const mapPropsToState = (state) => ({
    inFixtureList: state.match.inFixtureList,
 });
export default connect(mapPropsToState)(LiveMatches);
