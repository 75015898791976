import React from "react";
import Layout from "../../components/common/Layout";
import ChangePasswordComponent from "../../components/ChangePassword/ChangePasswordComponent";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <ChangePasswordComponent />
      </Layout>
    );
  }
}

export default ChangePassword;
