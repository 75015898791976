import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";
import MatchDetail from "../../components/MatchDetail";
import MatkaDetail from "../../components/MatchDetail/MatkaDetail";
import { getMatchDetailRequest } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getMatkaCategoryResult } from "../../_services";
import { async } from "q";
import MatkaChartDetail from "../../components/MatchDetail/MatkaChartDetail";
import { getDateAndTime } from "../../utils";
import moment from "moment";
import { IoMdArrowBack } from "react-icons/io";

export default function ChartDetail(props) {
  const { event_id, cate_id } = useParams();
  const history = useHistory()
  const todayDate = moment(new Date()).startOf("isoWeek");
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [startDate, setStartDate] = useState(
    todayDate.clone().subtract(4, "weeks").startOf('isoWeek').format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    todayDate.clone().endOf('isoWeek').format("YYYY-MM-DD")
  );

  useEffect(() => {
    getCategoryDetails();
  }, [cate_id]);

  const getCategoryDetails = async () => {
    const payload = {
      category_id: cate_id,
    };
    if (startDate && endDate) {
      payload["from_date"] = moment(startDate).format("YYYY-MM-DD");
      payload["to_date"] = moment(endDate).format("YYYY-MM-DD");
    }
    try {
      const res = await getMatkaCategoryResult(payload);
      const records = res?.data?.records ? res?.data?.records : [];
      const dateArray = [];
      const currentDate = moment(startDate).startOf("isoWeek");
      const end = moment(endDate).endOf("isoWeek");

      while (currentDate.isSameOrBefore(end)) {
        const monday = currentDate.clone().format("YYYY-MM-DD");
        const sunday = currentDate.clone().add(6, "days").format("YYYY-MM-DD");

        const recordsWithinWeek = records
          .filter((record) => moment(record.open_date, "YYYY-MM-DD").isBetween(monday, sunday, null, "[]")
          )
          .map((record) => {
            const dayName = moment(record.open_date).format("d");
            return { ...record, dayName };
          });

        dateArray.push({
          weekRange: {
            start: currentDate.clone().format("DD-MM-YYYY"),
            end: currentDate.clone().add(6, "days").format("DD-MM-YYYY"),
          },
          records: recordsWithinWeek,
        });

        currentDate.add(7, "days");
      }

      setSelectedDateRange(dateArray);
    } catch (error) { }
  };

  const handleSearch = () => {
    if (startDate && endDate) {
      getCategoryDetails();
    } else {
    }
  };

  const asteriskBox = (records, day) => {
    const matkaResult = records.find(
      (item) => item?.dayName === day
    )?.matka_game_result;
    const numArray = matkaResult
      ? matkaResult?.replace(/-/g, "")?.split("")
      : "";

    return (
      <div className="chrtChar_bx">
        <div className="chrtChar_row">
          <div className="chrtChar_innrFx">
            {numArray[0] ? numArray[0] : "*"}
          </div>
          <div className="chrtChar_innrFx"></div>
          <div className="chrtChar_innrFx">
            {numArray[5] ? numArray[5] : "*"}
          </div>
        </div>

        <div className="chrtChar_row">
          <div className="chrtChar_innrFx">
            {numArray[1] ? numArray[1] : "*"}
          </div>
          <div className="chrtChar_innrFx middle-cht">
            {numArray[3] ? numArray[3] : "*"} {numArray[4] ? numArray[4] : "*"}
          </div>
          <div className="chrtChar_innrFx">
            {numArray[6] ? numArray[6] : "*"}
          </div>
        </div>

        <div className="chrtChar_row">
          <div className="chrtChar_innrFx">
            {numArray[2] ? numArray[2] : "*"}
          </div>
          <div className="chrtChar_innrFx"></div>
          <div className="chrtChar_innrFx">
            {numArray[7] ? numArray[7] : "*"}
          </div>
        </div>
      </div>
    );
  };

  const dayArray = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
console.log("window?.width",window?.innerWidth)
  return (
    <>
      <Layout {...props}>
        <div className="container">
          <div className="row matka_date_picker_cont">
            <div className="box_title">
              <IoMdArrowBack color="#fff" size={'24px'} className="pointer" onClick={() => history.goBack()} />
              <h3>{event_id.toUpperCase()}</h3>
            </div>
            <div className="matka_date_picker_box">
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="date-input"
              />
              <input
                type="date"
                value={endDate}
                min={startDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="date-input"
              />
              <button onClick={handleSearch}>Search</button>
            </div>
          </div>
          <div className="row mob_view anv-margTop-2">
            <div className="chart_table_box">
              <table className="mainMatka_table">
                <thead className="dayHead">
                  <th>
                    <div style={{ width: "70px" }}>DATE</div>
                  </th>
                  {dayArray.map((item) => (
                <th>
                  <div style={{ width: "70px" }}>
                    {window.innerWidth <= 768
                      ? item.slice(0,3)
                      : item}
                  </div>
                </th>
              ))}
                </thead>

                <tbody>
                  {selectedDateRange?.map((item, ind) => (
                    <tr key={ind}>
                      <td className="datesTd">
                        <div style={{ width: "120px", color: "#4b4186" }}>
                          {item?.weekRange?.start} <br />
                          <br />
                          TO
                          <br />
                          <br />
                          {item?.weekRange?.end}
                        </div>
                      </td>

                      <td>{asteriskBox(item?.records, "1")}</td>

                      <td>{asteriskBox(item?.records, "2")}</td>

                      <td>{asteriskBox(item?.records, "3")}</td>

                      <td>{asteriskBox(item?.records, "4")}</td>

                      <td>{asteriskBox(item?.records, "5")}</td>

                      <td>{asteriskBox(item?.records, "6")}</td>

                      <td>{asteriskBox(item?.records, "0")}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
