import React from "react";
import {
  appendStackValue,
  commentaryType,
  convertParseFloat,
} from "../../utils";
import { setBetRequest } from "../../_actions";
import { getSessionPosition } from "../../_services";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

class SessionDetail extends React.Component {
  state = {
    positionData: [],
    width: window.innerWidth,
    height: window.innerHeight,
    detailsId: [],
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  setBet = (bettype, price, session) => {
    let betData = {
      event_id: session.event_id,
      market_id: session.session_id,
      selectionId: session.session_id,
      name: session.runnerName,
      market_name: session.market_name,
      price,
      bookmaker_type: 3,
      bettype,
    };
    this.props.dispatch(setBetRequest(betData));
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  // getFancyPositionData = async () => {
  //   let arrObj = [];
  //   // let v = [];
  //   this.props.sessionList.forEach(async (market) => {
  //     let sendData = {};
  //     sendData.event_id = market.event_id;
  //     sendData.session_id = market.session_id;
  //     // let response = await getSessionPosition(sendData);
  //     getSessionPosition(sendData)
  //       .then((response) => {
  //         if (
  //           response &&
  //           response.data &&
  //           response.data.runners &&
  //           response.data.runners.length
  //         ) {
  //           let runnersData = JSON.parse(JSON.stringify(response.data.runners));
  //           runnersData.forEach((val) => (val.session_id = market.session_id));
  //           arrObj = [...arrObj, ...runnersData];
  //           this.setState({ positionData: arrObj });
  //         } else {
  //           arrObj = [];
  //         }
  //       })
  //       .catch((error) => {});
  //   });
  // };

  fetchFancyPosition = (session_id, selectionId) => {
    let position = this.state.positionData.find(
      (pos) => pos.selectionId === selectionId && pos.session_id === session_id
    );
    if (position && position.winloss) {
      return position.winloss;
    } else {
      return 0;
    }
  };

  toggleDetailsMenu = (id) => {
    const detailsArray = this.state?.detailsId;
    if (detailsArray.indexOf(id) >= 0) {
      detailsArray.splice(detailsArray.indexOf(id), 1);
    } else {
      detailsArray.push(id);
    }
    this.setState({ detailsArray: detailsArray });
  };

  render() {
    let {
      matchDetailEvents,
      sessionList,
      setBookmakerType,
      setBettingType,
      setPrice,
      setDispalyPrice,
      setMarketId,
      setSelectionId,
      setToggleBetForm,
      setName,
      setTeamName,
      setBatType,
      scrollToBottom,
      setSelectedData,
      showSessionBook,
    } = this.props;
    return (
      sessionList?.data &&
      sessionList?.data.filter((item) => !item.bet_lock).length > 0 && (
        <div className="row font-sans-serif" style={{ paddingTop: "0px" }}>
          <table className="table table-bordered session-bets-table font-sans-serif anvSesstion_table">
            <thead className="sessions-thead font-sans-serif">
              <tr className="sessions-title font-sans-serif pointer">
                <th
                  colSpan={this.state.width <= 768 ? "10" : "12"}
                  className="bet-place-tbl-th market_type_row market-name-row-color font-sans-serif ls-2"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {sessionList?.session?.name}
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        display: "flex",
                        alignItems: "center",
                        paddingTop: 3,
                      }}
                    >
                      <span
                        style={{
                          marginLeft: 20,
                        }}
                        className="pull-right font-sans-serif "
                      >
                        {sessionList.f_exposure > 0 ? (
                          <p className="highlight-green">
                            {convertParseFloat(sessionList.f_exposure)}
                          </p>
                        ) : (
                          <p className="highlight-red">
                            {convertParseFloat(sessionList.f_exposure)}
                          </p>
                        )}
                      </span>
                      <div
                        className="d-flex"
                        onClick={() =>
                          this.toggleDetailsMenu(sessionList?.session?.name)
                        }
                      >
                        {!this.state.detailsId.includes(
                          sessionList?.session?.name
                        ) ? (
                          <AiOutlineDown style={{ marginLeft: "10px" }} />
                        ) : (
                          <AiOutlineUp style={{ marginLeft: "10px" }} />
                        )}
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
              {!this.state.detailsId.includes(sessionList?.session?.name) && (
                <tr className="sessions-header font-sans-serif" colSpan={12}>
                  <th
                    align="left"
                    colSpan={5}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif"
                  >
                    <div className="d-flex">
                      {matchDetailEvents?.[
                        "min" + sessionList?.session?.value
                      ] ||
                      matchDetailEvents?.["max" + sessionList?.session?.value]
                        ? `Min:${appendStackValue(
                            "" +
                              matchDetailEvents?.[
                                "min" + sessionList?.session?.value
                              ]
                          )} | Max:${appendStackValue(
                            "" +
                              matchDetailEvents?.[
                                "max" + sessionList?.session?.value
                              ]
                          )}`
                        : null}
                    </div>
                  </th>
                  <th
                    colSpan={2}
                    className="bet-odds-type bet-place-tbl-th not-box-color font-sans-serif"
                  >
                    No
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif"
                    id="session_bet_row"
                  >
                    RATE
                  </th>
                  <th
                    colSpan={2}
                    className="bet-odds-type bet-place-tbl-th yes-box-color font-sans-serif"
                  >
                    Yes
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif"
                    id="session_bet_row"
                  >
                    RATE
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color font-sans-serif"
                  ></th>
                </tr>
              )}
            </thead>
            {!this.state.detailsId.includes(sessionList?.session?.name) && (
              <tbody>
                {sessionList?.data
                  .filter((item) => !item.bet_lock)
                  .map((session, index) => (
                    <tr colSpan={12} key={index} className="session_min_height">
                      <td
                        colSpan={5}
                        data-column="session_title"
                        className="bet-place-tbl-td font-sans-serif overflowtext"
                        style={{
                          textAlign: "left",
                          width: "100%",
                          fontWeight: this.state.width <= 768 ? "none" : "bold",
                        }}
                      >
                        {session.runner_name}
                      </td>
                      {session.game_status && session.game_status != "" ? (
                        <>
                          <td
                            id="session_status"
                            colSpan={this.state.width <= 768 ? 4 : 6}
                            data-column="session_title"
                            className="font-sans-serif SessionTable"
                            style={{
                              width: "100%",
                              right: 0,
                              left: 0,
                              textAlign: "center",
                            }}
                          >
                            {session.game_status}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={2}
                            id={index + session.runner_name}
                            // onClick={() => this.setBet(1, session.back_price1, session)}
                            onClick={(e) => {
                              if (
                                this.props.handleCommentaryStatusModal(
                                  commentaryType[5]
                                )
                              ) {
                                return;
                              }
                              if (session.lay_price1) {
                                setBookmakerType(3);
                                setBettingType(2);
                                setPrice(session.lay_price1);
                                setDispalyPrice(session.lay_price1);
                                setMarketId(session.session_id);
                                setSelectionId(session.session_id);
                                setName("SESSION");
                                setSelectedData(session);
                                setTeamName(session.runner_name);
                                setBatType("NO");
                                setToggleBetForm(true);
                                scrollToBottom(index + session.runner_name); // dont uncoment
                              }
                            }}
                            className="fw-80 font-sans-serif open-keyboard-session not-value-box-color SessionTable bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer close-status not-box-color"
                          >
                            <p className="session_bhav">
                              {" "}
                              {matchDetailEvents?.btn_color &&
                              matchDetailEvents.btn_color == "Red" &&
                              matchDetailEvents.commentary_button_id != 3
                                ? 0
                                : session.lay_price1}
                            </p>
                            <p className="session_rate">{session.lay_size1}</p>
                          </td>
                          <td
                            colSpan={1}
                            className="bet-place-tbl-td bet-val-cent close-status font-sans-serif"
                            id="session_bet_row"
                          >
                            {session.lay_size1}
                          </td>
                          <td
                            id={index + session.runner_name + "YES"}
                            colSpan={2}
                            // onClick={() => this.setBet(2, session.lay_price1, session)}
                            onClick={(e) => {
                              if (
                                this.props.handleCommentaryStatusModal(
                                  commentaryType[5]
                                )
                              ) {
                                return;
                              }
                              if (session.back_price1) {
                                setBookmakerType(3);
                                setBettingType(1);
                                setPrice(session.back_price1);
                                setDispalyPrice(session.back_price1);
                                setMarketId(session.session_id);
                                setSelectionId(session.session_id);
                                setName("SESSION");
                                setSelectedData(session);
                                setTeamName(session.runner_name);
                                setBatType("YES");
                                setToggleBetForm(true);
                                scrollToBottom(
                                  index + session.runner_name + "YES"
                                );
                              }
                            }}
                            className="fw-80 font-sans-serif open-keyboard-session yes-value-box-color SessionTable bet-place-tbl-td first-runner-bet-odds-no-value cursor-pointer close-status lagai-box-color yes-box-color"
                          >
                            <p className="session_bhav">
                              {" "}
                              {matchDetailEvents?.btn_color &&
                              matchDetailEvents.btn_color == "Red" &&
                              matchDetailEvents.commentary_button_id != 3
                                ? 0
                                : session.back_price1}
                            </p>
                            <p className="session_rate">{session.back_size1}</p>
                          </td>
                          <td
                            colSpan={1}
                            className="bet-place-tbl-td bet-val-cent close-status font-sans-serif"
                            id="session_bet_row"
                          >
                            {session.back_size1}
                          </td>
                        </>
                      )}
                      <td
                        colSpan={1}
                        className="bet-place-tbl-td bet-val-cent close-status font-sans-serif"
                      >
                        <img
                          className="ladder_img"
                          src="/images/ladder.png"
                          width={"50px"}
                          onClick={() => showSessionBook(session?.session_id)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      )
    );
  }
}

export default React.memo(SessionDetail);
