import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getStorage, setBodyColor } from "../../utils";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const data = getStorage("clientLogin", "object", {});

  const themeColors = {
    color1: {
      primary: "#483B8C",
      secondary: "#9A93E0", // Purple
    },
  };
  setBodyColor({
    color: data?.theme?.color_code
      ? data?.theme?.color_code
      : themeColors.color1,
  });

  let isLoggedIn = rest?.auth?.clientLogin?.user_id ? true : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(PrivateRoute);
