import React, { useEffect, useState } from "react";
import { getMatchDetailRequest } from "../../_actions";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getMarketSessionBet, getclientBalance } from "../../_services";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDateAndTime, getLeftBazarTime } from "../../utils";
import MatkaHeader from "./MatkaHeader";
import "./matkaChart.css";

const MatkaChartDetail = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [state, setAllStateValue] = useState({
    betAmount: 0,
    redirect: "",
    width: window.innerWidth,
    height: window.innerHeight,
    bookmakerType: 0,
    bettingType: 0,
    price: 0,
    displayPrice: 0,
    marketId: "",
    selectionId: "",
    toggleBetForm: false,
    name: "",
    marketBetData: "",
    betTotalRecords: 0,
    sessionBetData: "",
    eventId: "",
    shown: false,
    showAlertDialog: false,
    alertType: "",
    alertMessage: "",
    commentaryData: "",
    timer: 0,
    betPlacedClick: false,
  });
  const [matchDetail, setMatchDetails] = useState({});
  const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
  const [oddsStack, setOddsStack] = useState([]);
  const [odd_diff, setOddDiff] = useState("");
  const [openSettingModal, setOpenSettingModal] = useState(false);
  const [callBalanceAPI, setCallBalanceAPI] = useState(false);
  const [marketType, setActiveMarket] = useState(
    localStorage.getItem("matka_market_id")
  );
  const [matkaType, setActiveMatka] = useState("");
  const [fetchBetList, fetchMatkaBetList] = useState(false);
  const [betClosed, setBetClosed] = useState(false);
  const [isLoading, setIsloading] = useState(true);

  useEffect(() => {
    return () => {
      setMatchDetails({});
      localStorage.removeItem("matka_market_id");
      window.removeEventListener("resize", updateDimensions);
      if (state.socketValue) {
        setMatchDetails({});
      }
    };
  }, []);

  useEffect(() => {
    if (params?.event_id) {
      loadData();
    }
  }, [params?.event_id]);

  useEffect(() => {
    if (state.eventId && params.event_id !== state.eventId) {
      setState({ eventId: params.event_id });
      window.location.reload();
    }
  }, [params.event_id]);

  useEffect(() => {
    getclientBalanceData();
  }, [callBalanceAPI]);

  const getclientBalanceData = async () => {
    getclientBalance().then((response) => {
      if (response && response.data) {
        setOddsStack(
          response.data.odds_stack ? response.data.odds_stack.split(",") : []
        );
        setOddDiff(response?.data?.odd_diff ? response.data.odd_diff : "");
      }
    });
  };

  useEffect(() => {
    if (!state.toggleBetForm) {
      clearValues();
    }
  }, [state.toggleBetForm]);

  const setState = (obj, callback) => {
    setAllStateValue((s) => ({ ...s, ...obj }));
    if (callback) callback();
  };

  const clearValues = () => {
    setState({
      betAmount: 0,
      bookmakerType: 0,
      bettingType: 0,
      price: 0,
      displayPrice: 0,
      marketId: "",
      selectionId: "",
      name: "",
    });
  };
  const updateDimensions = () => {
    setState({ width: window.innerWidth, height: window.innerHeight });
  };

  const loadData = () => {
    // window.addEventListener("resize", updateDimensions);
    fetchMatchDetailData();
  };

  useEffect(() => {
    fetchMarketBet();
  }, [matkaType, fetchBetList]);

  const fetchMatchDetailData = () => {
    const { event_id } = params;
    if (event_id) {
      dispatch(
        getMatchDetailRequest({ event_id }, (res) => {
          matchDetailParse(res);
          setState({ eventId: event_id });
        })
      );
    } else {
      setState({ eventId: event_id });
    }
  };

  const matchDetailParse = (data) => {
    let finalData = [];
    finalData = data.market;
    data.market.map((market, markey) => {
      market.runners.map((run, key) => {
        market.runner_odds.find((odd) => {
          if (run.selectionId === odd.selectionId) {
            run["runner_odd"] = odd;
            finalData[markey].runners[key] = run;
          }
        });
      });
    });
    if (data?.event?.sport_id == 1) {
      let allMarket = finalData;
      for (let i = 0; i < allMarket.length; i++) {
        const marketRunner = allMarket[i].runners;
        const updatedRunner = [];
        let drawRunner = null;
        for (let j = 0; j < marketRunner.length; j++) {
          if (marketRunner[j].runnerName == "The Draw") {
            drawRunner = marketRunner[j];
          } else {
            updatedRunner.push(marketRunner[j]);
          }
        }
        if (drawRunner) {
          updatedRunner.push(drawRunner);
        }
        allMarket[i].runners = updatedRunner;
      }
      finalData = allMarket;
    }
    data.market = finalData;
    const liveData = data.event.score ? JSON.parse(data.event.score) : "";
    const parseData = parseLiveScore(liveData, data); //ig
    data.live_msg = parseData.liveScoreMsg;
    data.live_score1 = parseData.live_score1;
    data.live_score2 = parseData.live_score2;
    handleAllactiveFancy(state.allActiveFancySessions, data);
  };

  const parseLiveScore = (liveData, data) => {
    //
    let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

    if (liveData) {
      let liveScore = liveData.data;
      let liveScore1 = "";
      let liveScore2 = "";
      let liveScoreMsg = "";
      let team1 = "";
      let team2 = "";
      try {
        const runners = data.market[0].runners;
        team1 = runners[0].runnerName;
        team2 = runners[1].runnerName;
      } catch (error) { }
      liveScore1 = team1 + " (" + liveScore.spnnation1 + ") ";
      liveScore2 = team2 + " (" + liveScore.spnnation2 + ") ";

      if (liveScore.score1 && liveScore.score1 != "") {
        liveScore1 += liveScore.score1 + " Ovs";
      } else {
        liveScore1 += " 0-0 (0.0) Ovs";
      }

      if (liveScore.score2 && liveScore.score2 != "") {
        liveScore2 += liveScore.score2 + " Ovs";
      } else {
        liveScore2 += " 0-0 (0.0) Ovs";
      }
      let rrr = "";
      if (liveScore.spnreqrate1 != "" || liveScore.spnreqrate2 != "") {
        rrr = liveScore.spnreqrate1
          ? liveScore.spnreqrate1
          : liveScore.spnreqrate2;
        if (rrr != "") {
          rrr = " RRR: " + rrr;
        }
      }
      liveScoreMsg = liveScore.spnmessage + rrr;
      finalData = {
        live_score1: liveScore.activenation1 == "1" ? liveScore1 : liveScore2,
        live_score2: liveScore.activenation2 == "1" ? liveScore1 : liveScore2,
        liveScoreMsg: liveScoreMsg,
      };
    }
    return finalData;
  };

  const handleAllactiveFancy = (
    allActiveFancy = allActiveFancySessions,
    matchData
  ) => {
    let fancyData = matchData?.fancy.filter((itemFacny) =>
      allActiveFancy.length
        ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
        : true
    );
    matchData.fancy = fancyData;
    setBetClosed(
      getLeftBazarTime(
        getDateAndTime("YYYY-MM-DD HH:mm:ss", matchData?.event?.open_date)
      )?.bet_sec > matchData?.event?.bet_close_time
    );
    setMatchDetails(matchData);
    setActiveMarket(
      localStorage.getItem("matka_market_id")
        ? localStorage.getItem("matka_market_id")
        : matchData?.market[0]?.market_name
    );
    setIsloading(false);
    setAllActiveFancySessions(allActiveFancy);
  };

  const fetchMarketBet = () => {
    const { event_id } = params;
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.filter = 1;
    sendData.event_id = event_id;
    sendData.market_name = matkaType;

    getMarketSessionBet(sendData)
      .then((response) => {
        if (response && response?.data?.open_bets) {
          const betData =
            response?.data?.open_bets.length > 0
              ? response?.data?.open_bets.reduce((itemArray, item) => {
                const foundItem = itemArray.find(
                  (element) => element.market_name === item.market_name
                );

                if (foundItem) {
                  foundItem.list.push(item);
                } else {
                  itemArray.push({
                    market_name: item.market_name,
                    list: [item],
                  });
                }

                return itemArray;
              }, [])
              : [];
          setState({
            marketBetData: betData,
            betTotalRecords: response.data.total_records,
          });
        } else {
          setState({ marketBetData: [], betTotalRecords: 0 });
        }
      })
      .catch((error) => {
        setState({ marketBetData: [], betTotalRecords: 0 });
      });
  };

  const updateTimer = () => {
    if (state.timer == 10) {
      setState({ timer: 0, toggleBetForm: false });
    } else {
      setTimeout(() => {
        let time = state.timer;

        time += 1;
        setState({ timer: time }, () => {
          if (!state.betPlacedClick) {
            updateTimer();
          }
        });
      }, 1000);
    }
  };

  const { marketBetData, sessionBetData, betTotalRecords } = state;
  return (
    <>
      <div className="container">
        <MatkaHeader
          betClosed={betClosed}
          setBetClosed={setBetClosed}
          matchDetail={matchDetail}
        />
        <div className="row mob_view anv-margTop-2">
          <table className="mainMatka_table">
            <thead>
              <th><div style={{ width: "70px" }} >DATE</div></th>
              <th><div style={{ width: "70px" }} >MONDAY</div></th>
              <th><div style={{ width: "70px" }} >TUESDAY</div></th>
              <th><div style={{ width: "70px" }} >WEDNESDAY</div></th>
              <th><div style={{ width: "70px" }} >THURSDAY</div></th>
              <th><div style={{ width: "70px" }} >FRIDAY</div></th>
              <th><div style={{ width: "70px" }} >SATURDAY</div></th>
              <th><div style={{ width: "70px" }} >SUNDAY</div></th>
            </thead>

            <tbody>
              <tr>
                <td>
                  <div style={{ width: "120px", color: "#4b4186" }}>02-01-2017 <br /><br />TO<br /><br />08-01-2017</div>
                </td>

                <td>
                  <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>


                <td><div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                  </td>

                <td>
                <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>

                <td>
                <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>

                <td>
                <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>

                <td>
                <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>

                <td>
                <div className="chrtChar_bx">
                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx middle-cht">**</div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>

                    <div className="chrtChar_row">
                      <div className="chrtChar_innrFx">*</div>
                      <div className="chrtChar_innrFx"></div>
                      <div className="chrtChar_innrFx">*</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="col-12" style={{ textAlign: "center" }}></div>
        </div>
      </div>
    </>
  );
};

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default connect(mapPropsToState)(MatkaChartDetail);
