import moment from "moment-timezone";
export function getStorage(key, type, defaultValue) {
  let data = localStorage.getItem(key);
  if (data) {
    switch (type) {
      case "array":
      case "object":
        try {
          data = JSON.parse(data);
        } catch (e) {
          data = defaultValue;
        }
        break;
      case "string":
        break;
      default:
        data = defaultValue;
        break;
    }
  } else {
    data = defaultValue;
  }
  return data;
}

/**
 * Sorts an array of objects by column/property.
 * @param {Array} array - The array of objects.
 * @param {object} sortObject - The object that contains the sort order keys with directions (asc/desc). e.g. { age: 'desc', name: 'asc' }
 * @returns {Array} The sorted array.
 */
export function multiSort(array, sortObject = {}) {
  const sortKeys = Object.keys(sortObject);
  if (!array) {
    return [];
  }
  // Return array if no sort object is supplied.
  if (!sortKeys.length) {
    return array;
  }

  // Change the values of the sortObject keys to -1, 0, or 1.
  for (let key in sortObject) {
    sortObject[key] =
      sortObject[key] === "desc" || sortObject[key] === -1
        ? -1
        : sortObject[key] === "skip" || sortObject[key] === 0
        ? 0
        : 1;
  }

  const keySort = (a, b, direction) => {
    direction = direction !== null ? direction : 1;

    if (a === b) {
      // If the values are the same, do not switch positions.
      return 0;
    }

    // If b > a, multiply by -1 to get the reverse direction.
    return a > b ? direction : -1 * direction;
  };

  return array.sort((a, b) => {
    let sorted = 0;
    let index = 0;

    // Loop until sorted (-1 or 1) or until the sort keys have been processed.
    while (sorted === 0 && index < sortKeys.length) {
      const key = sortKeys[index];

      if (key) {
        const direction = sortObject[key];

        sorted = keySort(a[key], b[key], direction);
        index++;
      }
    }

    return sorted;
  });
}
export function getDateAndTime(dateFormat, date) {
  const date_time = moment.utc(date).local().format(dateFormat);
  return date_time;
}

export const t = (textKey) => {
  const langCode = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en";
  var translationData = [];
  try {
    translationData = JSON.parse(localStorage.getItem("translation"));
  } catch (e) {
    translationData = [];
  }
  const langTrans =
    translationData && translationData[langCode]
      ? translationData[langCode]
      : {};
  return langTrans && langTrans[textKey] ? langTrans[textKey] : textKey;
};

export const t_with_params = (textKey, dataObj) => {
  const langCode = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en";
  var translationData = [];
  try {
    translationData = JSON.parse(localStorage.getItem("translation"));
  } catch (e) {
    translationData = [];
  }
  const langTrans =
    translationData && translationData[langCode]
      ? translationData[langCode]
      : {};
  const enlangTrans =
    translationData && translationData["en"] ? translationData["en"] : {};

  let entranText =
    enlangTrans && enlangTrans[textKey] ? enlangTrans[textKey] : textKey;

  let tranText =
    langTrans && langTrans[textKey] ? langTrans[textKey] : entranText;

  if (tranText) {
    Object.keys(dataObj).map((key) => {
      tranText = tranText.replace(key, dataObj[key]);
      return null;
    });
  }
  return tranText;
};

export const changeLanguage = (langCode) => {
  let language = langCode;
  localStorage.setItem("language", language);
  // this.setState({ language: language });
};

export const getTranslatedData = (data) => {
  if (typeof data == "object") {
    const langCode = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
    return data[langCode] ? data[langCode] : data["en"] ? data["en"] : "";
  } else {
    return "--";
  }
};

export const setBodyColor = ({ color }) => {
  document.documentElement.style.setProperty(
    "--body-color-primary",
    color.primary
  );
  document.documentElement.style.setProperty(
    "--body-color-secondary",
    color.secondary
  );
};

export const commentaryType = {
  1: {
    commentary_button_id: "1",
    button_label: "CLOSE ALL",
    message: "Close all, please try again later",
  },
  2: {
    commentary_button_id: "2",
    button_label: "OPEN ALL",
  },
  3: {
    commentary_button_id: "3",
    button_label: "BET CLOSE",
    message: "Bet is closed, please try again later",
  },
  4: {
    commentary_button_id: "4",
    button_label: "BET OPEN",
  },
  5: {
    commentary_button_id: "5",
    button_label: "SESSION CLOSE",
    message: "Session is closed, please try again later",
  },
  6: {
    commentary_button_id: "6",
    button_label: "SESSION OPEN",
  },
};

export const appendStackValue = (stack) => {
  if (!stack) {
    return 0;
  }
  switch (stack.length) {
    case 4:
      return !Number.isInteger(+stack / 1000)
        ? stack
        : stack.slice(0, 1) + " K";
    case 5:
      return !Number.isInteger(+stack / 1000)
        ? stack
        : stack.slice(0, 2) + " K";
    case 6:
      return !Number.isInteger(+stack / 100000)
        ? stack
        : stack.slice(0, 1) + " L";
    case 7:
      return !Number.isInteger(+stack / 100000)
        ? stack
        : stack.slice(0, 2) + " L";
    default:
      return stack;
  }
};

const sessionType = {
  1: { position: 1, name: "Session", value: "_fancy_stack" },
  6: { position: 2, name: "Fancy", value: "_fancy_run_stack" },
  7: { position: 3, name: "Ball By Ball", value: "_ball_by_ball_stack" },
  5: { position: 4, name: "Others", value: "_other_fancy_stack" },
  8: { position: 5, name: "Advance", value: "_advance_fancy_stack" },
};

export const differentFancyList = (list, sessionBetData = []) => {
  const fancyArray = list.reduce((itemArray, item) => {
    const session_type = item?.session_type ? item?.session_type : 1;
    const itemValue = {
      session_type: session_type <= 4 ? 1 : session_type,
      session: sessionType[session_type <= 4 ? 1 : session_type],
      data: [item],
      f_exposure:
        item?.session_type <= 4
          ? sessionBetData?.reduce((sum, s) => {
              sum = s?.session_type <= 4 ? sum + +s?.pl : sum;
              return sum;
            }, 0)
          : sessionBetData?.reduce((sum, s) => {
              sum = s?.session_type == item?.session_type ? sum + +s?.pl : sum;
              return sum;
            }, 0),
    };
    if (itemArray.length > 0) {
      const ind = itemArray.findIndex((i) =>
        i.session_type === 1
          ? item?.session_type <= 4
          : i.session_type === item?.session_type
      );
      if (ind >= 0) {
        const itemList = itemArray[ind]?.data;
        itemList.push(item);
        itemArray.splice(ind, 1, {
          ...itemArray[ind],
          data: itemList,
        });
      } else {
        itemArray.push(itemValue);
      }
    } else {
      itemArray.push(itemValue);
    }
    return itemArray;
  }, []);
  return fancyArray.sort((a, b) => a?.session?.position - b?.session?.position);
};

export const showMinMax = (type, market, event, format = false) => {
  let min = "0",
    max = "0";
  if (type == "SESSION") {
    switch (true) {
      case market?.session_type <= 4:
        max = event?.max_fancy_stack;
        min = event?.min_fancy_stack;
        break;
      case market?.session_type == 5:
        max = event?.max_other_fancy_stack;
        min = event?.min_other_fancy_stack;
        break;
      case market?.session_type == 6:
        max = event?.max_fancy_run_stack;
        min = event?.min_fancy_run_stack;
        break;
      case market?.session_type == 7:
        max = event?.max_ball_by_ball_stack;
        min = event?.min_ball_by_ball_stack;
        break;
      case market?.session_type == 8:
        max = event?.max_advance_fancy_stack;
        min = event?.min_advance_fancy_stack;
        break;
    }
  } else {
    // if (market?.showLagaiOnly) {
    //   if (format) {
    //     return event?.min_other_market_stack || event?.max_other_market_stack
    //       ? `Min:${event?.min_other_market_stack} | Max:${event?.max_other_market_stack}`
    //       : null;
    //   } else {
    //     return event?.min_other_market_stack || event?.max_other_market_stack
    //       ? {
    //           min: event?.min_other_market_stack,
    //           max: event?.max_other_market_stack,
    //         }
    //       : { min, max };
    //   }
    // }

    switch (market?.market_type) {
      case 1:
        if (+event?.cup_event === 1) {
          max = event?.max_cup_stack;
          min = event?.min_cup_stack;
        } else {
          max = event?.max_match_stack;
          min = event?.min_match_stack;
        }
        break;
      case 2:
        max = event?.max_bookmaker_stack;
        min = event?.min_bookmaker_stack;
        break;
      default:
        max = event?.max_other_market_stack;
        min = event?.min_other_market_stack;
        break;
    }
  }
  if (format) {
    return `Min:${appendStackValue("" + min)} | Max:${appendStackValue(
      "" + max
    )}`;
  } else {
    return { min, max };
  }
};

export const convertParseFloat = (num) =>
  num != 0 ? parseFloat(num).toFixed(2).replace(/\.00$/, "") : num;

export const sumNumbersArray = (array) => {
  let sum = 0,
    index = 0;
  while (true) {
    let num = array[index++];
    sum += +num;
    if (index < array.length) {
      continue;
    } else {
      break;
    }
  }

  return sum;
};

export const getLeftBazarTime = (dateTime) => {
  const currentTime = moment().utc();
  let difference = moment(dateTime, "YYYY-MM-DD HH:mm:ss").diff(
    currentTime,
    "seconds"
  );

  if (difference < 0) {
    difference = 0; // Set the difference to 0 if currentTime > dateTime
  }
  const duration = moment.duration(difference, "seconds");
  return {
    bet_sec: difference,
    left: moment.utc(duration.asMilliseconds()).format("HH:mm:ss"),
  };
};

export const asteriskList = ["*", "*", "*", "*", "*", "*", "*", "*"];

export const getBetDelayTime = (name, selectedData, event, addTime = 0) => {
  let delay = 0;
  if (name === "SESSION") {
    switch (true) {
      case selectedData?.session_type === 1:
        delay = event.session_run_delay;
        break;
      case selectedData?.session_type === 2:
        delay = event.session_run2_delay;
        break;
      case selectedData?.session_type === 3:
        delay = event.fall_of_wicket_delay;
        break;
      case selectedData?.session_type === 4:
        delay = event.player_run_delay;
        break;
      case selectedData?.session_type === 5:
        delay = event.other_fancy_delay;
        break;
      case selectedData?.session_type === 6:
        delay = event.fancy_run_delay;

        break;
      case selectedData?.session_type === 7:
        delay = event.ball_by_ball_delay;
        break;
      case selectedData?.session_type === 8:
        delay = event.advance_fancy_delay;
        break;
    }
  } else {
    if (+event?.cup_event === 1) {
      delay = event.cup_delay;
    } else {
      switch (selectedData?.market_type) {
        case 1:
          delay = event.match_delay;
          break;
        case 2:
          delay = event.bookmaker_delay;
          break;
        default:
          delay = event.other_market_delay;
          break;
      }
    }
  }
  let time = 3000;
  try {
    time = (+delay) * 1000;
    time += addTime;
    return time;
  } catch (err) {
    return time;
  }
};
