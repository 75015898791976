import React from "react";
import { connect } from "react-redux";
import { svgImage } from "../common/svgConstant";

class DashboardComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  render() {
    return (
      <>
        <div className="container dashboard_content">
          <div className="vertical_mid">
            {this.state.width >= 780 ? (
              <>
                <div className="row mob-row-320 content-top-padding">
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color"
                      href="/match/inplay/999"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        width="512px"
                        height="512px"
                        y="0px"
                        viewBox="0 0 25 25"
                        className="span-btn play-all pull-left h-icons"
                      >
                        <path
                          class="st18"
                          d="M21.9,3.3C22,3.4,22,3.4,22,3.5c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.5-0.2,0.8c-0.1,0.1-0.2,0.2-0.4,0.2
	c-0.5,0.3-1.1,0.5-1.8,0.7l0,0c-0.1,0-0.1,0-0.2,0.1C19.4,5.8,19.2,6,19,6.3l-0.1,0.1l0.2,0c0.8-0.2,1.6-0.5,2.5-1
	c0.4-0.2,0.7-0.5,0.9-0.7c0.2-0.3,0.3-0.6,0.2-0.8C22.5,3.6,22.3,3.4,21.9,3.3l-0.1-0.2L21.9,3.3z"
                        />
                        <path
                          class="st18"
                          d="M4.7,6L4.9,6l0-0.1c0-0.4-0.3-0.5-0.5-0.6C4.2,5.3,4,5.1,3.8,5C3.4,4.7,3.1,4.3,3,3.9l0,0c0-0.1,0-0.1,0-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.4,1,0.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
	c0,0,0-0.1,0-0.1C4.8,5,4.8,5,4.7,5c0,0,0,0,0,0C4.1,4.6,3.4,4.1,3.3,3.1l0-0.1L3.2,3c0,0-0.1,0.1-0.1,0.1C3,3.2,2.9,3.3,2.8,3.4
	C2.3,3.8,2.3,4.3,2.7,4.7C3.2,5.4,3.9,5.7,4.7,6z"
                        />
                        <path
                          class="st18"
                          d="M14.4,6.9c-0.2,0-0.3-0.1-0.3-0.2c-0.3-0.2-0.5-0.2-0.8,0c-0.1,0.1-0.3,0.1-0.4,0.2C12.8,7,12.7,7,12.6,7
	l-0.2,0.1l2-0.1V7C14.4,7,14.4,7,14.4,6.9L14.4,6.9L14.4,6.9z"
                        />
                        <path
                          class="st18"
                          d="M11,6.7C11,6.7,11,6.7,11,6.7l-0.1,0c-0.2,0.1-0.5,0.2-0.7,0.1l0,0c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2
	C9.7,6.5,9.6,6.4,9.5,6.4c-0.1,0-0.2,0-0.4,0C9.1,6.5,9,6.5,8.9,6.5c0,0,0,0,0,0C8.4,6.5,8,6.4,7.6,6.2C7.4,6.1,7.2,6.1,7.1,6.1
	c-0.1,0-0.2,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1L6.4,6.4l0.1,0c0.3,0.1,0.7,0.2,1,0.2l0.1,0C8.4,6.8,9.2,7,10,7l0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0.1,1.1,0.1c0.2,0,0.3,0,0.5,0l0.4,0L11.8,7c-0.2-0.1-0.5-0.2-0.6-0.3
	C11.1,6.6,11,6.7,11,6.7z"
                        />
                        <path
                          class="st18"
                          d="M16.2,6.5L15.6,7L17,6.8l-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.2C16.3,6.4,16.2,6.4,16.2,6.5z"
                        />
                        <path
                          class="st18"
                          d="M21.5,3.1c0,0,0.1,0,0.1,0l0.2,0L21.7,3c-0.3-0.2-0.6-0.3-0.9-0.4l-0.1,0l0,0.1C20.9,3.1,21.2,3.1,21.5,3.1z"
                        />
                        <path
                          class="st18"
                          d="M15.5,0.8c-0.1,0-0.1-0.1-0.2-0.1c-0.3,0-0.5,0.1-0.6,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
	c-0.4,0-0.7,0.1-1,0.2c-0.1-0.4-0.2-0.7-0.5-0.9c-0.2-0.2-0.6-0.3-0.9-0.2c-0.6,0.1-1,0.3-1.5,0.8c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0
	C9.8,0.7,8.7,1,8.1,1.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0C7.9,1.8,7.8,1.7,7.6,1.7C6.8,1.5,6.1,1.7,5.4,2.2c-0.1,0.1-0.1,0.1-0.2,0
	C4.9,2.2,4.7,2.2,4.4,2.2c-0.2,0-0.5,0.2-0.7,0.5C3.6,3,3.5,3.3,3.7,3.6c0.3,0.6,0.8,1,1.4,1.3c0.1,0,0.1,0.1,0.1,0.1
	c0,0,0,0.1-0.1,0.2C5,5.3,5,5.6,5.3,5.9C5.5,6.2,5.8,6.2,6,6.2c0.2,0,0.5-0.1,0.8-0.3c0.3-0.3,0.5-0.2,0.9,0C8.3,6.3,9,6.3,9.6,6
	c0,0,0,0,0.1,0l0,0c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.8,0.3c0.2-0.1,0.4-0.1,0.5-0.3c0.1,0.2,0.2,0.2,0.4,0.3
	c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.9-0.2,1.3-0.5c0.1-0.1,0.2-0.1,0.3,0c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.4,1.1-0.7
	c0.5,0.4,1,0.5,1.5,0.5c0.8-0.1,1.2-0.4,1.4-0.9C19.2,5.1,19.4,5,19.8,5c0,0,0.1,0,0.1,0c0.7-0.2,1.2-0.4,1.7-0.7
	c0.1-0.1,0.3-0.2,0.2-0.5c-0.1-0.3-0.2-0.3-0.4-0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0l-0.1,0l0,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1l0,0c0-0.2,0-0.4-0.1-0.6c-0.2-0.2-0.4-0.3-0.7-0.3l-0.1,0c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.4-0.6-0.9-0.9-1.6-1.1c-0.4-0.1-0.9-0.1-1.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0.1l0-0.1l0,0l0,0l0-0.1
	l0,0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4l0,0C15.7,1,15.7,0.9,15.5,0.8 M15.8,4.1C15.8,4.1,15.8,4.1,15.8,4.1c-0.1-0.1-0.1-0.1-0.2-0.2
	c0,0-0.1-0.1,0-0.1c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.3,0.2
	c0.1,0.1,0.1,0.1,0.2,0.2l0,0C16.3,3.5,16,3.7,15.8,4.1z M16.1,4.2c0.2-0.4,0.6-0.6,1.1-0.7c0.1,0,0.1,0,0.2,0l0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l0,0c0.5,0,0.9,0.2,1.3,0.6c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.2,0.2-0.2,0.4-0.1,0.5
	c0.1,0.2,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-0.2,0.4-0.5,0.6-0.9,0.7c-0.3,0.1-0.6,0.1-0.9,0c-0.1,0-0.3-0.1-0.4-0.2
	c-0.2-0.1-0.4-0.3-0.3-0.5c0-0.3-0.1-0.6-0.2-0.8C16,4.4,16,4.3,16.1,4.2z M16.2,2.7C16.2,2.7,16.2,2.7,16.2,2.7
	C16.2,2.7,16.2,2.7,16.2,2.7L16.2,2.7C16.1,2.7,16.1,2.7,16.2,2.7C16,2.5,15.9,2.3,16,2.1c0-0.1,0.1-0.1,0.2-0.2
	C16,2.2,16,2.4,16.2,2.7z M11.4,2.8c0.4,0.3,0.9,0.4,1.3,0.4l0.1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.1-0.7,0.2-1.1,0.1h0h0c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.1-0.2l0,0
	c-0.2-0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2-0.1,0.3-0.1C11.3,2.8,11.3,2.8,11.4,2.8C11.4,2.8,11.4,2.8,11.4,2.8
	z M8.2,2.4l0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.1-0.1,0.2C8.5,3.1,8.4,3.2,8.4,3.4h0c0,0,0,0,0,0c0,0,0,0,0,0
	C8.1,3.4,8,3.4,7.8,3.4c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0C7.1,3.2,6.8,3,6.7,2.7C6.7,2.4,6.8,2.3,6.9,2.3c0.3-0.1,0.6-0.1,0.9,0
	C8,2.3,8,2.3,8.2,2.4L8.2,2.4z M9.1,2.8C9.1,2.8,9.1,2.8,9.1,2.8 M9.1,2.7C9.2,2.7,9.2,2.7,9.1,2.7c0.5-0.4,0.9-0.5,1.4-0.4
	c0.2,0.1,0.4,0.2,0.5,0.4c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.4,0.1-0.5,0.3-0.4,0.7c0,0.1,0.1,0.2,0.2,0.2
	c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.6-0.9,1-1.5,1C9.6,5,9.5,5,9.4,5C8.9,4.9,8.5,4.5,8.5,3.9
	C8.6,3.5,8.7,3.1,9.1,2.7 M11.2,5.6c0-0.1,0-0.1,0-0.2C11.5,6,12,6.1,12.5,6.2c0,0,0,0,0,0c-0.4,0.2-1.1,0-1.4-0.3
	C11.2,5.8,11.2,5.8,11.2,5.6C11.2,5.7,11.2,5.7,11.2,5.6z M11.4,4.7c0.1-0.3,0.2-0.5,0.5-0.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0.1,0.1c0.1,0.2,0.2,0.2,0.4,0.3c0.5,0.2,1,0.2,1.7,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0.3-0.2,0.5-0.5,0.7
	c-0.3,0.2-0.7,0.2-1,0.2c-0.4,0-0.8-0.1-1.2-0.4C11.4,5.2,11.3,4.9,11.4,4.7z M14.2,5.8c0.4-0.3,0.5-0.6,0.4-1.1c0-0.1,0-0.1,0-0.1
	c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.3c0.4,0.5,0.5,1,0.4,1.5c-0.1,0.4-0.5,0.7-1,0.6
	C14.7,6.2,14.4,6.1,14.2,5.8C14.1,5.8,14.2,5.8,14.2,5.8z M15.3,3.8c0,0.1-0.1,0.2-0.2,0.2c-0.6,0.2-1.6,0.3-2.3,0.1
	c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.2,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0,1.2,0.2
	l0,0C15.2,3.6,15.3,3.6,15.3,3.8z M13.9,3.2C13.9,3.2,13.9,3.2,13.9,3.2C13.9,3.1,13.9,3.1,13.9,3.2c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.2-0.1l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
	C14.1,3.2,14,3.2,13.9,3.2z M14.4,2.5L14.4,2.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.1-1.3,0.1-1.8-0.1l-0.1,0c-0.2-0.1-0.3-0.1-0.4-0.3
	c0-0.1,0.1-0.2,0.3-0.2c0,0,0.1,0,0.1,0l0,0c0.5-0.1,0.9-0.1,1.4-0.1c0.1,0,0.2,0,0.2,0L14.2,2c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1
	c0.1,0.1,0.2,0.1,0.2,0.2C14.6,2.4,14.5,2.4,14.4,2.5z M9,2.5C9,2.5,8.9,2.5,9,2.5C8.9,2.6,8.9,2.6,9,2.5C8.8,2.4,8.6,2.3,8.3,2.3
	l0,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C8.5,1.7,9,1.3,9.5,1.3c0.4-0.1,0.8,0.1,1.1,0.3c0.2,0.2,0.2,0.4,0.3,0.6
	C10.2,1.9,9.6,2,9,2.5z M5.4,5.2C5.4,5,5.4,5,5.6,5c0,0,0.1,0,0.1,0C6,5,6.3,5,6.6,4.8c0,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0s0,0,0,0
	C6.5,5.5,6.1,5.7,5.7,5.7c-0.2,0-0.3,0-0.3-0.1C5.3,5.5,5.3,5.4,5.4,5.2z M10,5.4c0.3-0.1,0.6-0.2,0.9-0.4c0.1,0.4,0.1,0.7,0,0.9
	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3-0.1C10.2,6,10.1,5.8,9.9,5.6C9.9,5.6,9.9,5.5,10,5.4C9.9,5.5,10,5.4,10,5.4z M19.2,2.9
	c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.7,0.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-1-0.7-1.1-1.1c0-0.2,0-0.3,0.1-0.4
	c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.8,0.1,1.4,0.4,1.8,1C19.1,2.6,19.1,2.7,19.2,2.9z M15.7,1.7
	c0,0.7-0.1,1.3-0.2,1.9c-0.2-0.2-0.4-0.2-0.5-0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.6,0-1.1,0.2-1.7c0-0.1,0.1-0.2,0.2-0.3
	C15.3,1,15.3,1,15.4,1c0,0,0,0,0,0c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1l0,0 M15.6,1.2c0,0.1,0.1,0.2,0.1,0.3 M14.5,1.6L14.5,1.6
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.2,0l0,0c0,0,0,0,0,0l0,0l0,0c-0.2-0.1-0.3-0.1-0.5-0.1l-0.2,0c0,0,0,0,0,0
	c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0C14,1.5,14.2,1.5,14.5,1.6C14.5,1.5,14.5,1.5,14.5,1.6C14.5,1.5,14.6,1.5,14.5,1.6z M13.1,1
	c0.1,0.2,0.1,0.5-0.1,0.8c-0.4,0-0.8,0-1.1,0.2L11.6,2l0,0l0,0c-0.1,0.1-0.3,0.2-0.3,0.4c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.3-0.1-0.7-0.4-1c-0.1-0.1-0.1-0.1,0-0.1h0c0.5-0.6,1.1-0.8,1.7-0.7C12.8,0.6,13,0.8,13.1,1z M6.9,4.2
	C7,4,7.2,3.9,7.5,3.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.2
	c0,0.1,0,0.1,0,0.2C8.4,4.8,9,5.4,9.5,5.4c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1C9.4,5.9,9.1,5.9,8.7,6C7.9,5.9,7.4,5.6,7,5
	C6.8,4.8,6.8,4.5,6.9,4.2z M19.9,3.8l0.6-0.1 M19.9,3.8 M20.5,3.7c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l0,0l0,0
	c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0.1-0.1,0.1C21.2,3.9,21,4,20.9,4c-0.7,0.4-1.4,0.5-2,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2-0.1
	c0-0.1,0-0.1,0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0l0.1,0l0,0l0,0v0c0,0,0.1,0,0.1-0.1
	C19.3,4,19.5,4,19.7,3.9c0,0,0.1,0,0.1,0l0,0l0,0 M19.9,2.7c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.3,0.6
	c0,0,0,0,0,0l0,0v0l0,0c0,0-0.1,0-0.1,0l-0.1,0c-0.2,0-0.4,0.1-0.5,0.2l-0.1,0c0,0-0.1,0-0.1-0.1c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.1c0,0,0,0,0.1,0C19.6,2.7,19.7,2.7,19.9,2.7z M7.9,2.1C7.6,2,7.3,2,7,2
	c-0.3,0-0.5,0.2-0.6,0.4C6.4,2.6,6.3,2.8,6.4,3C6.1,2.7,5.9,2.6,5.6,2.4C6,2,6.7,1.8,7.3,1.9C7.5,1.9,7.7,1.9,7.9,2.1z M6.6,4.2
	C6.5,4.4,6.3,4.5,6,4.5c-0.7,0-1.7-0.6-2-1.2C4,3.1,4,2.9,4.1,2.7c0.1-0.2,0.4-0.3,0.7-0.3c0.8,0.1,1.4,0.5,1.8,1.2
	C6.7,3.8,6.7,4,6.6,4.2z"
                        />
                        <path
                          class="st18"
                          d="M24.9,11.5c0-1.1-0.7-2-1.7-2c-0.2,0-0.4,0-0.6,0.1c-0.5-0.8-1.1-1.5-1.9-2.1c0-0.2,0-0.3,0-0.5l0-0.9l-0.6,0.2
	c-0.5,0.2-1,0.4-1.5,0.5c-1.6,0.4-3.3,0.7-5.3,0.7c-1.9,0.1-3.6,0-5.2-0.3c-1.2-0.2-2.1-0.4-3-0.8L4.4,6.3L4.2,6.7
	c-0.1,0.2-0.1,0.5,0,0.7l0,0.1c0,0.2,0,0.2-0.1,0.2C4,7.7,3.9,7.7,3.8,7.8C3.2,8.4,2.7,9,2.4,9.6C2.2,9.5,2,9.5,1.8,9.5
	c-0.9,0-1.7,0.9-1.7,2c0,0.8,0.4,1.5,1,1.8c-0.1,1,0,2,0.3,3.1C2,18.9,3.5,20.8,5.8,22c0.3,0.1,0.5,0.3,0.8,0.4
	c-0.3,1.8-0.1,2,0.1,2.1l0.1,0c0.2,0,0.4-0.1,1-1.6c0.7,0.2,1.4,0.3,2.2,0.5c2.2,0.3,4,0.3,5.7-0.1c0.4-0.1,0.8-0.2,1.1-0.3
	c0.6,1.6,0.8,1.7,1,1.7l0.1,0c0.2-0.1,0.4-0.3,0.1-2.1c0.7-0.3,1.4-0.6,2-1c2.5-1.7,4-4.9,3.8-8.1C24.5,13.1,24.9,12.3,24.9,11.5z
	 M23.1,10.3L23.1,10.3c0.5-0.1,1,0.5,1,1.2c0,0.4-0.1,0.8-0.4,1C23.6,11.7,23.3,11,23.1,10.3C23,10.3,23.1,10.3,23.1,10.3z
	 M20.3,8.1c1.5,1.1,2.4,2.7,2.7,4.6c0.6,3.1-0.8,6.4-3.3,8c-1.2,0.8-2.5,1.3-4.1,1.6c-1.6,0.3-3.4,0.3-5.4,0.1
	c-1.6-0.2-2.9-0.6-4-1.2c-2.1-1.1-3.5-2.8-4-5.1c-0.7-3,0-5.7,2.2-7.8l0,0c0,0,0.1-0.1,0.1-0.1C5,8,5,7.6,5,7.4
	c0.9,0.3,1.9,0.6,3.1,0.8c1.7,0.3,3.5,0.4,5.4,0.3c2-0.1,3.8-0.3,5.4-0.8c0.4-0.1,0.7-0.2,1.1-0.3c0,0.1,0,0.1,0,0.2
	C20,7.8,20.1,8,20.3,8.1z M1.9,10.3c-0.3,0.7-0.6,1.4-0.7,2.1c-0.2-0.2-0.3-0.6-0.3-0.9c0-0.7,0.4-1.2,0.9-1.2c0,0,0,0,0,0L1.9,10.3
	C1.9,10.3,1.9,10.3,1.9,10.3z"
                        />
                        <path
                          class="st1"
                          d="M378.3-132.8c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
	c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
	c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
	l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
	c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
	l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
	c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
	c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C313.6,110.1,357,12.7,346.6-80.7l1.4-0.6
	C366.1-88.5,378.3-109.2,378.3-132.8z M-321.6-93.6c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
	c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
	l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-309.1-146.3-317.6-120.6-321.6-93.6z M230.8,150.9c-35.5,23.6-76.2,39.8-124.6,49.4
	c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36C-245.6,132.6-287.1,79.9-304,10c-22.5-93.3,0.5-174,68.4-239.8l0.7-0.7
	c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5c2.7,1.1,5.5,2.1,8.2,3.1
	c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7c13.5-3.7,26.5-8.1,39.5-13.5
	c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3C350-2.2,308.2,99.4,230.8,150.9z M347.5-94.4
	c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1
	c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1c0,15.2-6.5,29.6-16.7,37l-0.5,0.4
	c-0.5,0.3-1,0.6-1.7,1L347.5-94.4z"
                        />
                        <path
                          class="st1"
                          d="M399.8-146.3c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
	c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
	c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
	l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
	c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
	l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
	c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
	c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C335.1,96.6,378.5-0.8,368.1-94.2l1.4-0.6
	C387.6-102,399.8-122.7,399.8-146.3z M-300.2-107.1c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
	c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
	l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-287.6-159.9-296.2-134.2-300.2-107.1z M252.2,137.4c-35.5,23.6-76.2,39.8-124.6,49.4
	c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36c-65-33.9-106.5-86.6-123.4-156.5c-22.5-93.3,0.5-174,68.4-239.8
	l0.7-0.7c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5
	c2.7,1.1,5.5,2.1,8.2,3.1c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7
	c13.5-3.7,26.5-8.1,39.5-13.5c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3
	C371.4-15.7,329.6,85.9,252.2,137.4z M368.9-107.9c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7
	c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1
	c0,15.2-6.5,29.6-16.7,37l-0.5,0.4c-0.5,0.3-1,0.6-1.7,1L368.9-107.9z"
                        />
                      </svg>
                      MATKA
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color"
                      href="/match/inplay/777"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        width="512px"
                        height="512px"
                        y="0px"
                        viewBox="0 0 25 25"
                        className="span-btn play-all pull-left h-icons"
                      >
                        <path
                          class="st18"
                          d="M21.9,3.3C22,3.4,22,3.4,22,3.5c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.5-0.2,0.8c-0.1,0.1-0.2,0.2-0.4,0.2
	c-0.5,0.3-1.1,0.5-1.8,0.7l0,0c-0.1,0-0.1,0-0.2,0.1C19.4,5.8,19.2,6,19,6.3l-0.1,0.1l0.2,0c0.8-0.2,1.6-0.5,2.5-1
	c0.4-0.2,0.7-0.5,0.9-0.7c0.2-0.3,0.3-0.6,0.2-0.8C22.5,3.6,22.3,3.4,21.9,3.3l-0.1-0.2L21.9,3.3z"
                        />
                        <path
                          class="st18"
                          d="M4.7,6L4.9,6l0-0.1c0-0.4-0.3-0.5-0.5-0.6C4.2,5.3,4,5.1,3.8,5C3.4,4.7,3.1,4.3,3,3.9l0,0c0-0.1,0-0.1,0-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.4,1,0.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
	c0,0,0-0.1,0-0.1C4.8,5,4.8,5,4.7,5c0,0,0,0,0,0C4.1,4.6,3.4,4.1,3.3,3.1l0-0.1L3.2,3c0,0-0.1,0.1-0.1,0.1C3,3.2,2.9,3.3,2.8,3.4
	C2.3,3.8,2.3,4.3,2.7,4.7C3.2,5.4,3.9,5.7,4.7,6z"
                        />
                        <path
                          class="st18"
                          d="M14.4,6.9c-0.2,0-0.3-0.1-0.3-0.2c-0.3-0.2-0.5-0.2-0.8,0c-0.1,0.1-0.3,0.1-0.4,0.2C12.8,7,12.7,7,12.6,7
	l-0.2,0.1l2-0.1V7C14.4,7,14.4,7,14.4,6.9L14.4,6.9L14.4,6.9z"
                        />
                        <path
                          class="st18"
                          d="M11,6.7C11,6.7,11,6.7,11,6.7l-0.1,0c-0.2,0.1-0.5,0.2-0.7,0.1l0,0c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2
	C9.7,6.5,9.6,6.4,9.5,6.4c-0.1,0-0.2,0-0.4,0C9.1,6.5,9,6.5,8.9,6.5c0,0,0,0,0,0C8.4,6.5,8,6.4,7.6,6.2C7.4,6.1,7.2,6.1,7.1,6.1
	c-0.1,0-0.2,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1L6.4,6.4l0.1,0c0.3,0.1,0.7,0.2,1,0.2l0.1,0C8.4,6.8,9.2,7,10,7l0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0.1,1.1,0.1c0.2,0,0.3,0,0.5,0l0.4,0L11.8,7c-0.2-0.1-0.5-0.2-0.6-0.3
	C11.1,6.6,11,6.7,11,6.7z"
                        />
                        <path
                          class="st18"
                          d="M16.2,6.5L15.6,7L17,6.8l-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.2C16.3,6.4,16.2,6.4,16.2,6.5z"
                        />
                        <path
                          class="st18"
                          d="M21.5,3.1c0,0,0.1,0,0.1,0l0.2,0L21.7,3c-0.3-0.2-0.6-0.3-0.9-0.4l-0.1,0l0,0.1C20.9,3.1,21.2,3.1,21.5,3.1z"
                        />
                        <path
                          class="st18"
                          d="M15.5,0.8c-0.1,0-0.1-0.1-0.2-0.1c-0.3,0-0.5,0.1-0.6,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
	c-0.4,0-0.7,0.1-1,0.2c-0.1-0.4-0.2-0.7-0.5-0.9c-0.2-0.2-0.6-0.3-0.9-0.2c-0.6,0.1-1,0.3-1.5,0.8c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0
	C9.8,0.7,8.7,1,8.1,1.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0C7.9,1.8,7.8,1.7,7.6,1.7C6.8,1.5,6.1,1.7,5.4,2.2c-0.1,0.1-0.1,0.1-0.2,0
	C4.9,2.2,4.7,2.2,4.4,2.2c-0.2,0-0.5,0.2-0.7,0.5C3.6,3,3.5,3.3,3.7,3.6c0.3,0.6,0.8,1,1.4,1.3c0.1,0,0.1,0.1,0.1,0.1
	c0,0,0,0.1-0.1,0.2C5,5.3,5,5.6,5.3,5.9C5.5,6.2,5.8,6.2,6,6.2c0.2,0,0.5-0.1,0.8-0.3c0.3-0.3,0.5-0.2,0.9,0C8.3,6.3,9,6.3,9.6,6
	c0,0,0,0,0.1,0l0,0c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.8,0.3c0.2-0.1,0.4-0.1,0.5-0.3c0.1,0.2,0.2,0.2,0.4,0.3
	c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.9-0.2,1.3-0.5c0.1-0.1,0.2-0.1,0.3,0c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.4,1.1-0.7
	c0.5,0.4,1,0.5,1.5,0.5c0.8-0.1,1.2-0.4,1.4-0.9C19.2,5.1,19.4,5,19.8,5c0,0,0.1,0,0.1,0c0.7-0.2,1.2-0.4,1.7-0.7
	c0.1-0.1,0.3-0.2,0.2-0.5c-0.1-0.3-0.2-0.3-0.4-0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0l-0.1,0l0,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1l0,0c0-0.2,0-0.4-0.1-0.6c-0.2-0.2-0.4-0.3-0.7-0.3l-0.1,0c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.1,0-0.2-0.1c-0.4-0.6-0.9-0.9-1.6-1.1c-0.4-0.1-0.9-0.1-1.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0.1l0-0.1l0,0l0,0l0-0.1
	l0,0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4l0,0C15.7,1,15.7,0.9,15.5,0.8 M15.8,4.1C15.8,4.1,15.8,4.1,15.8,4.1c-0.1-0.1-0.1-0.1-0.2-0.2
	c0,0-0.1-0.1,0-0.1c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.3,0.2
	c0.1,0.1,0.1,0.1,0.2,0.2l0,0C16.3,3.5,16,3.7,15.8,4.1z M16.1,4.2c0.2-0.4,0.6-0.6,1.1-0.7c0.1,0,0.1,0,0.2,0l0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l0,0c0.5,0,0.9,0.2,1.3,0.6c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.2,0.2-0.2,0.4-0.1,0.5
	c0.1,0.2,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-0.2,0.4-0.5,0.6-0.9,0.7c-0.3,0.1-0.6,0.1-0.9,0c-0.1,0-0.3-0.1-0.4-0.2
	c-0.2-0.1-0.4-0.3-0.3-0.5c0-0.3-0.1-0.6-0.2-0.8C16,4.4,16,4.3,16.1,4.2z M16.2,2.7C16.2,2.7,16.2,2.7,16.2,2.7
	C16.2,2.7,16.2,2.7,16.2,2.7L16.2,2.7C16.1,2.7,16.1,2.7,16.2,2.7C16,2.5,15.9,2.3,16,2.1c0-0.1,0.1-0.1,0.2-0.2
	C16,2.2,16,2.4,16.2,2.7z M11.4,2.8c0.4,0.3,0.9,0.4,1.3,0.4l0.1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.1-0.7,0.2-1.1,0.1h0h0c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.1-0.2l0,0
	c-0.2-0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2-0.1,0.3-0.1C11.3,2.8,11.3,2.8,11.4,2.8C11.4,2.8,11.4,2.8,11.4,2.8
	z M8.2,2.4l0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.1-0.1,0.2C8.5,3.1,8.4,3.2,8.4,3.4h0c0,0,0,0,0,0c0,0,0,0,0,0
	C8.1,3.4,8,3.4,7.8,3.4c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0C7.1,3.2,6.8,3,6.7,2.7C6.7,2.4,6.8,2.3,6.9,2.3c0.3-0.1,0.6-0.1,0.9,0
	C8,2.3,8,2.3,8.2,2.4L8.2,2.4z M9.1,2.8C9.1,2.8,9.1,2.8,9.1,2.8 M9.1,2.7C9.2,2.7,9.2,2.7,9.1,2.7c0.5-0.4,0.9-0.5,1.4-0.4
	c0.2,0.1,0.4,0.2,0.5,0.4c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.4,0.1-0.5,0.3-0.4,0.7c0,0.1,0.1,0.2,0.2,0.2
	c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.6-0.9,1-1.5,1C9.6,5,9.5,5,9.4,5C8.9,4.9,8.5,4.5,8.5,3.9
	C8.6,3.5,8.7,3.1,9.1,2.7 M11.2,5.6c0-0.1,0-0.1,0-0.2C11.5,6,12,6.1,12.5,6.2c0,0,0,0,0,0c-0.4,0.2-1.1,0-1.4-0.3
	C11.2,5.8,11.2,5.8,11.2,5.6C11.2,5.7,11.2,5.7,11.2,5.6z M11.4,4.7c0.1-0.3,0.2-0.5,0.5-0.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0,0,0,0,0.1,0.1c0.1,0.2,0.2,0.2,0.4,0.3c0.5,0.2,1,0.2,1.7,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0.3-0.2,0.5-0.5,0.7
	c-0.3,0.2-0.7,0.2-1,0.2c-0.4,0-0.8-0.1-1.2-0.4C11.4,5.2,11.3,4.9,11.4,4.7z M14.2,5.8c0.4-0.3,0.5-0.6,0.4-1.1c0-0.1,0-0.1,0-0.1
	c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.3c0.4,0.5,0.5,1,0.4,1.5c-0.1,0.4-0.5,0.7-1,0.6
	C14.7,6.2,14.4,6.1,14.2,5.8C14.1,5.8,14.2,5.8,14.2,5.8z M15.3,3.8c0,0.1-0.1,0.2-0.2,0.2c-0.6,0.2-1.6,0.3-2.3,0.1
	c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.2,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0,1.2,0.2
	l0,0C15.2,3.6,15.3,3.6,15.3,3.8z M13.9,3.2C13.9,3.2,13.9,3.2,13.9,3.2C13.9,3.1,13.9,3.1,13.9,3.2c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.2-0.1l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
	C14.1,3.2,14,3.2,13.9,3.2z M14.4,2.5L14.4,2.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.1-1.3,0.1-1.8-0.1l-0.1,0c-0.2-0.1-0.3-0.1-0.4-0.3
	c0-0.1,0.1-0.2,0.3-0.2c0,0,0.1,0,0.1,0l0,0c0.5-0.1,0.9-0.1,1.4-0.1c0.1,0,0.2,0,0.2,0L14.2,2c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1
	c0.1,0.1,0.2,0.1,0.2,0.2C14.6,2.4,14.5,2.4,14.4,2.5z M9,2.5C9,2.5,8.9,2.5,9,2.5C8.9,2.6,8.9,2.6,9,2.5C8.8,2.4,8.6,2.3,8.3,2.3
	l0,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C8.5,1.7,9,1.3,9.5,1.3c0.4-0.1,0.8,0.1,1.1,0.3c0.2,0.2,0.2,0.4,0.3,0.6
	C10.2,1.9,9.6,2,9,2.5z M5.4,5.2C5.4,5,5.4,5,5.6,5c0,0,0.1,0,0.1,0C6,5,6.3,5,6.6,4.8c0,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0s0,0,0,0
	C6.5,5.5,6.1,5.7,5.7,5.7c-0.2,0-0.3,0-0.3-0.1C5.3,5.5,5.3,5.4,5.4,5.2z M10,5.4c0.3-0.1,0.6-0.2,0.9-0.4c0.1,0.4,0.1,0.7,0,0.9
	c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3-0.1C10.2,6,10.1,5.8,9.9,5.6C9.9,5.6,9.9,5.5,10,5.4C9.9,5.5,10,5.4,10,5.4z M19.2,2.9
	c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.7,0.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-1-0.7-1.1-1.1c0-0.2,0-0.3,0.1-0.4
	c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.8,0.1,1.4,0.4,1.8,1C19.1,2.6,19.1,2.7,19.2,2.9z M15.7,1.7
	c0,0.7-0.1,1.3-0.2,1.9c-0.2-0.2-0.4-0.2-0.5-0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.6,0-1.1,0.2-1.7c0-0.1,0.1-0.2,0.2-0.3
	C15.3,1,15.3,1,15.4,1c0,0,0,0,0,0c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1l0,0 M15.6,1.2c0,0.1,0.1,0.2,0.1,0.3 M14.5,1.6L14.5,1.6
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.2,0l0,0c0,0,0,0,0,0l0,0l0,0c-0.2-0.1-0.3-0.1-0.5-0.1l-0.2,0c0,0,0,0,0,0
	c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0C14,1.5,14.2,1.5,14.5,1.6C14.5,1.5,14.5,1.5,14.5,1.6C14.5,1.5,14.6,1.5,14.5,1.6z M13.1,1
	c0.1,0.2,0.1,0.5-0.1,0.8c-0.4,0-0.8,0-1.1,0.2L11.6,2l0,0l0,0c-0.1,0.1-0.3,0.2-0.3,0.4c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.3-0.1-0.7-0.4-1c-0.1-0.1-0.1-0.1,0-0.1h0c0.5-0.6,1.1-0.8,1.7-0.7C12.8,0.6,13,0.8,13.1,1z M6.9,4.2
	C7,4,7.2,3.9,7.5,3.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.2
	c0,0.1,0,0.1,0,0.2C8.4,4.8,9,5.4,9.5,5.4c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1C9.4,5.9,9.1,5.9,8.7,6C7.9,5.9,7.4,5.6,7,5
	C6.8,4.8,6.8,4.5,6.9,4.2z M19.9,3.8l0.6-0.1 M19.9,3.8 M20.5,3.7c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l0,0l0,0
	c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0.1-0.1,0.1C21.2,3.9,21,4,20.9,4c-0.7,0.4-1.4,0.5-2,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2-0.1
	c0-0.1,0-0.1,0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0l0.1,0l0,0l0,0v0c0,0,0.1,0,0.1-0.1
	C19.3,4,19.5,4,19.7,3.9c0,0,0.1,0,0.1,0l0,0l0,0 M19.9,2.7c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.3,0.6
	c0,0,0,0,0,0l0,0v0l0,0c0,0-0.1,0-0.1,0l-0.1,0c-0.2,0-0.4,0.1-0.5,0.2l-0.1,0c0,0-0.1,0-0.1-0.1c0.2-0.1,0.4-0.3,0.5-0.5
	c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.1c0,0,0,0,0.1,0C19.6,2.7,19.7,2.7,19.9,2.7z M7.9,2.1C7.6,2,7.3,2,7,2
	c-0.3,0-0.5,0.2-0.6,0.4C6.4,2.6,6.3,2.8,6.4,3C6.1,2.7,5.9,2.6,5.6,2.4C6,2,6.7,1.8,7.3,1.9C7.5,1.9,7.7,1.9,7.9,2.1z M6.6,4.2
	C6.5,4.4,6.3,4.5,6,4.5c-0.7,0-1.7-0.6-2-1.2C4,3.1,4,2.9,4.1,2.7c0.1-0.2,0.4-0.3,0.7-0.3c0.8,0.1,1.4,0.5,1.8,1.2
	C6.7,3.8,6.7,4,6.6,4.2z"
                        />
                        <path
                          class="st18"
                          d="M24.9,11.5c0-1.1-0.7-2-1.7-2c-0.2,0-0.4,0-0.6,0.1c-0.5-0.8-1.1-1.5-1.9-2.1c0-0.2,0-0.3,0-0.5l0-0.9l-0.6,0.2
	c-0.5,0.2-1,0.4-1.5,0.5c-1.6,0.4-3.3,0.7-5.3,0.7c-1.9,0.1-3.6,0-5.2-0.3c-1.2-0.2-2.1-0.4-3-0.8L4.4,6.3L4.2,6.7
	c-0.1,0.2-0.1,0.5,0,0.7l0,0.1c0,0.2,0,0.2-0.1,0.2C4,7.7,3.9,7.7,3.8,7.8C3.2,8.4,2.7,9,2.4,9.6C2.2,9.5,2,9.5,1.8,9.5
	c-0.9,0-1.7,0.9-1.7,2c0,0.8,0.4,1.5,1,1.8c-0.1,1,0,2,0.3,3.1C2,18.9,3.5,20.8,5.8,22c0.3,0.1,0.5,0.3,0.8,0.4
	c-0.3,1.8-0.1,2,0.1,2.1l0.1,0c0.2,0,0.4-0.1,1-1.6c0.7,0.2,1.4,0.3,2.2,0.5c2.2,0.3,4,0.3,5.7-0.1c0.4-0.1,0.8-0.2,1.1-0.3
	c0.6,1.6,0.8,1.7,1,1.7l0.1,0c0.2-0.1,0.4-0.3,0.1-2.1c0.7-0.3,1.4-0.6,2-1c2.5-1.7,4-4.9,3.8-8.1C24.5,13.1,24.9,12.3,24.9,11.5z
	 M23.1,10.3L23.1,10.3c0.5-0.1,1,0.5,1,1.2c0,0.4-0.1,0.8-0.4,1C23.6,11.7,23.3,11,23.1,10.3C23,10.3,23.1,10.3,23.1,10.3z
	 M20.3,8.1c1.5,1.1,2.4,2.7,2.7,4.6c0.6,3.1-0.8,6.4-3.3,8c-1.2,0.8-2.5,1.3-4.1,1.6c-1.6,0.3-3.4,0.3-5.4,0.1
	c-1.6-0.2-2.9-0.6-4-1.2c-2.1-1.1-3.5-2.8-4-5.1c-0.7-3,0-5.7,2.2-7.8l0,0c0,0,0.1-0.1,0.1-0.1C5,8,5,7.6,5,7.4
	c0.9,0.3,1.9,0.6,3.1,0.8c1.7,0.3,3.5,0.4,5.4,0.3c2-0.1,3.8-0.3,5.4-0.8c0.4-0.1,0.7-0.2,1.1-0.3c0,0.1,0,0.1,0,0.2
	C20,7.8,20.1,8,20.3,8.1z M1.9,10.3c-0.3,0.7-0.6,1.4-0.7,2.1c-0.2-0.2-0.3-0.6-0.3-0.9c0-0.7,0.4-1.2,0.9-1.2c0,0,0,0,0,0L1.9,10.3
	C1.9,10.3,1.9,10.3,1.9,10.3z"
                        />
                        <path
                          class="st1"
                          d="M378.3-132.8c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
	c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
	c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
	l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
	c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
	l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
	c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
	c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C313.6,110.1,357,12.7,346.6-80.7l1.4-0.6
	C366.1-88.5,378.3-109.2,378.3-132.8z M-321.6-93.6c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
	c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
	l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-309.1-146.3-317.6-120.6-321.6-93.6z M230.8,150.9c-35.5,23.6-76.2,39.8-124.6,49.4
	c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36C-245.6,132.6-287.1,79.9-304,10c-22.5-93.3,0.5-174,68.4-239.8l0.7-0.7
	c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5c2.7,1.1,5.5,2.1,8.2,3.1
	c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7c13.5-3.7,26.5-8.1,39.5-13.5
	c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3C350-2.2,308.2,99.4,230.8,150.9z M347.5-94.4
	c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1
	c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1c0,15.2-6.5,29.6-16.7,37l-0.5,0.4
	c-0.5,0.3-1,0.6-1.7,1L347.5-94.4z"
                        />
                        <path
                          class="st1"
                          d="M399.8-146.3c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
	c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
	c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
	l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
	c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
	l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
	c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
	c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C335.1,96.6,378.5-0.8,368.1-94.2l1.4-0.6
	C387.6-102,399.8-122.7,399.8-146.3z M-300.2-107.1c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
	c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
	l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-287.6-159.9-296.2-134.2-300.2-107.1z M252.2,137.4c-35.5,23.6-76.2,39.8-124.6,49.4
	c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36c-65-33.9-106.5-86.6-123.4-156.5c-22.5-93.3,0.5-174,68.4-239.8
	l0.7-0.7c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5
	c2.7,1.1,5.5,2.1,8.2,3.1c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7
	c13.5-3.7,26.5-8.1,39.5-13.5c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3
	C371.4-15.7,329.6,85.9,252.2,137.4z M368.9-107.9c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7
	c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1
	c0,15.2-6.5,29.6-16.7,37l-0.5,0.4c-0.5,0.3-1,0.6-1.7,1L368.9-107.9z"
                        />
                      </svg>
                      FAST MATKA
                    </a>
                  </div>

                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
                <div className="row" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a className="card card-1 card-color" href="/ledger">
                      <svg
                        className="span-btn play-all pull-left h-icons"
                        x="0px"
                        y="0px"
                        viewBox="0 0 480 480"
                        style={{ enableBackground: "new 0 0 480 480" }}
                        // xml:space="preserve"
                        width="512px"
                        height="512px"
                      >
                        <g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M456,208H296c-13.255,0-24,10.745-24,24v224c0,13.255,10.745,24,24,24h160c13.255,0,24-10.745,24-24V232 C480,218.745,469.255,208,456,208z M368,464h-72c-4.418,0-8-3.582-8-8v-56h80V464z M368,384h-80v-64h80V384z M464,456 c0,4.418-3.582,8-8,8h-72v-64h80V456z M464,384h-80v-64h80V384z M464,304H288v-72c0-4.418,3.582-8,8-8h160c4.418,0,8,3.582,8,8 V304z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M440,240H312c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h128c4.418,0,8-3.582,8-8v-32C448,243.582,444.418,240,440,240z M432,272H320v-16h112V272z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <polygon
                                points="336,344 336,328 320,328 320,344 304,344 304,360 320,360 320,376 336,376 336,360 352,360 352,344 "
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></polygon>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="400"
                                y="344"
                                width="48"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <polygon
                                points="350.624,421.656 339.312,410.344 328,421.656 316.688,410.344 305.376,421.656 316.688,432.968 305.376,444.28 316.688,455.592 328,444.28 339.312,455.592 350.624,444.28 339.312,432.968 "
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></polygon>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="400"
                                y="424"
                                width="48"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="416"
                                y="444"
                                width="16"
                                height="8"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="416"
                                y="412"
                                width="16"
                                height="8"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M320,0H88v0.072c-2.112-0.025-4.148,0.793-5.656,2.272l-80,80C0.863,83.851,0.045,85.887,0.072,88H0v376 c0,8.837,7.163,16,16,16h240v-16h-48v-24h16c4.418,0,8-3.582,8-8v-40c0-4.418-3.582-8-8-8h-16v-24h16c4.418,0,8-3.582,8-8v-40 c0-4.418-3.582-8-8-8h-0.208c20.243-15.048,32.184-38.777,32.208-64c0.086-44.183-35.662-80.069-79.844-80.155 S96.086,195.507,96,239.689c-0.049,25.327,11.897,49.181,32.208,64.311H64c-4.418,0-8,3.582-8,8v32H40c-4.418,0-8,3.582-8,8v40 c0,4.418,3.582,8,8,8h16v24H40c-4.418,0-8,3.582-8,8v32H16V96h64c8.837,0,16-7.163,16-16V16h224v176h16V16 C336,7.163,328.837,0,320,0z M216,400v24H72v-24H216z M112,240c0-35.346,28.654-64,64-64c35.346,0,64,28.654,64,64 c0,35.346-28.654,64-64,64C140.67,303.96,112.04,275.33,112,240z M72,320h144v24H72V320z M48,384v-24h144v24H64H48z M48,440h144 v24H48V440z M80,80H27.312L80,27.312V80z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M176,216h24v-16h-16v-8h-16v9.472c-12.473,4.393-19.023,18.066-14.629,30.539c3.38,9.596,12.455,16.009,22.629,15.989 c4.418,0,8,3.582,8,8s-3.582,8-8,8h-24v16h16v8h16v-9.472c12.473-4.393,19.023-18.066,14.629-30.539 c-3.38-9.596-12.455-16.009-22.629-15.989c-4.418,0-8-3.582-8-8S171.582,216,176,216z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M448,40h-16c0-4.418-3.582-8-8-8h-8V8c0-4.418-3.582-8-8-8h-32c-4.418,0-8,3.582-8,8v24h-8c-4.418,0-8,3.582-8,8v152h16 V48h48v144h16V56h16v104h16V56C464,47.163,456.837,40,448,40z M400,32h-16V16h16V32z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="120"
                                y="64"
                                width="136"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="32"
                                y="112"
                                width="272"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="32"
                                y="144"
                                width="96"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="232"
                                y="144"
                                width="72"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                        </g>{" "}
                      </svg>
                      LEDGER
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color"
                      href="/account-statement"
                    >
                      <svg
                        className="span-btn play-all pull-left h-icons"
                        x="0px"
                        y="0px"
                        viewBox="0 0 480 480"
                        style={{ enableBackground: "new 0 0 480 480" }}
                        // xml:space="preserve"
                        width="512px"
                        height="512px"
                      >
                        <g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M456,208H296c-13.255,0-24,10.745-24,24v224c0,13.255,10.745,24,24,24h160c13.255,0,24-10.745,24-24V232 C480,218.745,469.255,208,456,208z M368,464h-72c-4.418,0-8-3.582-8-8v-56h80V464z M368,384h-80v-64h80V384z M464,456 c0,4.418-3.582,8-8,8h-72v-64h80V456z M464,384h-80v-64h80V384z M464,304H288v-72c0-4.418,3.582-8,8-8h160c4.418,0,8,3.582,8,8 V304z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M440,240H312c-4.418,0-8,3.582-8,8v32c0,4.418,3.582,8,8,8h128c4.418,0,8-3.582,8-8v-32C448,243.582,444.418,240,440,240z M432,272H320v-16h112V272z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <polygon
                                points="336,344 336,328 320,328 320,344 304,344 304,360 320,360 320,376 336,376 336,360 352,360 352,344 "
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></polygon>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="400"
                                y="344"
                                width="48"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <polygon
                                points="350.624,421.656 339.312,410.344 328,421.656 316.688,410.344 305.376,421.656 316.688,432.968 305.376,444.28 316.688,455.592 328,444.28 339.312,455.592 350.624,444.28 339.312,432.968 "
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></polygon>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="400"
                                y="424"
                                width="48"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="416"
                                y="444"
                                width="16"
                                height="8"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="416"
                                y="412"
                                width="16"
                                height="8"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M320,0H88v0.072c-2.112-0.025-4.148,0.793-5.656,2.272l-80,80C0.863,83.851,0.045,85.887,0.072,88H0v376 c0,8.837,7.163,16,16,16h240v-16h-48v-24h16c4.418,0,8-3.582,8-8v-40c0-4.418-3.582-8-8-8h-16v-24h16c4.418,0,8-3.582,8-8v-40 c0-4.418-3.582-8-8-8h-0.208c20.243-15.048,32.184-38.777,32.208-64c0.086-44.183-35.662-80.069-79.844-80.155 S96.086,195.507,96,239.689c-0.049,25.327,11.897,49.181,32.208,64.311H64c-4.418,0-8,3.582-8,8v32H40c-4.418,0-8,3.582-8,8v40 c0,4.418,3.582,8,8,8h16v24H40c-4.418,0-8,3.582-8,8v32H16V96h64c8.837,0,16-7.163,16-16V16h224v176h16V16 C336,7.163,328.837,0,320,0z M216,400v24H72v-24H216z M112,240c0-35.346,28.654-64,64-64c35.346,0,64,28.654,64,64 c0,35.346-28.654,64-64,64C140.67,303.96,112.04,275.33,112,240z M72,320h144v24H72V320z M48,384v-24h144v24H64H48z M48,440h144 v24H48V440z M80,80H27.312L80,27.312V80z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M176,216h24v-16h-16v-8h-16v9.472c-12.473,4.393-19.023,18.066-14.629,30.539c3.38,9.596,12.455,16.009,22.629,15.989 c4.418,0,8,3.582,8,8s-3.582,8-8,8h-24v16h16v8h16v-9.472c12.473-4.393,19.023-18.066,14.629-30.539 c-3.38-9.596-12.455-16.009-22.629-15.989c-4.418,0-8-3.582-8-8S171.582,216,176,216z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M448,40h-16c0-4.418-3.582-8-8-8h-8V8c0-4.418-3.582-8-8-8h-32c-4.418,0-8,3.582-8,8v24h-8c-4.418,0-8,3.582-8,8v152h16 V48h48v144h16V56h16v104h16V56C464,47.163,456.837,40,448,40z M400,32h-16V16h16V32z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="120"
                                y="64"
                                width="136"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="32"
                                y="112"
                                width="272"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="32"
                                y="144"
                                width="96"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <rect
                                x="232"
                                y="144"
                                width="72"
                                height="16"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></rect>{" "}
                            </g>{" "}
                          </g>
                        </g>{" "}
                      </svg>
                      STATEMENT
                    </a>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
                <div className="row match-box" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color"
                      href="/change_password"
                    >
                      <svg
                        className="span-btn play-all pull-left h-icons"
                        x="0px"
                        y="0px"
                        viewBox="0 0 486.025 486.025"
                        style={{ enableBackground: "new 0 0 486.025 486.025" }}
                        // xml:space="preserve"
                        width="512px"
                        height="512px"
                      >
                        <g>
                          <g>
                            {" "}
                            <g>
                              {" "}
                              <path
                                d="M420.725,85.413c-42.1-42.1-98.1-65.3-157.6-65.3c-60.6,0-117.3,23.9-159.6,67.3c-4.6,4.7-4.5,12.3,0.2,17 c4.7,4.6,12.3,4.5,17-0.2c37.8-38.7,88.3-60,142.4-60c109.7-0.1,198.9,89.1,198.9,198.8s-89.2,198.9-198.9,198.9 s-198.9-89.2-198.9-198.9v-2.5l19.8,19.8c2.3,2.3,5.4,3.5,8.5,3.5s6.1-1.2,8.5-3.5c4.7-4.7,4.7-12.3,0-17l-40.2-40.3 c-4.7-4.7-12.3-4.7-17,0l-40.3,40.3c-4.7,4.7-4.7,12.3,0,17c2.3,2.3,5.4,3.5,8.5,3.5s6.1-1.2,8.5-3.5l19.8-19.8v2.5 c0,59.5,23.2,115.5,65.3,157.6s98.1,65.3,157.6,65.3s115.5-23.2,157.6-65.3s65.2-98.1,65.2-157.6S462.825,127.513,420.725,85.413z "
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                              <path
                                d="M263.125,113.413c-39,0-70.7,31.7-70.7,70.7v34.1h-22c-6.6,0-12,5.4-12,12v119.7c0,6.6,5.4,12,12,12h185.5 c6.6,0,12-5.4,12-12v-119.6c0-6.6-5.4-12-12-12h-22v-34.1C333.925,145.213,302.125,113.413,263.125,113.413z M216.425,184.213 c0-25.8,21-46.7,46.7-46.7s46.7,21,46.7,46.7v34.1h-93.4L216.425,184.213L216.425,184.213z M343.925,337.913h-161.5v-95.7h161.5 V337.913z"
                                data-original="#000000"
                                className="active-path"
                                data-old_color="#000000"
                                fill="#FFFFFF"
                              ></path>{" "}
                            </g>{" "}
                          </g>
                        </g>{" "}
                      </svg>
                      PASSWORD
                    </a>
                  </div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a className="card card-1 card-color" href="/rules">
                      <svg
                        className="span-btn play-all pull-left h-icons"
                        x="0px"
                        y="0px"
                        viewBox="0 0 330 330"
                        style={{ enableBackground: "new 0 0 330 330" }}
                        // xml:space="preserve"
                        width="512px"
                        height="512px"
                      >
                        <g>
                          <g>
                            {" "}
                            <path
                              d="M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z"
                              data-original="#000000"
                              className="active-path"
                              data-old_color="#000000"
                              fill="#FFFFFF"
                            ></path>{" "}
                            <path
                              d="M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991 c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z"
                              data-original="#000000"
                              className="active-path"
                              data-old_color="#000000"
                              fill="#FFFFFF"
                            ></path>{" "}
                            <path
                              d="M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z "
                              data-original="#000000"
                              className="active-path"
                              data-old_color="#000000"
                              fill="#FFFFFF"
                            ></path>{" "}
                          </g>
                        </g>{" "}
                      </svg>
                      RULES
                    </a>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
              </>
            ) : (
              <>
                <div className="row  match-box" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color active dashboard-svg"
                      href="/match/inplay/4"
                    >
                      {svgImage.cricket}
                      CRICKET
                    </a>
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/1"
                    >
                      {svgImage.football}
                      FOOTBALL
                    </a>
                  </div>

                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
                <div className="row match-box" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/999"
                    >
                      {/* <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        width="512px"
                        height="512px"
                        y="0px"
                        viewBox="0 0 25 25"
                        className="span-btn play-all pull-left h-icons"
                      >
                        <path
                          class="st18"
                          d="M21.9,3.3C22,3.4,22,3.4,22,3.5c0,0,0,0.1,0,0.1c0.1,0.3,0.1,0.5-0.2,0.8c-0.1,0.1-0.2,0.2-0.4,0.2
 c-0.5,0.3-1.1,0.5-1.8,0.7l0,0c-0.1,0-0.1,0-0.2,0.1C19.4,5.8,19.2,6,19,6.3l-0.1,0.1l0.2,0c0.8-0.2,1.6-0.5,2.5-1
 c0.4-0.2,0.7-0.5,0.9-0.7c0.2-0.3,0.3-0.6,0.2-0.8C22.5,3.6,22.3,3.4,21.9,3.3l-0.1-0.2L21.9,3.3z"
                        />
                        <path
                          class="st18"
                          d="M4.7,6L4.9,6l0-0.1c0-0.4-0.3-0.5-0.5-0.6C4.2,5.3,4,5.1,3.8,5C3.4,4.7,3.1,4.3,3,3.9l0,0c0-0.1,0-0.1,0-0.1
 c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0c0.1,0.3,0.3,0.5,0.6,0.7c0.3,0.2,0.6,0.4,1,0.6c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1
 c0,0,0-0.1,0-0.1C4.8,5,4.8,5,4.7,5c0,0,0,0,0,0C4.1,4.6,3.4,4.1,3.3,3.1l0-0.1L3.2,3c0,0-0.1,0.1-0.1,0.1C3,3.2,2.9,3.3,2.8,3.4
 C2.3,3.8,2.3,4.3,2.7,4.7C3.2,5.4,3.9,5.7,4.7,6z"
                        />
                        <path
                          class="st18"
                          d="M14.4,6.9c-0.2,0-0.3-0.1-0.3-0.2c-0.3-0.2-0.5-0.2-0.8,0c-0.1,0.1-0.3,0.1-0.4,0.2C12.8,7,12.7,7,12.6,7
 l-0.2,0.1l2-0.1V7C14.4,7,14.4,7,14.4,6.9L14.4,6.9L14.4,6.9z"
                        />
                        <path
                          class="st18"
                          d="M11,6.7C11,6.7,11,6.7,11,6.7l-0.1,0c-0.2,0.1-0.5,0.2-0.7,0.1l0,0c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.2
 C9.7,6.5,9.6,6.4,9.5,6.4c-0.1,0-0.2,0-0.4,0C9.1,6.5,9,6.5,8.9,6.5c0,0,0,0,0,0C8.4,6.5,8,6.4,7.6,6.2C7.4,6.1,7.2,6.1,7.1,6.1
 c-0.1,0-0.2,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1L6.4,6.4l0.1,0c0.3,0.1,0.7,0.2,1,0.2l0.1,0C8.4,6.8,9.2,7,10,7l0,0c0,0,0,0,0,0
 c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0.1,1.1,0.1c0.2,0,0.3,0,0.5,0l0.4,0L11.8,7c-0.2-0.1-0.5-0.2-0.6-0.3
 C11.1,6.6,11,6.7,11,6.7z"
                        />
                        <path
                          class="st18"
                          d="M16.2,6.5L15.6,7L17,6.8l-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.2C16.3,6.4,16.2,6.4,16.2,6.5z"
                        />
                        <path
                          class="st18"
                          d="M21.5,3.1c0,0,0.1,0,0.1,0l0.2,0L21.7,3c-0.3-0.2-0.6-0.3-0.9-0.4l-0.1,0l0,0.1C20.9,3.1,21.2,3.1,21.5,3.1z"
                        />
                        <path
                          class="st18"
                          d="M15.5,0.8c-0.1,0-0.1-0.1-0.2-0.1c-0.3,0-0.5,0.1-0.6,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0
 c-0.4,0-0.7,0.1-1,0.2c-0.1-0.4-0.2-0.7-0.5-0.9c-0.2-0.2-0.6-0.3-0.9-0.2c-0.6,0.1-1,0.3-1.5,0.8c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0
 C9.8,0.7,8.7,1,8.1,1.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0C7.9,1.8,7.8,1.7,7.6,1.7C6.8,1.5,6.1,1.7,5.4,2.2c-0.1,0.1-0.1,0.1-0.2,0
 C4.9,2.2,4.7,2.2,4.4,2.2c-0.2,0-0.5,0.2-0.7,0.5C3.6,3,3.5,3.3,3.7,3.6c0.3,0.6,0.8,1,1.4,1.3c0.1,0,0.1,0.1,0.1,0.1
 c0,0,0,0.1-0.1,0.2C5,5.3,5,5.6,5.3,5.9C5.5,6.2,5.8,6.2,6,6.2c0.2,0,0.5-0.1,0.8-0.3c0.3-0.3,0.5-0.2,0.9,0C8.3,6.3,9,6.3,9.6,6
 c0,0,0,0,0.1,0l0,0c0,0.1,0.1,0.1,0.2,0.2c0.2,0.2,0.4,0.4,0.8,0.3c0.2-0.1,0.4-0.1,0.5-0.3c0.1,0.2,0.2,0.2,0.4,0.3
 c0.2,0.1,0.5,0.2,0.8,0.2c0.4,0,0.9-0.2,1.3-0.5c0.1-0.1,0.2-0.1,0.3,0c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.4,1.1-0.7
 c0.5,0.4,1,0.5,1.5,0.5c0.8-0.1,1.2-0.4,1.4-0.9C19.2,5.1,19.4,5,19.8,5c0,0,0.1,0,0.1,0c0.7-0.2,1.2-0.4,1.7-0.7
 c0.1-0.1,0.3-0.2,0.2-0.5c-0.1-0.3-0.2-0.3-0.4-0.3c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0l-0.1,0l0,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0
 c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1l0,0c0-0.2,0-0.4-0.1-0.6c-0.2-0.2-0.4-0.3-0.7-0.3l-0.1,0c-0.1,0-0.2,0-0.3,0.1
 c-0.1,0-0.1,0-0.2-0.1c-0.4-0.6-0.9-0.9-1.6-1.1c-0.4-0.1-0.9-0.1-1.3,0.3c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0.1l0-0.1l0,0l0,0l0-0.1
 l0,0c0,0,0,0,0,0c0-0.1,0-0.3-0.1-0.4l0,0C15.7,1,15.7,0.9,15.5,0.8 M15.8,4.1C15.8,4.1,15.8,4.1,15.8,4.1c-0.1-0.1-0.1-0.1-0.2-0.2
 c0,0-0.1-0.1,0-0.1c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0.1-0.2,0.2-0.2l0,0c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.3,0.2
 c0.1,0.1,0.1,0.1,0.2,0.2l0,0C16.3,3.5,16,3.7,15.8,4.1z M16.1,4.2c0.2-0.4,0.6-0.6,1.1-0.7c0.1,0,0.1,0,0.2,0l0,0c0,0,0,0,0,0
 c0,0,0,0,0,0l0,0c0.5,0,0.9,0.2,1.3,0.6c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c-0.2,0.2-0.2,0.4-0.1,0.5
 c0.1,0.2,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-0.2,0.4-0.5,0.6-0.9,0.7c-0.3,0.1-0.6,0.1-0.9,0c-0.1,0-0.3-0.1-0.4-0.2
 c-0.2-0.1-0.4-0.3-0.3-0.5c0-0.3-0.1-0.6-0.2-0.8C16,4.4,16,4.3,16.1,4.2z M16.2,2.7C16.2,2.7,16.2,2.7,16.2,2.7
 C16.2,2.7,16.2,2.7,16.2,2.7L16.2,2.7C16.1,2.7,16.1,2.7,16.2,2.7C16,2.5,15.9,2.3,16,2.1c0-0.1,0.1-0.1,0.2-0.2
 C16,2.2,16,2.4,16.2,2.7z M11.4,2.8c0.4,0.3,0.9,0.4,1.3,0.4l0.1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0
 c0,0,0,0,0,0.1l0,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.1-0.7,0.2-1.1,0.1h0h0c0,0-0.1,0-0.1,0c-0.4,0-0.8-0.1-1.1-0.2l0,0
 c-0.2-0.1-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.3-0.2l0,0c0.1,0,0.2-0.1,0.3-0.1C11.3,2.8,11.3,2.8,11.4,2.8C11.4,2.8,11.4,2.8,11.4,2.8
 z M8.2,2.4l0.1,0.1c0.2,0.1,0.3,0.2,0.5,0.3c0,0.1-0.1,0.1-0.1,0.2C8.5,3.1,8.4,3.2,8.4,3.4h0c0,0,0,0,0,0c0,0,0,0,0,0
 C8.1,3.4,8,3.4,7.8,3.4c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0C7.1,3.2,6.8,3,6.7,2.7C6.7,2.4,6.8,2.3,6.9,2.3c0.3-0.1,0.6-0.1,0.9,0
 C8,2.3,8,2.3,8.2,2.4L8.2,2.4z M9.1,2.8C9.1,2.8,9.1,2.8,9.1,2.8 M9.1,2.7C9.2,2.7,9.2,2.7,9.1,2.7c0.5-0.4,0.9-0.5,1.4-0.4
 c0.2,0.1,0.4,0.2,0.5,0.4c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.4,0.1-0.5,0.3-0.4,0.7c0,0.1,0.1,0.2,0.2,0.2
 c0.2,0.1,0.4,0.2,0.6,0.2l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.6-0.9,1-1.5,1C9.6,5,9.5,5,9.4,5C8.9,4.9,8.5,4.5,8.5,3.9
 C8.6,3.5,8.7,3.1,9.1,2.7 M11.2,5.6c0-0.1,0-0.1,0-0.2C11.5,6,12,6.1,12.5,6.2c0,0,0,0,0,0c-0.4,0.2-1.1,0-1.4-0.3
 C11.2,5.8,11.2,5.8,11.2,5.6C11.2,5.7,11.2,5.7,11.2,5.6z M11.4,4.7c0.1-0.3,0.2-0.5,0.5-0.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0
 c0,0,0,0,0.1,0.1c0.1,0.2,0.2,0.2,0.4,0.3c0.5,0.2,1,0.2,1.7,0.2c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0,0.3-0.2,0.5-0.5,0.7
 c-0.3,0.2-0.7,0.2-1,0.2c-0.4,0-0.8-0.1-1.2-0.4C11.4,5.2,11.3,4.9,11.4,4.7z M14.2,5.8c0.4-0.3,0.5-0.6,0.4-1.1c0-0.1,0-0.1,0-0.1
 c0,0,0,0,0.1,0c0.2,0,0.4-0.1,0.6-0.2c0.1,0,0.2-0.1,0.3-0.3c0.4,0.5,0.5,1,0.4,1.5c-0.1,0.4-0.5,0.7-1,0.6
 C14.7,6.2,14.4,6.1,14.2,5.8C14.1,5.8,14.2,5.8,14.2,5.8z M15.3,3.8c0,0.1-0.1,0.2-0.2,0.2c-0.6,0.2-1.6,0.3-2.3,0.1
 c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.3-0.2,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0,0.8,0,1.2,0.2
 l0,0C15.2,3.6,15.3,3.6,15.3,3.8z M13.9,3.2C13.9,3.2,13.9,3.2,13.9,3.2C13.9,3.1,13.9,3.1,13.9,3.2c0.1,0,0.1,0,0.2,0
 c0.1,0,0.1,0,0.2-0.1l0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0
 C14.1,3.2,14,3.2,13.9,3.2z M14.4,2.5L14.4,2.5c-0.2,0.1-0.4,0.1-0.6,0.2c-0.7,0.1-1.3,0.1-1.8-0.1l-0.1,0c-0.2-0.1-0.3-0.1-0.4-0.3
 c0-0.1,0.1-0.2,0.3-0.2c0,0,0.1,0,0.1,0l0,0c0.5-0.1,0.9-0.1,1.4-0.1c0.1,0,0.2,0,0.2,0L14.2,2c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0.1
 c0.1,0.1,0.2,0.1,0.2,0.2C14.6,2.4,14.5,2.4,14.4,2.5z M9,2.5C9,2.5,8.9,2.5,9,2.5C8.9,2.6,8.9,2.6,9,2.5C8.8,2.4,8.6,2.3,8.3,2.3
 l0,0l-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C8.5,1.7,9,1.3,9.5,1.3c0.4-0.1,0.8,0.1,1.1,0.3c0.2,0.2,0.2,0.4,0.3,0.6
 C10.2,1.9,9.6,2,9,2.5z M5.4,5.2C5.4,5,5.4,5,5.6,5c0,0,0.1,0,0.1,0C6,5,6.3,5,6.6,4.8c0,0.2,0.1,0.3,0.2,0.5c0,0,0,0,0,0s0,0,0,0
 C6.5,5.5,6.1,5.7,5.7,5.7c-0.2,0-0.3,0-0.3-0.1C5.3,5.5,5.3,5.4,5.4,5.2z M10,5.4c0.3-0.1,0.6-0.2,0.9-0.4c0.1,0.4,0.1,0.7,0,0.9
 c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.2,0-0.3-0.1C10.2,6,10.1,5.8,9.9,5.6C9.9,5.6,9.9,5.5,10,5.4C9.9,5.5,10,5.4,10,5.4z M19.2,2.9
 c0.1,0.2,0,0.5-0.2,0.6c-0.2,0.1-0.4,0.2-0.7,0.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.4-0.1-1-0.7-1.1-1.1c0-0.2,0-0.3,0.1-0.4
 c0.1-0.1,0.2-0.2,0.4-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.8,0.1,1.4,0.4,1.8,1C19.1,2.6,19.1,2.7,19.2,2.9z M15.7,1.7
 c0,0.7-0.1,1.3-0.2,1.9c-0.2-0.2-0.4-0.2-0.5-0.3c-0.1,0-0.1-0.1-0.1-0.1c0-0.6,0-1.1,0.2-1.7c0-0.1,0.1-0.2,0.2-0.3
 C15.3,1,15.3,1,15.4,1c0,0,0,0,0,0c0,0,0.1,0,0.2,0.1c0,0,0,0,0,0.1l0,0 M15.6,1.2c0,0.1,0.1,0.2,0.1,0.3 M14.5,1.6L14.5,1.6
 c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.2,0l0,0c0,0,0,0,0,0l0,0l0,0c-0.2-0.1-0.3-0.1-0.5-0.1l-0.2,0c0,0,0,0,0,0
 c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0,0C14,1.5,14.2,1.5,14.5,1.6C14.5,1.5,14.5,1.5,14.5,1.6C14.5,1.5,14.6,1.5,14.5,1.6z M13.1,1
 c0.1,0.2,0.1,0.5-0.1,0.8c-0.4,0-0.8,0-1.1,0.2L11.6,2l0,0l0,0c-0.1,0.1-0.3,0.2-0.3,0.4c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0
 c0,0,0,0,0,0c0-0.3-0.1-0.7-0.4-1c-0.1-0.1-0.1-0.1,0-0.1h0c0.5-0.6,1.1-0.8,1.7-0.7C12.8,0.6,13,0.8,13.1,1z M6.9,4.2
 C7,4,7.2,3.9,7.5,3.8c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.1,0.1,0.1,0.2
 c0,0.1,0,0.1,0,0.2C8.4,4.8,9,5.4,9.5,5.4c0.1,0,0.1,0,0.1,0c0,0,0,0,0,0.1C9.4,5.9,9.1,5.9,8.7,6C7.9,5.9,7.4,5.6,7,5
 C6.8,4.8,6.8,4.5,6.9,4.2z M19.9,3.8l0.6-0.1 M19.9,3.8 M20.5,3.7c0.2,0,0.4-0.1,0.6-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l0,0l0,0
 c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0.1-0.1,0.1C21.2,3.9,21,4,20.9,4c-0.7,0.4-1.4,0.5-2,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.2-0.1
 c0-0.1,0-0.1,0.2-0.2c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0l0,0l0.1,0l0,0l0,0v0c0,0,0.1,0,0.1-0.1
 C19.3,4,19.5,4,19.7,3.9c0,0,0.1,0,0.1,0l0,0l0,0 M19.9,2.7c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3-0.2,0.5-0.3,0.6
 c0,0,0,0,0,0l0,0v0l0,0c0,0-0.1,0-0.1,0l-0.1,0c-0.2,0-0.4,0.1-0.5,0.2l-0.1,0c0,0-0.1,0-0.1-0.1c0.2-0.1,0.4-0.3,0.5-0.5
 c0.1-0.2,0.1-0.5,0.1-0.7c0-0.1,0-0.1,0-0.1c0,0,0,0,0.1,0C19.6,2.7,19.7,2.7,19.9,2.7z M7.9,2.1C7.6,2,7.3,2,7,2
 c-0.3,0-0.5,0.2-0.6,0.4C6.4,2.6,6.3,2.8,6.4,3C6.1,2.7,5.9,2.6,5.6,2.4C6,2,6.7,1.8,7.3,1.9C7.5,1.9,7.7,1.9,7.9,2.1z M6.6,4.2
 C6.5,4.4,6.3,4.5,6,4.5c-0.7,0-1.7-0.6-2-1.2C4,3.1,4,2.9,4.1,2.7c0.1-0.2,0.4-0.3,0.7-0.3c0.8,0.1,1.4,0.5,1.8,1.2
 C6.7,3.8,6.7,4,6.6,4.2z"
                        />
                        <path
                          class="st18"
                          d="M24.9,11.5c0-1.1-0.7-2-1.7-2c-0.2,0-0.4,0-0.6,0.1c-0.5-0.8-1.1-1.5-1.9-2.1c0-0.2,0-0.3,0-0.5l0-0.9l-0.6,0.2
 c-0.5,0.2-1,0.4-1.5,0.5c-1.6,0.4-3.3,0.7-5.3,0.7c-1.9,0.1-3.6,0-5.2-0.3c-1.2-0.2-2.1-0.4-3-0.8L4.4,6.3L4.2,6.7
 c-0.1,0.2-0.1,0.5,0,0.7l0,0.1c0,0.2,0,0.2-0.1,0.2C4,7.7,3.9,7.7,3.8,7.8C3.2,8.4,2.7,9,2.4,9.6C2.2,9.5,2,9.5,1.8,9.5
 c-0.9,0-1.7,0.9-1.7,2c0,0.8,0.4,1.5,1,1.8c-0.1,1,0,2,0.3,3.1C2,18.9,3.5,20.8,5.8,22c0.3,0.1,0.5,0.3,0.8,0.4
 c-0.3,1.8-0.1,2,0.1,2.1l0.1,0c0.2,0,0.4-0.1,1-1.6c0.7,0.2,1.4,0.3,2.2,0.5c2.2,0.3,4,0.3,5.7-0.1c0.4-0.1,0.8-0.2,1.1-0.3
 c0.6,1.6,0.8,1.7,1,1.7l0.1,0c0.2-0.1,0.4-0.3,0.1-2.1c0.7-0.3,1.4-0.6,2-1c2.5-1.7,4-4.9,3.8-8.1C24.5,13.1,24.9,12.3,24.9,11.5z
  M23.1,10.3L23.1,10.3c0.5-0.1,1,0.5,1,1.2c0,0.4-0.1,0.8-0.4,1C23.6,11.7,23.3,11,23.1,10.3C23,10.3,23.1,10.3,23.1,10.3z
  M20.3,8.1c1.5,1.1,2.4,2.7,2.7,4.6c0.6,3.1-0.8,6.4-3.3,8c-1.2,0.8-2.5,1.3-4.1,1.6c-1.6,0.3-3.4,0.3-5.4,0.1
 c-1.6-0.2-2.9-0.6-4-1.2c-2.1-1.1-3.5-2.8-4-5.1c-0.7-3,0-5.7,2.2-7.8l0,0c0,0,0.1-0.1,0.1-0.1C5,8,5,7.6,5,7.4
 c0.9,0.3,1.9,0.6,3.1,0.8c1.7,0.3,3.5,0.4,5.4,0.3c2-0.1,3.8-0.3,5.4-0.8c0.4-0.1,0.7-0.2,1.1-0.3c0,0.1,0,0.1,0,0.2
 C20,7.8,20.1,8,20.3,8.1z M1.9,10.3c-0.3,0.7-0.6,1.4-0.7,2.1c-0.2-0.2-0.3-0.6-0.3-0.9c0-0.7,0.4-1.2,0.9-1.2c0,0,0,0,0,0L1.9,10.3
 C1.9,10.3,1.9,10.3,1.9,10.3z"
                        />
                        <path
                          class="st1"
                          d="M378.3-132.8c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
 c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
 c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
 l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
 c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
 l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
 c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
 c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C313.6,110.1,357,12.7,346.6-80.7l1.4-0.6
 C366.1-88.5,378.3-109.2,378.3-132.8z M-321.6-93.6c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
 c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
 l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-309.1-146.3-317.6-120.6-321.6-93.6z M230.8,150.9c-35.5,23.6-76.2,39.8-124.6,49.4
 c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36C-245.6,132.6-287.1,79.9-304,10c-22.5-93.3,0.5-174,68.4-239.8l0.7-0.7
 c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5c2.7,1.1,5.5,2.1,8.2,3.1
 c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7c13.5-3.7,26.5-8.1,39.5-13.5
 c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3C350-2.2,308.2,99.4,230.8,150.9z M347.5-94.4
 c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1
 c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1c0,15.2-6.5,29.6-16.7,37l-0.5,0.4
 c-0.5,0.3-1,0.6-1.7,1L347.5-94.4z"
                        />
                        <path
                          class="st1"
                          d="M399.8-146.3c0-29.8-19.6-54.1-43.7-54.1c-5.6,0-11,1.3-16.2,3.8l-4.3,2.1c-14.9-25.7-34.4-48.1-58.4-67
 c-1.3-1.1-1.4-1.4-1.3-2.5c0.2-5.7,0.2-11.4,0.1-17.4c0-2.4,0-4.9,0-7.4v-9.1l-8.2,3.6c-15.3,6.7-30.4,12-46.2,16.3
 c-47.3,13-99.3,20.3-158.9,22.3c-56.4,1.8-108.2-1.2-158.3-9.4c-35.3-5.8-63.9-13-89.9-22.9c-3.4-1.3-6.9-2.6-10.3-4l-10.5-4.1
 l-1.9,6.1c-1.9,6.1-1.4,12.2-1,17.5c0,0.6,0.1,1.2,0.1,1.8c0.6,7.6-1.3,10-5.1,12c-3,1.6-5.4,3.9-7.4,5.9l-0.7,0.7
 c-18.7,18.1-34.1,37.2-46.3,57.4l-3.9-1.9c-5.2-2.6-10.6-3.8-16.2-3.8c-24.1,0-43.7,24.3-43.7,54.1c0,23.6,12.2,44.3,30.3,51.5
 l0.3,0.1c-2.8,29.8-0.3,61.1,7.7,94c17.7,73.5,61.3,128.7,129.5,164.3c9,4.7,18.3,9,28,12.9c-4,27.2-15,114.5,27,9.5
 c21.6,6.6,44.9,11.5,70.4,14.9c29.8,3.9,57.3,5.9,83.2,5.9c30.4,0,58.6-2.7,85.9-8.1c13.4-2.7,26.3-5.9,38.7-9.6
 c43.1,108.4,31.2,16.6,27.3-9.3c22.6-8.7,43.4-19.4,62.7-32.3C335.1,96.6,378.5-0.8,368.1-94.2l1.4-0.6
 C387.6-102,399.8-122.7,399.8-146.3z M-300.2-107.1c-0.4-0.2-0.9-0.5-1.3-0.8l-0.1-0.1c-0.7-0.3-1.2-0.7-1.7-1l-0.4-0.3
 c-10.3-7.3-16.9-21.8-16.9-37.1c0-23.2,14.3-42.1,31.9-42.1c1.8,0,3.6,0.2,5.4,0.6l-0.1,0.2l1.1,0.3c1.3,0.3,2.6,0.8,3.8,1.5
 l0.2,0.1c1.4,0.4,2.6,1,3.7,1.7C-287.6-159.9-296.2-134.2-300.2-107.1z M252.2,137.4c-35.5,23.6-76.2,39.8-124.6,49.4
 c-49.3,9.8-101.9,10.5-165.3,2.1c-47.3-6.2-87.1-18-121.5-36c-65-33.9-106.5-86.6-123.4-156.5c-22.5-93.3,0.5-174,68.4-239.8
 l0.7-0.7c1.6-1.6,3.2-3.1,4.7-3.9c11.4-5.9,12-16.3,11.4-23.6c0-0.6-0.1-1.3-0.2-1.9c-0.2-2.2-0.4-4.4-0.4-6.5
 c2.7,1.1,5.5,2.1,8.2,3.1c26.8,10.1,56,17.6,92.1,23.5c50.8,8.3,103.3,11.4,160.5,9.6c60.5-2,113.3-9.4,161.6-22.7
 c13.5-3.7,26.5-8.1,39.5-13.5c0,5.3,0,10.3-0.1,15.4c-0.2,5.2,1.7,9.1,5.9,12.4c44.7,35.2,73,83.1,84,142.3
 C371.4-15.7,329.6,85.9,252.2,137.4z M368.9-107.9c-0.8,0.6-1.6,1-2.5,1.4c-0.3-1.8-0.6-3.7-1-5.5c-4.9-26-12.9-50-24.1-71.7
 c1.2-0.9,2.7-1.6,4.3-2.1l0.2-0.1c1.2-0.7,2.5-1.1,3.8-1.5l1.1-0.3l-0.1-0.2c1.8-0.4,3.6-0.6,5.4-0.6c17.6,0,31.9,18.9,31.9,42.1
 c0,15.2-6.5,29.6-16.7,37l-0.5,0.4c-0.5,0.3-1,0.6-1.7,1L368.9-107.9z"
                        />
                      </svg> */}
                      {svgImage.matka}
                      MATKA
                    </a>
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/777"
                    >
                      {svgImage.matka}
                      MATKA LOTTERY
                    </a>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
                <div className="row match-box" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/111"
                    >
                      {svgImage.cupRate}
                      CUP RATE
                    </a>
                  </div>

                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/6"
                    >
                      {svgImage.casino}
                      CASINO
                    </a>
                  </div>

                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>

                <div className="row match-box" style={{ marginTop: "25px" }}>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                  <div className="col-md-4 col-lg-4 col-sm-4">
                    <a
                      className="card card-1 card-color dashboard-svg"
                      href="/match/inplay/898"
                    >
                      {svgImage.election}
                      ELECTION
                    </a>
                  </div>
                  <div className="col-md-2 col-lg-2 col-sm-2"></div>
                </div>
              </>
            )}
            <a href="/match/inplay/999">
              <div className="game-footer-outer show-play-and-win">
                <div className="games-footer"></div>
                {/* <div className="pw-close" onClick={null}>
                  ×
                </div> */}
              </div>
            </a>
          </div>
        </div>
      </>
    );
  }
}

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default connect(mapPropsToState)(DashboardComponent);
