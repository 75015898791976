import React from "react";
import { connect } from "react-redux";
import { getClientLedger } from "../../_actions";
import { toast, ToastContainer } from "react-toastify";
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import { getDateAndTime } from "../../utils";

class LedgerComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      ledgerData: []
    };
  }

  componentDidMount() {
    this.props.dispatch(
      getClientLedger((res) => {
        if (res) {
          this.setState({ ledgerData: res.ledger });
        }
      })
    );
  }

  render() {

    var { ledgerData } = this.state;

    return (
      <div className="container ledger-table-container-3">
        <div
          className="row content-top-padding"
          style={{ paddingBottom: "15px" }}
        >
          <table className="table table-bordered header-top-spacing">
            <thead>
              <tr>
                <th className="ldg-tbl-th match-box-color">DATE</th>
                <th className="ldg-tbl-th match-box-color">ENTRY</th>
                <th className="ldg-tbl-th match-box-color">DEBIT</th>
                <th className="ldg-tbl-th match-box-color">CREDIT</th>
                <th className="ldg-tbl-th match-box-color">BALANCE</th>
              </tr>
            </thead>
            <tbody>

              {ledgerData.length > 0 ? (
                ledgerData.map((ledger, index) => (
                  <tr>

                    <td
                      className="ldg-tbl-td match-value-box-color"
                      style={{ textAlign: "left" }}
                    >
                      {getDateAndTime("MMMM DD, hh:mm A", ledger.created_at)}

                      {/* {getDateAndTime(ledger.created_at)} */}
                    </td>
                    <td
                      title="Chepauk Super Gillies vs Nellai Royal Kings"
                      className="ldg-tbl-td match-value-box-color"
                      style={{ textAlign: "left" }}
                    >

                      {ledger.event_data != undefined ? (
                      <Link to={`/ledger/${ledger.event_data.event_id}`}>
                        {ledger.remark}
                      </Link>):ledger.remark}

                    </td>
                    <td className="ldg-tbl-td match-value-box-color"> {ledger.debit_amount}</td>
                    <td className="ldg-tbl-td match-value-box-color"> {ledger.credit_amount}</td>

                    <td className="ldg-tbl-td match-value-box-color"> {ledger.ledger_balance}</td>
                  </tr>

                ))

              ) : (<tr><td colSpan="5">

                NO RECORD FOUND
              </td></tr>)}

            </tbody>

          </table>
        </div>
      </div>

    );
  }
}

const mapPropsToState = (state) => ({
  matchDetail: state.match.matchDetail,
  betPriceDetail: state.match.betPriceDetail,
});

export default connect(mapPropsToState)(LedgerComponent);
