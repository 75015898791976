import { authConstants } from "../../_constants";
import { userLogin, logout, changePasswordLogin, sideMaintenance } from "../../_services";
import { toast } from "react-toastify";

export const loginUser = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST, data: {} });
    userLogin(postData)
      .then((response) => {
        // toast.success("Login successfully");
        callback(true);
        dispatch({ type: authConstants.LOGIN_SUCCESS, data: response.data });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};

export const logoutUser = (callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST, data: {} });
    logout()
      .then((response) => {
        // toast.success("Logout successfully");
        callback(true);
        dispatch({ type: authConstants.LOGOUT_SUCCESS, data: {} });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};
// change password
export const changePassword = ({ old_password, new_password, confirm_password }, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.CHANGE_PASSWORD_REQUEST, data: {} });
    changePasswordLogin(old_password, new_password, confirm_password)
      .then((response) => {
        toast.success("Change Password successfully");
        callback(true);
        dispatch({ type: authConstants.CHANGE_PASSWORD_SUCCESS, data: response.data });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.FAILED_RESPONSE, data: error });
      });
  };
};


export const loginMaintenance = (postData, callback) => {
  return (dispatch) => {
    dispatch({ type: authConstants.MAINTENACE_REQUEST, data: {} });
    sideMaintenance(postData)
      .then((response) => {

        callback(response);
        dispatch({ type: authConstants.MAINTENACE_SUCCESS, data: response });
      })
      .catch((error) => {
        callback(false);
        dispatch({ type: authConstants.MAINTENACE_RESPONSE, data: error });
      });
  };
};