import React, { useEffect } from "react";
import Layout from "../../components/common/Layout";
import MatchDetail from "../../components/MatchDetail";
import MatkaDetail from "../../components/MatchDetail/MatkaDetail";
import { getMatchDetailRequest } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

export default function Detail(props) {
  const { event_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { matchDetail } = useSelector((state) => state.match);

  useEffect(() => {
    if (event_id) {
      dispatch(getMatchDetailRequest({ event_id }, () => {}));
    } else {
      history.goBack();
    }
  }, [event_id]);

  return (
    <>
      <Layout {...props}>
        {!matchDetail ? (
          <div className="d-flex justify-content-center">
            <h4>Loading....</h4>
          </div>
        ) : (+matchDetail?.event?.sport_id === 999 || +matchDetail?.event?.sport_id === 333 || +matchDetail?.event?.sport_id === 777) ? (
          <MatkaDetail event_id={event_id} />
        ) : (
          <MatchDetail event_id={event_id} />
        )}
      </Layout>
    </>
  );
}
