import React from "react";
import Layout from "../../components/common/Layout";
import SettingsComponent from "../../components/Settings/SettingsComponent";

class Settings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <SettingsComponent />
      </Layout>
    );
  }
}

export default Settings;
