import _ from "lodash";

const DevHost = ["localhost", "betlagao.com", "www.betlagao.com"];
const StagHost = [
  "top2020.pro",
  "top2020.in",
  "www.top2020.pro",
  "www.top2020.in",
];
const LiveHost = [
  "7daymatka.com",
  "https://7daymatka.com/",
  "www.7daymatka.com",
  "www.7daymatka.com",
];
export const allowedWidgetDomains = [
  "http://localhost:3003/",
  "http://localhost:3002",
  "https://kgf_admin.betexchange.guru/",
  "https://kgf.betexchange.guru/",
  "https://r444.betlagao.com/",
];
const hostname = window.location.hostname;

const live = {
  api_baseurl: "https://clientapi.7daymatka.com/api/", 
  baseurl: "https://clientapi.7daymatka.com/",
  socketurl: "https://node.7daymatka.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwMTkxMDEsImV4cCI6MTY1NDA1NTEwMSwidXNlcl9pZCI6IjQwIiwicm9sZSI6IjciLCJwYXJlbnRfaWQiOiIzOSJ9.wx6bsn6MKfvNL7Bz6XYQjG1YFHsq5XKYvy2GJEYCAXY",
  },
};

const dev = {
  api_baseurl: "https://7day_client_api.radhebet.com/api/",
  baseurl: "https://7day_client_api.radhebet.com/",
  socketurl: "https://7day-node.radhebet.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwMTkxMDEsImV4cCI6MTY1NDA1NTEwMSwidXNlcl9pZCI6IjQwIiwicm9sZSI6IjciLCJwYXJlbnRfaWQiOiIzOSJ9.wx6bsn6MKfvNL7Bz6XYQjG1YFHsq5XKYvy2GJEYCAXY",
  },
};

const stag = {
  api_baseurl: "https://7day_client_api.radhebet.com/api/",
  baseurl: "https://7day_client_api.radhebet.com/",
  socketurl: "https://7day-node.radhebet.com",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
    Authorization:
      "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwMTkxMDEsImV4cCI6MTY1NDA1NTEwMSwidXNlcl9pZCI6IjQwIiwicm9sZSI6IjciLCJwYXJlbnRfaWQiOiIzOSJ9.wx6bsn6MKfvNL7Bz6XYQjG1YFHsq5XKYvy2GJEYCAXY",
  },
};

const AppConfig = _.includes(LiveHost, hostname)
  ? live
  : _.includes(StagHost, hostname)
  ? stag
  : dev;

export default AppConfig;

/*module.exports = {
    api_baseurl: "https://7day_client_api.radhebet.com/api/",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': 'en',
      'Authorization':'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTQwMTkxMDEsImV4cCI6MTY1NDA1NTEwMSwidXNlcl9pZCI6IjQwIiwicm9sZSI6IjciLCJwYXJlbnRfaWQiOiIzOSJ9.wx6bsn6MKfvNL7Bz6XYQjG1YFHsq5XKYvy2GJEYCAXY'
    }
}*/
