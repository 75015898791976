import React from "react";
import Layout from "../../components/common/Layout";
import SuperNowaComponent from "../../components/Games/SuperNowaComponent";

class SuperNowa extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <SuperNowaComponent />
      </Layout>
    );
  }
}

export default SuperNowa;
