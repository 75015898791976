import { matchConstants } from "../../_constants";
import { getStorage } from "../../utils";

let initialState = {
  sportList: [],
  inFixtureList: [],
  upFixtureList: [],
  isLoader: false,
};

export function match(state = initialState, action) {
  switch (action.type) {
    case matchConstants.MATCH_ALL_SPORT_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.MATCH_ALL_SPORT_SUCCESS:
      return {
        ...state,
        sportList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_INPLAAY_FIXTURE_REQUEST:
      return {
        ...state,
        inFixtureList: { total_records: 0, records: [] },
        isLoader: true,
      };
    case matchConstants.GET_INPLAAY_FIXTURE_SUCCESS:
      return {
        ...state,
        inFixtureList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_UPCOMING_FIXTURE_REQUEST:
      return {
        ...state,
        upFixtureList: { total_records: 0, records: [] },
        isLoader: true,
      };
    case matchConstants.GET_UPCOMING_FIXTURE_SUCCESS:
      return {
        ...state,
        upFixtureList: action.data,
        isLoader: false,
      };
    case matchConstants.GET_MATCH_DETAIL_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_MATCH_DETAIL_SUCCESS:
      return {
        ...state,
        matchDetail: action.data,
        isLoader: false,
      };
    case matchConstants.GET_CLIENT_ANNU_REQUEST:
      return {
        ...state,
        isLoader: true,
      };
    case matchConstants.GET_CLIENT_ANNU_SUCCESS:
      return {
        ...state,
        clientAnnouncement: action.data,
        isLoader: false,
      };
    case matchConstants.SET_BET_SUCCESS:
      return {
        ...state,
        betPriceDetail: action.data,
        isLoader: false,
      };

    case matchConstants.CLEAR_BET_SUCCESS:
      return {
        ...state,
        betPriceDetail: action.data,
        isLoader: false,
      };
    case matchConstants.FAILED_RESPONSE:
      return {
        ...state,
        isLoader: false,
      };
    default:
      return state;
  }
}
