import { auth } from './auth/auth.reducer';
import { match } from './match/match.reducer';
import { ledger } from './ledger/ledger.reducer';
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
const rootReducer = combineReducers({
  auth:persistReducer({ key: 'clientLogin', storage }, auth),
  match,ledger
  // auth
});
  
export default rootReducer;