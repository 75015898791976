import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { userAccountStatement } from "../../_services";
import Layout from "../../components/common/Layout";
import { CustomDatePicker } from "../../components/common/CustomDatePicker";
import { getDateAndTime } from "../../utils";

export default function AccountStatement(props) {
  const formatDate = "YYYY-MM-DD";
  const [dataList, setDataList] = useState({ total_count: 0, list: [] });
  const todayDate = moment(new Date()).startOf("isoWeek");
  const [filterData, setFilter] = useState({
    startDate: todayDate.clone().subtract(12, "days").format(formatDate),
    endDate: moment(new Date()).format(formatDate),
    current_page: 1,
    items_perpage: 20,
  });
  const [filterProps, setFilterProps] = useState({});
  const [loading, setLoading] = useState(false);
  const [forceFetch, setToggleFetch] = useState(false);

  useEffect(() => {
    if (forceFetch) {
      getDataList(1);
    }
  }, [forceFetch]);

  useEffect(() => {
    getDataList(filterData?.current_page);
  }, [filterData?.current_page]);

  const getDataList = async (current_page) => {
    const formattedStartDate =
      moment(filterData?.startDate).format(formatDate) + " " + "00:00:00";
    const formattedEndDate =
      moment(filterData?.endDate).format(formatDate) + " 23:59:59";
    const payload = {
      from_date: moment(formattedStartDate)
        .utc()
        .format(formatDate + " HH:mm:ss"),
      to_date: moment(formattedEndDate)
        .utc()
        .format(formatDate + " HH:mm:ss"),
      current_page: current_page,
      items_perpage: filterData?.items_perpage,
    };
    setLoading(true);
    try {
      const res = await userAccountStatement(payload);
      if (res?.data?.coin_report) {
        setDataList({
          total_count: res?.data?.total,
          list: res?.data?.coin_report ? res?.data?.coin_report.map(items => ({ ...items, created_at: getDateAndTime("DD, MMM YYYY h:mm A", items.created_at) })) : [],
        });
        setFilterProps(filterData);
        setLoading(false);
      } else {
        setLoading(false);
        setDataList({ total_count: 0, list: [] });
      }
    } catch (error) {
      setLoading(false);
    }
    setToggleFetch(false);
    setFilter((s) => ({ ...s, current_page }));
  };

  const data = [
    { name: "Date Time", value: "created_at", width:"13%" },
    { name: "Remark", value: "remark", width:"50%" },
    { name: "Credit", value: "credit_amount", align:"center" }, //betting_type = 1 : Back else Lay
    { name: "Debit", value: "debit_amount", align:"center" },
    { name: "Profit/Loss", value: "profit_loss", align:"center" },
    { name: "Balance", value: "user_balance", align:"center" },
  ];

  const RowElement = ({ item, column }) => (
    <tr>
      {column?.map((colItem, i) => {
        return (
          <>
            <td align={colItem?.align} title={colItem.value === "remark" ? item[`${colItem.value}`] : ""} key={i}>{item[`${colItem.value}`]}</td>
          </>
        );
      })}
    </tr>
  );

  const handleSelectDate = (name, value) => {
    setFilter((s) => ({ ...s, [name]: value }));
  };

  const handlePageClick = ({ selected }) => {
    setFilter((s) => ({ ...s, current_page: selected + 1 }));
  };

  return (
    <Layout {...props}>
      <div className="container  accStat_contain">
        <div className="box_contain">
          <h4>
            <b>Account-Statement</b>
          </h4>
          <div className="filter_box filter_boxGrid">
            <div className="filterFx_basis">
              <CustomDatePicker
                value={filterData?.startDate}
                max={moment(new Date()).format("YYYY-MM-DD")}
                onSelect={(e) => handleSelectDate("startDate", e.target.value)}
                className="date-input"
              />
            </div>
            <div className="filterFx_basis">
              <CustomDatePicker
                value={filterData?.endDate}
                min={filterData?.startDate}
                max={moment(new Date()).format("YYYY-MM-DD")}
                onSelect={(e) => handleSelectDate("endDate", e.target.value)}
                className="date-input"
              />
            </div>
            <div className="d-flxe gap-1 filterFx_basis filterFx_basis_full">
              <Button
                disabled={
                  // JSON.stringify(filterProps) === JSON.stringify(filterData) ||
                  loading
                }
                className="getHistoryBtn"
                onClick={() => setToggleFetch(true)}
              >
                Get Statement
              </Button>
            </div>
          </div>
        </div>
        {/* <MarketBet marketBetData={dataList} /> */}

        <div className="anvOtr_tabl acc_statementTable">
          <table className="table anvRepo_tab tableMob_wid">
            <thead>
              <tr>
                {data?.map((item) => (
                  <th style={item?.align ? { textAlign:"center"} : {width:item?.width}}>{item?.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataList?.list?.map((item, i) => (
                <RowElement key={item?.user_guid} item={item} column={data} />
              ))}
            </tbody>
          </table>
        </div>
        {dataList?.total_count > 0 && (
          <ReactPaginate
            initialPage={filterData?.current_page - 1}
            className="pagination"
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(
              dataList?.total_count / filterData?.items_perpage
            )}
            previousLabel="previous"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </Layout>
  );
}
