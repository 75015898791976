import React, { useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { getDateAndTime, getLeftBazarTime } from "../../utils";
import { toast } from "react-toastify";
import { cancelMatkaBet } from "../../_services";
import { Form } from "react-bootstrap";
import ConfirmModal from "../common/ConfirmModal";
import { BsFillLockFill } from "react-icons/bs";

const MatkaBetList = (props) => {
  const {
    marketBetData,
    betClosed,
    event,
    matkaMarket,
    matkaType,
    setActiveMatka,
    fetchMatkaBetList,
  } = props;
  const [togglelist, setToggleList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [cancelModal, setCancelBetModal] = useState(false);

  const getDisplayValue = (value, market_type) => {
    try {
      if (value) {
        if (market_type) {
          if (market_type === 1 || market_type === 4 || market_type === 5) {
            let val = value * 100;
            return (parseFloat(val).toFixed(2) - 1 * 100) / 100;
          } else if (market_type === 2) {
            let val = value * 100;
            return parseFloat(val).toFixed(2) - 1 * 100;
          } else {
            return parseFloat(value).toFixed(2);
          }
        } else {
          return value;
        }
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  };

  const cancelBet = async (betslip_uid) => {
    if (
      getLeftBazarTime(
        getDateAndTime("YYYY-MM-DD HH:mm:ss", event?.open_date)
      ) !== "00:00:00"
    ) {
      setLoader(true);
      try {
        const res = await cancelMatkaBet({ betslip_uid });
        if (res) {
          toast.success(res?.message ? res?.message : "success");
          fetchMatkaBetList((s) => !s);
        }
        setTimeout(() => {
          window.location.reload();
          setLoader(false);
        }, 500);
      } catch (error) {
        toast.error(
          error.response?.data?.error
            ? error.response?.data?.error
            : "server error"
        );
        setLoader(false);
      }
    } else {
      toast.error("Bazar is closed");
    }
  };

  const toggleDetailsMenu = (name) => {
    const detailsArray = [...togglelist];
    if (detailsArray.indexOf(name) >= 0) {
      detailsArray.splice(detailsArray.indexOf(name), 1);
    } else {
      detailsArray.push(name);
    }
    setToggleList(detailsArray);
  };

  const getBetClose = () => {
    if (typeof betClosed === "boolean") {
      switch (true) {
        case betClosed == true:
          return true;
        default:
          return false;
      }
    } else {
      switch (true) {
        case betClosed == 1:
          return false;
        case betClosed == 0:
          return true;
        default:
          return false;
      }

    }
  };

  return (
    <>
      <div className="row" style={{ paddingTop: "15px" }}>
        {marketBetData.map((item) => (
          <div className="tableResponsive">
            <div className="heading_bar"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.market_name}
                      {/* Customer Purchased  */}
                      {" { " + item?.list?.length + " }"}
                      {/* <Form.Control
                      style={{ marginLeft: "15px" }}
                      as="select"
                      onChange={(e) => setActiveMatka(e.target.value)}
                    >
                      <option
                        value=""
                        className={
                          "matka_menu_item " +
                          (matkaType === "" ? "active" : "")
                        }
                      >
                        Select Market
                      </option>
                      {matkaMarket?.map((market) => (
                        <option
                          value={market?.market_name}
                          className={
                            "matka_menu_item " +
                            (matkaType === market?.market_name ? "active" : "")
                          }
                          // onClick={() => setActiveMarket(market?.market_name)}
                        >
                          {market?.market_name}
                        </option>
                      ))}
                    </Form.Control> */}
                    </div>
                    <div
                      onClick={() => toggleDetailsMenu(item?.market_name)}
                      className="bet_list_arrow"
                    >
                      {togglelist ? (
                        <AiOutlineDown style={{ marginLeft: "10px" }} />
                      ) : (
                        <AiOutlineUp style={{ marginLeft: "10px" }} />
                      )}
                    </div>
                  </div>
          <table className="table table-bordered anvAkda_table">
            
            <thead>
              <tr
                className="pointer"
              >
                <th
                  colSpan="7"
                  className="bet-place-tbl-th market_type_row market-name-row-color"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.market_name}
                      {/* Customer Purchased  */}
                      {" { " + item?.list?.length + " }"}
                      {/* <Form.Control
                      style={{ marginLeft: "15px" }}
                      as="select"
                      onChange={(e) => setActiveMatka(e.target.value)}
                    >
                      <option
                        value=""
                        className={
                          "matka_menu_item " +
                          (matkaType === "" ? "active" : "")
                        }
                      >
                        Select Market
                      </option>
                      {matkaMarket?.map((market) => (
                        <option
                          value={market?.market_name}
                          className={
                            "matka_menu_item " +
                            (matkaType === market?.market_name ? "active" : "")
                          }
                          // onClick={() => setActiveMarket(market?.market_name)}
                        >
                          {market?.market_name}
                        </option>
                      ))}
                    </Form.Control> */}
                    </div>
                    <div
                      onClick={() => toggleDetailsMenu(item?.market_name)}
                      className="bet_list_arrow"
                    >
                      {togglelist ? (
                        <AiOutlineDown style={{ marginLeft: "10px" }} />
                      ) : (
                        <AiOutlineUp style={{ marginLeft: "10px" }} />
                      )}
                    </div>
                  </div>
                </th>
              </tr>
              {!togglelist.includes(item?.market_name) && (
                <tr>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                    style={{ textAlign: "left" }}
                  >
                    Patti
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                  >
                    Number
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th mob-col-width-ch run-pos-rate-amt-run-mod-color"
                  >
                    Amount
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                  >
                    Date
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                  >
                    Purchase Time
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                  >
                    Draw Time
                  </th>
                  <th
                    colSpan={1}
                    className="bet-place-tbl-th run-pos-rate-amt-run-mod-color"
                  >
                    Action
                  </th>
                </tr>
              )}
            </thead>
            {!togglelist.includes(item?.market_name) && (
              <tbody>
                {item?.list?.length ? (
                  item?.list?.map((market, index) => (
                    <tr key={index} id="runs-bet-value-box-color">
                      <td
                        colSpan={1}
                        className="fancy_session_th bet-place-tbl-td mob-col-width-ch"
                        style={{ textAlign: "left" }}
                      >
                        {market.market_name}
                      </td>
                      <td
                        colSpan={1}
                        className="fancy_session_th bet-place-tbl-td mob-col-width-ch"
                      >
                        {market.team_name}
                      </td>
                      <td
                        colSpan={1}
                        data-modal="modal-1"
                        className="bet-place-tbl-td md-trigger"
                      >
                        {getDisplayValue(market.stake)}
                      </td>
                      <td colSpan={1} className="bet-place-tbl-td ">
                        {getDateAndTime("DD, MMM YYYY", market.created_at)}
                      </td>
                      <td colSpan={1} className="bet-place-tbl-td ">
                        {getDateAndTime(
                          "DD, MMM YYYY hh:mm",
                          market.created_at
                        )}
                      </td>
                      <td colSpan={1} className="bet-place-tbl-td ">
                        {getDateAndTime("h:mm A", market.game_start_date)}
                      </td>
                      <td colSpan={1} className="bet-place-tbl-td ">
                        <button
                          disabled={
                            getBetClose() ||
                            loader === market?.betslip_uid ||
                            +market?.winning_status !== 0
                            // ||
                            // getLeftBazarTime(
                            //   getDateAndTime(
                            //     "YYYY-MM-DD HH:mm:ss",
                            //     event?.open_date
                            //   )
                            // )?.bet_sec < event?.bet_close_time
                          }
                          amt-value="clear"
                          className={
                            +market?.winning_status === 0
                              ? "bet_btn submit"
                              : "bet_cancel_btn"
                          }
                          onClick={() => {
                            setCancelBetModal(market?.betslip_uid);
                          }}
                        >
                          {
                            getBetClose()
                            // getLeftBazarTime(
                            //   getDateAndTime(
                            //     "YYYY-MM-DD HH:mm:ss",
                            //     event?.open_date
                            //   )
                            // )?.bet_sec < event?.bet_close_time
                            && (
                              <div className="lock_bet_cancle">
                                <BsFillLockFill size={26} color="red" />
                              </div>
                            )}
                          {+market?.winning_status === 0
                            ? "Cancel"
                            : "Cancelled"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={"no"} id="runs-bet-value-box-color">
                    <td colSpan={7}>
                      {" "}
                      <div
                        className="d-flex "
                        style={{
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <p>No Record Found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
          </div>
        ))}
        {cancelModal && (
          <ConfirmModal
            showModel={cancelModal}
            message={"Are you sure, you want to cancel Bet"}
            onClose={() => setCancelBetModal(false)}
            onSubmit={() => cancelBet(cancelModal)}
            btnTitle={"yes"}
            isLoading={Boolean(loader)}
            isDisabled={Boolean(loader)}
          />
        )}
      </div>
    </>
  );
};

export default MatkaBetList;
