import React from "react";
import Layout from "../../components/common/Layout";
import UpMatchList from "../../components/Match/UpMatchList";

class UpComing extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props}>
        <UpMatchList />
      </Layout>
    );
  }
}

export default UpComing;
